import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {IconPlus} from "@tabler/icons-react";
import {CreateOrganization} from "../../organizations/Organizations";

export const ProjectForm = ({project, setProject, languages, organizations, asriProjects, fetchData}) => {
  const [createOrganization, setCreateOrganization] = useState(false);

  const handleChange= (e) => {
    const key = e.target.name;
    const value = e.target.value;
    updateProject(key, value);
  }

  const updateProject = (key, value) => {
    if (key) {
      setProject(prev => ({...prev, [key]: value}))
    }
  }

  return (
    <>
      <CreateOrganization
        open={createOrganization}
        handleClose={() => setCreateOrganization(false)}
        onSuccess={fetchData}
      />
      <Grid container sx={{px: 2, py: 0}}>
        <Grid item xs={12} sm={6} sx={{p: 1}}>
          <TextField
            name={"name"}
            label={"Project Name"}
            value={project?.name}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{shrink: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{p: 1}}>
          <FormControl  fullWidth>
            <InputLabel id={`language-label`}
                        shrink={true}
                        sx={{textTransform: "capitalize"}}>Language</InputLabel>
            <Select
              labelId={`language-label`}
              id={`language-select`}
              name={"language"}
              label={"Language"}
              value={project?.language || ""}
              onChange={e => {
                const lang = languages.find(i => i.id === e.target.value);
                updateProject("language", lang.id || null)
              }}
            >
              <MenuItem value={""}><em>None</em></MenuItem>
              {languages?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.language} ({item?.code})</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{p: 1, display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}>
          <FormControl fullWidth>
            <InputLabel id={`organization-label`}
                        shrink={true}
                        sx={{textTransform: "capitalize"}}>Organization</InputLabel>
            <Select
              labelId={`organization-label`}
              id={`organization-select`}
              name={"organization"}
              label={"Organization"}
              value={project?.organization || ""}
              onChange={e => {
                const org = organizations.find(i => i?.id === e.target.value);
                updateProject("organization", org?.id || null)
              }}
            >
              <MenuItem value={""}><em>None</em></MenuItem>
              {organizations?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
            </Select>
          </FormControl>
          <Tooltip title={"Create Organization"}>
            <IconButton onClick={() => setCreateOrganization(true)}><IconPlus /></IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6} sx={{p: 1}}>
          <FormControl fullWidth>
            <InputLabel id={`asri-label`}
                        shrink={true}
                        sx={{textTransform: "capitalize"}}>ASRI</InputLabel>
            <Select
              labelId={`asri-label`}
              id={`asri-select`}
              name={"speechToText"}
              label={"ASRI"}
              value={project?.speechToText || ""}
              onChange={e => {
                console.log(e.target.value);
                const asri = asriProjects.find(i => i?.id === e.target.value);
                updateProject("speechToText", asri?.id || null)
              }}
            >
              <MenuItem value={""}><em>None</em></MenuItem>
              {asriProjects?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{p: 1}}>
          <TextField
            name={"description"}
            label={"Description"}
            value={project?.description}
            fullWidth
            InputLabelProps={{shrink: true}}
            rows={3} multiline
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  )
}