import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';

import {Box, List, ListItem, Stack, Tooltip, Typography} from "@mui/material";
import {
  IconStepInto,
} from "@tabler/icons-react";
import {NodeType} from "../model/ModelData";
import {IconForm} from "../assets/icons/IconForm";

function FormNode({id, data}) {
  const {steps, name, isConnected, node} = data;

  return (
    <Tooltip title={!isConnected ? "Please connect node with other node" : name}>
      <Box className={`custom-node ${node?.data?.use_llm ? "magic-node" : ""}`}
           sx={{
             minWidth: "200px",
             maxWidth: "200px",
             width: "100%",
             bgcolor: !isConnected ? 'rgb(252,252,217)' : "#dce4ea",
             border: 1, borderRadius: 0,
             borderColor: !isConnected ? "rgb(239,239,170)" : "#ffffff",
             boxShadow: 3
           }}
      >
        <Handle type="target" position={Position.Left} id={id} style={{top: 15}}/>
        <div className="custom-node__header">
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {<IconForm size={13} />}
            <Typography sx={{fontSize: 9, fontWeight: "bold"}}>{name}</Typography>
          </Stack>
        </div>
        <Box className="custom-node__body" sx={{bgcolor: "#FFF", borderRadius: 0}}>
          <List sx={{p: 0, m: 0}}>
            {
              steps?.map((step, index) => {
                return <ListItem
                  key={step?.id || index}
                  sx={{
                    background: "transparent",
                    borderBottom: steps?.length === index + 1 ? 0 : 1,
                    borderColor: "divider",
                    color: "#252525 !important",
                    p: 1,
                  }}>
                  <Stack direction={"row"} gap={1} alignItems={"center"} sx={{width: "100%"}}>
                    {step?.icon || <IconStepInto color={"#252525"} size={12}/>}
                    <Typography noWrap sx={{fontSize: 9, fontWeight: 400, flex: 1}}>{step?.text}</Typography>
                  </Stack>
                  {
                    step?.type && step?.type === NodeType.END_FORM &&
                    <Handle type="source" position={Position.Right} id={step?.id} style={{top: 15}}/>
                  }
                </ListItem>
              })
            }
          </List>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default memo(FormNode);