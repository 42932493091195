import React, {useEffect, useState} from "react";
import {
  Box, Button,
  IconButton,
  MenuItem,
  Select, Stack,
  Typography,
  useTheme
} from "@mui/material";
import axios from "../../../config/axios";
import {TOKEN} from "../../../context/SignInContext";
import {toast} from "react-toastify";
import {IconCheck, IconPlus, IconTrash, IconX} from "@tabler/icons-react";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const Members = ({newProject, setNewProject}) => {
  const theme = useTheme();
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addMember, setAddMember] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/user/all', null, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
          }
        });
        const users = response.data?.map(u => u.username);
        setUsers(users);
      } catch (err) {
        toast.error("Failed to get users")
      }
    }

    fetchUsers();
  }, [])


  const removeMember = (member) => {
    setNewProject(prev => {
      const prevTeam = prev?.team ? [...prev.team] : [];
      return {
        ...prev,
        team: prevTeam.filter(item => item !== member)
      }
    });
  }

  return (
    <>

      <Stack direction={"column"}>
        <Stack direction={"row"} sx={{p: 0.5, borderBottom: 1, borderColor: "divider"}}>
          <Typography fontWeight={700} sx={{flex: 1, p: 0.5, fontSize: 11}}>User</Typography>
          <Typography fontWeight={700} sx={{p: 0.5, fontSize: 11}}>Action</Typography>
        </Stack>
        {
          newProject?.team?.map(member => {
            return (
              <Stack key={member} direction={"row"} alignItems={"center"}
                     sx={{p: 0.5, borderBottom: 1, borderColor: "divider"}}>
                <Typography sx={{flex: 1, p: 0.5, fontSize: 11}}>{member}</Typography>
                <Box sx={{p: 0.5}}>
                  <IconButton
                    color={"error"} size={"small"}
                    sx={{borderRadius: "50% !important"}}
                    onClick={() => removeMember(member)}
                  >
                    <IconTrash size={18}/>
                  </IconButton>
                </Box>
              </Stack>
            )
          })
        }
        {
          addMember ?
            <Stack
              direction={"row"} alignItems={"center"} gap={1}
              sx={{p: 1, borderBottom: 1, borderColor: "divider"}}>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                sx={{flex: 1, m: 1}}
                value={selectedUser}
                onChange={e => setSelectedUser(e.target.value)}
              >
                {users.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, newProject?.team || [], theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                sx={{borderRadius: "50% !important"}}
                disabled={!selectedUser}
                onClick={() => {
                  if(selectedUser) {
                    setNewProject(prev => {
                      return {
                        ...prev,
                        team: prev?.team ? [...prev.team, selectedUser] : [selectedUser]
                      }
                    })
                  }
                  setSelectedUser(null);
                  setAddMember(false);
                }}
              >
                <IconCheck />
              </IconButton>
              <IconButton
                sx={{borderRadius: "50% !important"}}
                onClick={() => {
                  setSelectedUser(null);
                  setAddMember(false);
                }}
              >
                <IconX />
              </IconButton>

            </Stack>
            :
            <Box sx={{p: 1, mx: 'auto'}}>
              <Button startIcon={<IconPlus/>} onClick={() => setAddMember(true)} color={"success"}>Add New
                Member</Button>
            </Box>
        }


      </Stack>
    </>

  )
}