import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField, Typography
} from "@mui/material";
import React, {useState} from "react";
import {grey, lightBlue} from "@mui/material/colors";

export const SystemPromptField = ({value, onChange, ...props}) => {
  const [openHelper, setOpenHelper] = useState();

  return (
    <>
      <SystemPromptHelper open={openHelper} handleClose={() => setOpenHelper(false)} />
      <Box>
        <TextField
          value={value}
          size={"small"}
          label={"System Prompt"}
          fullWidth
          placeholder={"Write your system prompt here..."}
          multiline
          minRows={3}
          maxRows={9}
          // helperText={"Ask for confirmation"}
          onChange={onChange}
          {...props}
        />
        <Button size={"small"} variant={"text"} sx={{fontSize: 11, color: grey[600]}} onClick={() => setOpenHelper(true)}>
          Learn more
        </Button>
      </Box>
    </>
  )
}

const SystemPromptHelper = ({open, handleClose}) => {
  const [tab, setTab] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"What is System Prompt?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            {
              0: <>
                <Typography fontWeight={700}>Why we use system prompt</Typography>
                In AI language models like GPT-3, the "system prompt" refers to the initial text or input provided by the user to prompt the model to generate a response.
                It sets the context and direction for the AI to follow in generating its output.
                The system prompt guides the model in understanding what kind of response is expected and helps it generate relevant and coherent text.
              </>,
              1: <>
                <Typography fontWeight={700}>Troubleshooting Prompt:</Typography>
                "A customer calls in reporting poor internet connectivity. Guide them through troubleshooting steps to identify and resolve the issue."
              </>,
              2: <>
                <Typography fontWeight={700}>Plan Recommendation Prompt:</Typography>
                "A customer is looking to switch to a new mobile plan with more data and better coverage. Recommend the most suitable plan based on their usage patterns and preferences."
              </>,
              3: <>
                <Typography fontWeight={700}>Device Setup Prompt:</Typography>
                "A customer just purchased a new smartphone and needs help setting it up with their carrier's network. Walk them through the setup process and address any concerns they may have."
              </>,
              4: <>
                <Typography fontWeight={700}>Billing Dispute Prompt:</Typography>
                "A customer contacts support regarding an unexpected charge on their bill. Investigate the issue and provide assistance in resolving the billing dispute."
              </>,
              5: <>
                <Typography fontWeight={700}>Service Outage Prompt:</Typography>
                "Multiple customers are reporting an outage in a specific area. Inform them about the situation, provide updates on the resolution progress, and offer alternative solutions if available."
              </>,
              6: <>
                These prompts can help guide telecommunication agents in providing effective support and assistance to customers in various scenarios they may encounter.
              </>,
            }[tab]
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{mr: "auto", color: lightBlue[700]}} onClick={handleClose}>Skip</Button>
        <Button onClick={() => setTab(prev => (prev - 1))} disabled={tab === 0} sx={{color: lightBlue[700]}}>Previous</Button>
        <Button onClick={() => setTab(prev => (prev + 1))} disabled={tab === 6} sx={{color: lightBlue[700]}} autoFocus>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}