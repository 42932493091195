import React, {useEffect, useState} from "react";
import {ExpandModal} from "./ExpandModal";
import {Button, Stack, Typography} from "@mui/material";
import {Editor} from "@monaco-editor/react";

export const ParserModal = ({open, handleClose, text, onSuccess}) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (open) {
      setContent(text || "");
    }
  }, [open, text])

  return (
    <ExpandModal
      open={open}
      handleClose={handleClose}
    >
      <Typography fontWeight={700}>Parser</Typography>
      <Editor
        // height="80%"
        style={{flex: 1}}
        defaultLanguage="javascript"
        value={content || ""}
        onChange={(value, e) => setContent(value)}

      />
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => {
            onSuccess(content);
            setContent("");
            handleClose();
          }}
        >
          Save
        </Button>
        <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={() => {
            setContent("");
            handleClose();
          }}
        >
          Cancel
        </Button>
      </Stack>
    </ExpandModal>
  )
}