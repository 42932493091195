import React, {useContext, useReducer} from "react";
import {SignInContext} from "./SignInContext";

export default (reducer, actions, defaultValue) => {
    const Context = React.createContext();

    const Provider = ({children}) => {
        const authContext = useContext(SignInContext);
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (const key in actions) {
            boundActions[key] = actions[key](dispatch, authContext);
        }

        return <Context.Provider
            value={{state, ...boundActions}}
        >
            {children}
        </Context.Provider>
    }

    return {Context, Provider};
}