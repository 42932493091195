import {
  CircularProgress,
  Stack,
  TablePagination,
} from "@mui/material";
import {FilterKB} from "../components/FilterKB";
import React, {useEffect, useState} from "react";
import {KnowledgeRepository} from "../../../../repositories/KnowledgeRepository";
import {KnowledgeDocumentTable} from "./KnowledgeDocumentTable";
import {KnowledgeVectorTable} from "./KnowledgeVectorTable";

export const KnowledgeTable = ({project, organization, topics, slots}) => {
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [filterParams, setFilterParams] = useState({
    organization: organization?.id,
    topic: null,
    slot: null,
    query: null,
    use_qa: false,
    report: false,
    knowledge_graphs: false
  })
  const [pagination, setPagination] = useState({page: 1, size: 10, totalPages: 1, totalElements: 1});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organization) {
      fetchData();
    }
  }, [organization, filterParams?.topic, filterParams?.slot, filterParams?.query, filterParams?.use_qa, filterParams?.report, filterParams?.knowledge_graphs, pagination.page, pagination.size])

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await KnowledgeRepository.filterKnowledge({
        ...filterParams,
        organization_id: organization?.id,
        topic_id: filterParams?.topic || null,
        slot_id: filterParams?.slot || null,
        page: pagination?.page,
        size: pagination?.size,
      });
      setKnowledgeData(response.data.content || []);
      setPagination(prev => {
        if (prev) {
          return ({...prev, totalElements: response.data.totalElements, totalPages: response.data.totalPages})
        }
        return ({
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages
        })
      })
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const updateKnowledge = (data) => {
    setKnowledgeData(prev => {
      return prev?.map(item => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      })
    })
  }

  const updateVectorKnowledge = (data) => {
    setKnowledgeData(prev => {
      return prev?.map(item => {
        if (item.chunk_id === data.chunk_id) {
          return data;
        }
        return item;
      })
    })
  }

  return (
    <Stack direction={"column"}
           sx={{mx: "auto", px: 1, flex: 1, height: '100%'}}>

      <FilterKB
        organization={organization}
        filterParams={filterParams} setFilterParams={setFilterParams}
        topics={topics} slots={slots}
        onCreate={fetchData}
      />

      {
        loading ?
          <Stack sx={{flex: 1}} alignItems={"center"} justifyContent={"center"}><CircularProgress/></Stack> :
          <>
            {filterParams?.query ?
              <Stack id={"kb"} direction={"column"} sx={{flex: 1, overflowY: "auto"}}>
                <KnowledgeVectorTable
                  fetchData={fetchData} project={project} query={filterParams?.query}
                  knowledgeData={knowledgeData} updateKnowledge={updateVectorKnowledge}
                  organization={organization} topics={topics} slots={slots}
                />
              </Stack>
              :
              <>
                <Stack id={"kb"} direction={"column"} sx={{flex: 1, overflowY: "auto"}}>
                  <KnowledgeDocumentTable
                    fetchData={fetchData} project={project} query={filterParams?.query}
                    knowledgeData={knowledgeData} updateKnowledge={updateKnowledge}
                    organization={organization} topics={topics} slots={slots}
                  />
                </Stack>
                <TablePagination
                  component="div"
                  count={pagination?.totalElements}
                  page={pagination?.page - 1}
                  // onPageChange={(e, page) => console.log(pagination.page, page)}
                  onPageChange={(e, page) => setPagination(prev => ({...prev, page: page + 1}))}
                  rowsPerPage={pagination?.size}
                  onRowsPerPageChange={(e) => setPagination(prev => ({...prev, page: 1, size: +e.target.value}))}
                />
              </>
            }
          </>
      }

    </Stack>
  )
}

