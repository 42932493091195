import {
  Box,
  Button,
  CircularProgress,
  Grid, Input, InputAdornment,
  List,
  ListItemButton,
  ListItemIcon, ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {IconFile, IconFolder, IconSearch} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";

export const SipPage = ({project}) => {
  const [host, setHost] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFIle] = useState(false);
  const [downloadFileLoading, setDownloadFileLoading] = useState(false);
  const [publish, setPublish] = useState(false);
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    if (selectedItem) {
      downloadFile();
    } else {
      setFileContent("");
    }
  }, [selectedItem])


  const downloadFile = async () => {
    try {
      if (selectedItem?.name) {
        setDownloadFileLoading(true);
        const response = await fetch(`${host}/file/download?path=/${selectedItem?.name}&name=${selectedItem?.name}&id=${new Date().getTime()}`);
        if (!response.ok) {
          toast.error('Network response was not ok');
        }
        const text = await response.text();

        setFileContent(text || "");
      }
    } catch (err) {
      toast.error(`Failed to download file '${selectedItem?.name}'`)
    } finally {
      setDownloadFileLoading(false);
    }
  }

  const onConnect = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (host) {
        const response = await fetch(`${host}/file/view-folder?path=/`);
        const files = await response.json();
        console.log(files);
        setFiles(files);
        setIsConnected(true);
        setSearch("");
        setFileContent("");
        setSelectedItem(null);
      }
    } catch (e) {
      toast.error("Failed to connect to server")
    } finally {
      setLoading(false);
    }
  }

  const onSave = async () => {
    try {
      setUploadFIle(true);
      if (selectedItem) {
        const file = new Blob([fileContent], {type: 'text/plain'});

        // Create a new FormData object
        var data = new FormData();
        data.append('filePath', "/");
        data.append('fileName', selectedItem.name);
        data.append('file', file, selectedItem.name);

        // Perform the fetch request
        const response = await fetch(`${host}/file/upload`, {
          method: 'POST',
          body: data
        });

        if (!response.ok) {
          toast.error('Failed to upload file');
        } else {
          toast.success("File uploaded successfully");
        }
      }
    } catch (e) {
      toast.error("Failed to upload file")
    } finally {
      setUploadFIle(false);
    }
  }

  const onDeploy = async () => {
    try {
      setDownloadFileLoading(true);
      const response = await fetch(`${host}/reload?id=${new Date().getTime()}`);
      if (!response.ok) {
        toast.error('Network response was not ok');
      }
      toast.success("Deploy was successfully")
    } catch (err) {
      toast.error(`Failed to download file '${selectedItem?.name}'`)
    } finally {
      setDownloadFileLoading(false);
    }
  }

  return (
    <Grid container maxWidth={"lg"} sx={{mx: 'auto', py: 3, overflowY: "auto"}}>
      <Grid item xs={12} sx={{p: 2}}>
        <Typography variant={"h4"} fontWeight={600}>SIP Configuration</Typography>
      </Grid>
      <Grid item xs={12} sx={{p: 2}}>
        <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"}>
          <Stack component={"form"} onSubmit={onConnect} direction={"row"} gap={1} alignItems={"center"}>
            <TextField
              label={"Host"} size={"small"}
              placeholder={"https://x.x.x.x:8000"}
              value={host} onChange={e => setHost(e.target.value)}
              InputProps={{
                sx: {pr: 0.2, py: 0.2},
                endAdornment: <InputAdornment position={"end"} sx={{ml: 1, mr: 0, p: 0}}>
                  <Button disabled={!host || uploadFile || downloadFileLoading || loading} type="submit" color={"info"} sx={{boxShadow: 0}}>
                    Connect
                  </Button>
                </InputAdornment>
              }}
            />
          </Stack>
          <Button variant={"contained"} color={"primary"} disabled={uploadFile || downloadFileLoading || loading} onClick={onDeploy} sx={{boxShadow: 0}}>
            Deploy
          </Button>
        </Stack>
        {!isConnected && <Typography>Please connect to server to load files</Typography>}
      </Grid>
      <Grid item xs={12} sx={{p: 1}}>
        <Stack direction={"row"} gap={2} alignItems={"stretch"} sx={{maxHeight: "70vh", height: "100%"}}>
          <Stack direction={"column"} gap={1} sx={{bgcolor: "#fff", p: 2}} className={"border-shadow"}>
            <Typography fontWeight={600} fontSize={16}>Asterisk Configuration Files:</Typography>
            <Input
              startAdornment={<IconSearch size={16} style={{paddingRight: 8}}/>}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {loading && <CircularProgress size={18}/>}
            <List sx={{overflowY: "auto", flex: 1}}>
              {files?.filter(item => item?.name?.toLowerCase().includes(search?.toLowerCase()))?.map(item => {
                const {name, isDir} = item;
                return (
                  <ListItemButton
                    key={name}
                    onClick={() => setSelectedItem(item)}
                    sx={{
                      p: 0.5,
                      bgcolor: name === selectedItem?.name ? "rgba(0,0,0,0.05)" : "transparent",
                      borderRadius: "4px !important"
                    }}
                  >
                    <ListItemIcon>
                      {isDir ? <IconFolder/> : <IconFile/>}
                    </ListItemIcon>
                    <ListItemText primary={name}/>
                  </ListItemButton>
                )
              })}
            </List>
          </Stack>
          <Stack direction={"column"} gap={2} sx={{bgcolor: "#fff", p: 2, flex: 1}} className={"border-shadow"}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {uploadFile ? <CircularProgress size={18}/> : <IconFile size={20}/>}
              <Typography fontWeight={600}
                          fontSize={16}>{selectedItem?.name || "Please select file to edit"}</Typography>
            </Stack>
            <Box sx={{flex: 1, overflowY: "auto"}}>
              {
                downloadFileLoading ?
                  <CircularProgress size={18}/>
                  :
                  <Input
                    disableUnderline
                    multiline minRows={10} disabled={!selectedItem}
                    sx={{borderLeft: 1, borderColor: "divider", pl: 1.5, py: 1, bgcolor: grey[50]}} fullWidth
                    value={fileContent}
                    onChange={(e) => setFileContent(e.target.value)}
                  />
              }
            </Box>
            <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
              <Button variant={"contained"} color={"secondary"} disabled={uploadFile || downloadFileLoading || loading}
                      onClick={() => {
                        setSelectedItem(null);
                        setFileContent("");
                      }}
              >Cancel</Button>
              <Button variant={"contained"} color={"primary"} disabled={uploadFile || downloadFileLoading || loading} onClick={onSave}>Save</Button>
            </Stack>
            {/*{JSON.stringify(files)}*/}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}