import {FlowLayout} from "../../layouts/flow-layout/FlowLayout";
import {useParams, Routes, Route, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../config/firebase";
import {Project} from "./flow/Project";
import {ProjectSettings} from "./settings/ProjectSettings";
import {KnowledgeBase} from "./knowledge-base/KnowledgeBase";
import {FineTuning} from "./fine-tuning/FineTuning";
import {AsriPage} from "./asri/AsriPage";
import {SipPage} from "./sip/SipPage";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";

export const ProjectHomePage = () => {
  const {projectId} = useParams();

  const [project, setProject] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "projects", projectId), (doc) => {
      const newProject = doc.data() || {};
      if (newProject === undefined || newProject?.id === undefined || !newProject || !newProject?.id) {
        navigate("/")
      }
      newProject.id = projectId;
      setProject(newProject);
    });

    return () => unsubscribe();
  }, [projectId])

  return (
    <FlowLayout
      project={project}
      toggleChat={() => setOpenChat(!openChat)}
      openChat={openChat}
    >
      {
        project === null ? <LoadingScreen /> :
          <Routes>
            <Route path="settings" element={<ProjectSettings project={project} />} />
            <Route path="knowledge-base/*"  element={<KnowledgeBase project={project}/>} />
            <Route path={"fine-tuning/:modelId/*"}  element={<FineTuning project={project}/>} />
            <Route path={"fine-tuning/*"}  element={<FineTuning project={project}/>} />
            <Route path={"asri"}  element={<AsriPage project={project}/>} />
            <Route path={"sip"}  element={<SipPage project={project}/>} />
            <Route path=""  element={<Project project={project} setProject={setProject} openChat={openChat} setOpenChat={setOpenChat} />} />
          </Routes>
      }
    </FlowLayout>
  )
}