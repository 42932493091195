import axios from "../config/axios-kb"


export const MessageRepository = {
  edit: (id, data) => {
    return axios.put(`/api/messages/${id}`, data);
  },
  delete: (id) => {
    return axios.delete(`/api/messages/${id}`);
  },
}