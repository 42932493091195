import React from "react";
import {Fade, IconButton, Stack} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "80vw",
  maxHeight: "80dvh",
  height: "100%",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const ExpandModal = ({open, handleClose, children}) => {

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={handleClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack direction={"column"} gap={2} sx={style}>
            {children}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
