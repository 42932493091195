import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {TopicRepository} from "../../../../repositories/TopicRepository";
import {toast} from "react-toastify";
import React from "react";

export const CreateTopicModal = ({open, handleClose, organization, selectedTopic, onDelete, onSuccess}) => {

  const handleDelete = () => {
    if (selectedTopic) {
      TopicRepository.delete(selectedTopic.id)
        .then(() => {
          onDelete(selectedTopic.id);
          handleClose();
        })
        .catch(err => toast.error(err.message));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData).entries());
          formJson.org_id = organization?.id;
          if (!formJson.name) {
            toast.error("Name is required field")
            return;
          }

          if (selectedTopic && selectedTopic?.id) {
            TopicRepository.edit(selectedTopic?.id, formJson)
              .then(response => {
                onSuccess(response.data.topic);
              })
              .catch(err => {
                toast.error("Failed to create topic");
              })
              .finally(() => {
                handleClose();
              });
          } else {
            TopicRepository.create(formJson)
              .then(response => {
                onSuccess(response.data.topic);
              })
              .catch(err => {
                toast.error("Failed to edit topic");
              })
              .finally(() => {
                handleClose();
              });
          }
        },
      }}
    >
      <DialogTitle>{selectedTopic ? "Edit" : "Create"} Topic</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12}>
          In natural language processing (NLP), a "topic" generally refers to the subject matter or theme of a piece of text or conversation. It's the main idea or subject being discussed.
        </DialogContentText>
        <DialogContentText fontSize={12}>
          For example, if you're discussing sports, the topic might be "football", "basketball", or "soccer". The model can then generate text specifically related to that sport.
        </DialogContentText>
        <TextField
          autoFocus
          required
          defaultValue={selectedTopic?.name || ""}
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        {selectedTopic && <Button color={"error"} onClick={handleDelete} sx={{mr: "auto"}}>Delete</Button>}
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">{selectedTopic ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}