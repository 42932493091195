import React, {useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Fade, FormControl,
  FormControlLabel,
  IconButton,
  InputLabel, MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconArrowsDiagonal,
  IconTrash,
  IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {Editor} from "@monaco-editor/react";
import {ParserModal} from "../../../../components/modals/ParserModal";
import {SystemPromptField} from "../../../../components/system-prompt/SystemPromptField";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const CreateEntityModal = ({open, handleClose, entity, handleSubmit, onRemove, project, flow}) => {
  const [name, setName] = useState("");
  const [regex, setRegex] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [outputParser, setOutputParser] = useState(`function parser() {
    console.log("Hello world")
}`);
  const [entityType, setEntityType] = useState("FreeTextEntity");
  const [validation, setValidation] = useState(false);
  const [expandParser, setExpandParser] = useState(false);

  useEffect(() => {
    if (entity) {
      setValidation(entity.validation || false);
      setName(entity?.name || "");
      setRegex(entity?.regex || entity?.api_url || "");
      setEntityType(entity?.entityType || "FreeTextEntity");
      setSystemPrompt(entity?.system_prompt || "");
      setOutputParser(entity?.output_parser || `function parser() {
    console.log("Hello world")
}`);
    }
  }, [entity]);
  const onCreate = async () => {
    if (!name || (
      entityType === "RegexEntity" && validation && !regex
    )) {
      toast.error("All fields are required!");
      return;
    }
    if (!name || (
      entityType === "ApiRegexEntity" && validation && !regex
    )) {
      toast.error("All fields are required!");
      return;
    }
    let newEntity = {
      id: entity?.id || uuidv4(),
      name: name,
      entityType: entityType,
      validation: validation,
      // flow: entity?.flow || flow?.id, //flow id
    }
    if (entityType === "ApiRegexEntity" || entityType === "NEREntity") {
      newEntity.validation = validation;
      newEntity.api_url = regex;
    } else if (entityType === "RegexEntity") {
      newEntity.validation = validation;
      newEntity.regex = regex;
    } else if (entityType === "LLMExtractionBoostedEntity") {
      newEntity.system_prompt = systemPrompt;
      newEntity.output_parser = outputParser;
      delete newEntity.validation;
    }
    handleSubmit(newEntity);
    onClose();
  }

  const onClose = () => {
    setName("");
    setValidation(false);
    setName("");
    setRegex("");
    setEntityType("FreeTextEntity");
    handleClose();
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={onClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack direction={"column"} gap={2} sx={style}>
            <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>Create new entity</Typography>
            <TextField
              size={"small"}
              label={"Entity name"}
              value={name}
              error={name !== "" && project?.entities?.filter(i => i.name === name && entity?.id !== i.id).length > 0}
              helperText={
                name !== "" && project?.entities?.filter(i => i.name === name && entity?.id !== i.id).length > 0 ?
                  "This name already exists."
                  : "Entity name must be in lowercase and without spaces (_)"
              }
              onChange={e => setName(e.target.value?.replaceAll(" ", "_").toLowerCase())}
            />
            <FormControl size={"small"} fullWidth>
              <InputLabel id="entity-label">Entity Type</InputLabel>
              <Select
                labelId="entity-label"
                id="entity-select"
                value={entityType}
                label="Entity Type"
                onChange={(e) => {
                  const value = e.target.value;
                  setEntityType(value);
                  if (value === "FreeTextEntity") {
                    setRegex("");
                    setValidation(false);
                  }
                }}
              >
                <MenuItem value={"FreeTextEntity"}>FreeTextEntity</MenuItem>
                <MenuItem value={"RegexEntity"}>RegexEntity</MenuItem>
                <MenuItem value={"ApiRegexEntity"}>ApiRegexEntity</MenuItem>
                <MenuItem value={"NEREntity"}>NEREntity</MenuItem>
                <MenuItem value={"LLMExtractionBoostedEntity"}>LLMExtractionBoostedEntity</MenuItem>
              </Select>
            </FormControl>

            {
              entityType === "RegexEntity" &&
              <>
                <FormControlLabel control={
                  <Checkbox
                    size="small"
                    onChange={(e, value) => setValidation(value)}
                    checked={validation}
                  />} label={<Typography fontSize={13}>Validation</Typography>}/>
                <TextField
                  size={"small"}
                  label={"Regex"}
                  disabled={!validation}
                  value={regex}
                  helperText={"Enter your regex for validation"}
                  onChange={e => setRegex(e.target.value)}
                />
              </>
            }
            {
              entityType === "ApiRegexEntity" &&
              <>
                <FormControlLabel control={
                  <Checkbox
                    size="small"
                    onChange={(e, value) => setValidation(value)}
                    checked={validation}
                  />} label={<Typography fontSize={13}>Validation</Typography>}/>
                <TextField
                  size={"small"}
                  label={"API url"}
                  disabled={!validation}
                  value={regex}
                  helperText={"Enter your api url for validation"}
                  onChange={e => setRegex(e.target.value)}
                />
              </>
            }
            {
              entityType === "NEREntity" &&
              <>
                <FormControlLabel control={
                  <Checkbox
                    size="small"
                    onChange={(e, value) => setValidation(value)}
                    checked={validation}
                  />} label={<Typography fontSize={13}>Validation</Typography>}/>
                <TextField
                  size={"small"}
                  label={"NER Entity"}
                  disabled={!validation}
                  value={regex}
                  onChange={e => setRegex(e.target.value)}
                />
              </>
            }
            {
              entityType === "LLMExtractionBoostedEntity" &&
              <>
                <SystemPromptField
                  value={systemPrompt}
                  onChange={(e) => setSystemPrompt(e.target.value)}
                />
                <Box>
                  <ParserModal
                    handleClose={() => setExpandParser(false)}
                    open={expandParser}
                    onSuccess={(content) =>setOutputParser(content || "")}
                    text={outputParser}
                  />
                  <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>
                    <Typography fontWeight={700}>Javascript</Typography>
                    <Box>
                      <IconButton onClick={() => setExpandParser(true)}>
                        <IconArrowsDiagonal size={15}/>
                      </IconButton>
                    </Box>
                  </Stack>
                  <Editor
                    height="200px"
                    defaultLanguage="javascript"
                    value={outputParser}
                    // onChange={(value, e) => onChangeData(isHook ? "api_output_parser" : "parser", value)}
                  />
                </Box>
              </>
            }

            <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
              <Button
                variant={"contained"}
                color={"secondary"}
                sx={{borderRadius: 0, textTransform: "capitalize"}}
                disabled={!name || project?.entities?.filter(i => i.name === name && entity?.id !== i.id).length > 0 || (entityType === "RegexEntity" && validation && !regex)|| (entityType === "LLMExtractionBoostedEntity" && !systemPrompt && !outputParser)}
                onClick={onCreate}
              >
                Save
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                sx={{borderRadius: 0, textTransform: "capitalize"}}
                onClick={onClose}
              >
                Cancel
              </Button>
              {
                entity?.id &&
                <Button
                  variant={"outlined"}
                  color={"error"}
                  sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
                  onClick={() => {
                    onRemove(entity?.id);
                    onClose();
                  }}
                >
                  <IconTrash size={20}/>
                </Button>
              }
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
