import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {NodeType} from "../../../../../model/ModelData";

export const ConfirmationSettings = ({node, setNode, project, onUpdate, onChangeData, nodes}) => {
  let items = nodes.filter(
    item => node?.id !== item.id
      && [NodeType.QA].includes(item.type)
  )

  return (
    <>
      <Typography fontWeight={700} fontSize={13}>Confirmation</Typography>
      <FormControlLabel control={
        <Checkbox
          size={"small"}
          checked={node?.data?.confirmation}
          onChange={(e) => onChangeData('confirmation', e.target.checked)}
          onBlur={() => onUpdate(node)}
        />} label="Confirm"/>

      <TextField
        value={node?.data?.confirmation_message}
        disabled={!node?.data?.confirmation}
        size={"small"}
        label={"Confirmation message"}
        placeholder={"Write message"}
        helperText={"Ask for confirmation"}
        onChange={(e) => onChangeData('confirmation_message', e.target.value)}
        onBlur={() => onUpdate(node)}
      />
      <TextField
        value={node?.data?.confirmation_params?.join(", ") || ""}
        disabled={!node?.data?.confirmation}
        size={"small"}
        label={"Confirmation parameters"}
        placeholder={"Write parameters"}
        helperText={"Write new param with (,)"}
        onChange={(e) => onChangeData('confirmation_params', e.target.value?.replaceAll(", ", ",").split(","))}
        onBlur={() => onUpdate(node)}
      />
      <FormControlLabel control={
        <Checkbox
          size={"small"}
          sx={{color: "text.primary"}}
          checked={node?.data?.qa_inherit_from_parent}
          onChange={(e) =>{
            onChangeData("qa_inherit_from_parent", e.target.checked)
            if (e.target.checked) {
              onChangeData("confirmation_no_match", null)
            }
          }}
          onBlur={() => onUpdate(node)}
        />} label="Inherit 'no_match' route from parent"/>

      <FormControl size={"small"}
                   disabled={!node?.data?.confirmation || node?.data?.qa_inherit_from_parent}
                   fullWidth>
        <InputLabel id={`confirmation-no-match-label`}
                    sx={{textTransform: "capitalize"}}>Confirmation No-Match route</InputLabel>
        <Select
          labelId={`confirmation-no-match-label`}
          id={`confirmation-no-match-select`}
          value={node?.data?.confirmation_no_match || null}
          label={"Confirmation No-Match route"}
          onBlur={() => onUpdate(node)}
          onChange={(e) => onChangeData('confirmation_no_match', e.target.value)}
        >
          <MenuItem value={null}><em>None</em></MenuItem>
          {items.map(n =>
            <MenuItem key={n?.id}
                      value={n?.id}>{n?.name || n?.id}</MenuItem>)}
        </Select>
      </FormControl>

    </>
  )
}