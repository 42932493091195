import React, {useEffect, useState} from "react";
import {Button, Fade, IconButton, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {FlowEntity, NodeEntity, NodeType} from "../../../../model/ModelData";
import {v4 as uuidv4} from "uuid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "280px",
    maxHeight: "80vh",
    bgcolor: 'background.paper',
    borderRadius: 0,
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    overflowY: "auto"
};

export const CreateFlowModal = ({open, handleClose, flow, handleSubmit, project}) => {
    const [name, setName] = useState("");
    useEffect(() => {
        if (flow) {
            setName(flow?.name);
        }
    }, [flow]);

    const onCreateFlow = async () => {
        if (!name) {
            return;
        }
        const data = {...project};
        let id = uuidv4();
        let entity = {...FlowEntity}
        if (flow?.id) {
            id = flow?.id;
            entity = {...flow}
        }
        const newFlow = {...entity, id: id, name: name};

        if (!flow) {
            const textNode = {
                ...NodeEntity,
                id: uuidv4(),
                name: "Welcome",
                type: NodeType.TEXT,
                data: {
                    greet: [""]
                }
            }
            const startNode = {
                ...NodeEntity,
                id: uuidv4(),
                name: "Start",
                type: NodeType.START,
                data: {
                    greet: [""]
                },
                routes: {
                    static: {
                        next: {
                            id: uuidv4(),
                            type: "next",
                            target: textNode.id,
                            data: {
                                greet: ""
                            }
                        },
                    }
                }
            }
            newFlow.nodes = [startNode, textNode];
        }
        if (data.flows.find(f => f.id === newFlow.id)) {
            data.flows = data?.flows?.map(f => {
                if (f.id === newFlow.id) {
                    return newFlow;
                }
                return f;
            })
        } else {
            data.flows.push(newFlow);
        }

        handleSubmit(data);
        handleClose();
    }

    const onDelete = async () => {
        if (!flow) {
            return;
        }
        const data = {...project};
        data.flows = data?.flows.filter(f => f.id !== flow.id);

        handleSubmit(data);
        handleClose();
    }

    const onClose = () => {
        setName("");
        handleClose();
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open} timeout={500}>
                <Box>
                    <Box sx={{position: "fixed", top: 10, right: 10}}>
                        <IconButton
                            size={"small"}
                            onClick={onClose}
                            color={"#FFF"}
                        >
                            <IconX color={grey[300]}/>
                        </IconButton>
                    </Box>

                    <Stack direction={"column"} gap={2} sx={style}>
                        <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>Create new flow</Typography>
                        <TextField
                            size={"small"}
                            label={"Flow name"}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />

                        <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                disabled={!name}
                                onClick={onCreateFlow}
                            >
                                Save
                            </Button>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {
                                flow && <Button
                                variant={"outlined"}
                                color={"error"}
                                sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    )
}
