import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import DraggableDialog from "../../../../components/dialog/DraggableDialog";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../../../config/firebase";
import RecursiveTable from "../../../../components/table/RecursiveTable";
import BasicTabs from "../../../../components/tabs/BasicTabs";

function useLocalStorageListener(key, callback) {
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key) {
        // The value in localStorage with the specified key has changed.
        // You can update state or perform any other actions here.
        callback(e.newValue);
      }
    };

    // Add event listener for the 'storage' event
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, callback]);
}


export const Debugger = ({open, updatePosition}) => {
  // State to hold the value from localStorage
  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem('debug') || null
  );
  const [debug, setDebug] = useState();

  // Use the custom hook to listen for changes
  useLocalStorageListener('debug', (newValue) => {
    // Update the state with the new value from localStorage
    setLocalStorageValue(newValue || null);
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "debug", localStorageValue), (doc) => {
      const debugData = doc.data();

      setDebug(debugData);
      updatePosition(debugData?.position?.origin_id || debugData?.position?.id)
    });

    return () => unsubscribe();
  }, [localStorageValue]);

  const getContent = (key, value) => {
    if (typeof value === "string") {
      return <RecursiveTable data={{[key]: value}} />
    }
    if (key === "control") {
      return <RecursiveTable data={value} />
    }
    if (key === "params" && value) {
      return Object.entries(value).map(([k, v]) => <RecursiveTable title={k} data={v} />)
    }
    return null;
  }

  return (
    <DraggableDialog
      open={open && !!debug}
    >
      {
        debug ?
        <BasicTabs
          tabs={["Control", "Params"]}
          tabContents={[getContent("control", debug["control"]), getContent("params", debug["params"])]}
        />
        :
        <Typography>Debug data not found</Typography>
      }
    </DraggableDialog>
  )
}