import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Autocomplete,
  Box, Checkbox,
  FormControlLabel, IconButton,
  Stack, TextField, Tooltip,
  Typography
} from "@mui/material";
import {IconChevronDown, IconPlus, IconTrash} from "@tabler/icons-react";
import {menuItemsInfo} from "../editMenuItems";
import {v4 as uuidv4} from "uuid";
import {NodeType} from "../../../../../model/ModelData";

const staticRouteDescription = {
  next: "This route will be next when node finishes",
  no_match: "This route will only be executed if we don't find a any route (if we don't catch any of the intents)",
  fail: "This route is executed if any error occurs"
}

export const StaticRoutesTab = ({node, setNode, nodes, onUpdate, flow, selectedRoute, routes = ["next", "no_match", "fail"]}) => {

  const createRoute = (route) => {
    return {
      id: uuidv4(),
      target: null,
      name: null,
      type: route,
      backstack: false,
      data: {
        greet: "",
        params: []
      }
    }
  }

  let items = nodes.filter(
    item => node?.id !== item.id
      && ![NodeType.END_FLOW].includes(item.type)
  )
  if (node?.data?.form_id && node?.type !== NodeType.END_FORM) {
    // items = items.filter(i => i?.data?.form_id === node?.data?.form_id);
  } else {
    items = items?.filter(i => !i?.data?.form_id || i?.type === NodeType.START_FORM);
  }
  items = items?.map(node => ({...node, groupName: `Flow: ${flow?.name}`}));
  if (node?.data?.form_id) {
    const formNode = items?.find(item => item?.data?.form_id === node?.data?.form_id && item?.type === NodeType.START_FORM);
    if (node?.type === NodeType.END_FORM) {
      items = items?.map(item => {
        if (item?.data?.form_id === node?.data?.form_id) {
          return {...item, groupName: `Form: ${formNode?.name || flow?.name}`}
        }
        return {...item};
      });
    } else {
      items = items?.filter(item => item?.data?.form_id === node?.data?.form_id)?.map(item => ({...item, groupName: `Form: ${formNode?.name || flow?.name}`}));
    }
  }
  items = items.sort((a, b) => -b.groupName.localeCompare(a.groupName))

  const {primary, secondary} = menuItemsInfo.static;
  console.log(items);
  return (
    <>
      <Box>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"secondary2"} fontSize={13}>{secondary}</Typography>
      </Box>
      {
        routes?.map(route => {
          const selectedNode = nodes?.find(n => n?.id === node?.routes?.static[route]?.target);
          return (
            <Accordion
              elevation={0}
              defaultExpanded={node?.routes?.static[route]?.id === selectedRoute}
              sx={{
                maxWidth: "inherit",
                my: 0,
                pb: 0,
                border: "0 !important",
                "&.Mui-expanded": {my: "0 !important", border: 0},
                "&.MuiPaper-elevation": {border: 0}
              }}
            >
              <AccordionSummary
                expandIcon={<IconChevronDown size={15}/>}
                aria-controls={`${route}-content`}
                id={`${route}-header`}
                sx={{p: 0, border: 0}}
              >
                <Box>
                  <Typography fontWeight={"bold"} textTransform={"capitalize"}
                              fontSize={13}>{route?.replaceAll("_", " ")}</Typography>
                  {staticRouteDescription[route] &&
                    <Typography variant={"secondary2"} fontSize={12}>{staticRouteDescription[route]}</Typography>}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{my: 0, p: 0}}>
                <Stack direction={"column"} gap={1}>
                  <Autocomplete
                    labelId={`${route}-label`}
                    id={`${route}-select`}
                    renderInput={(props) => <TextField {...props} size={"small"} label={route?.replaceAll("_", " ")}/>}
                    options={items}
                    groupBy={option => option?.groupName}
                    renderGroup={params => (
                      <li key={params.key}>
                        <Typography sx={{fontWeight: 700, p: 1, bgcolor: "rgba(0,0,0,0.015)"}}>{params.group} <IconChevronDown size={13} /></Typography>
                        <div style={{padding: 4, borderRadius: "4px !important"}}>
                          {params.children}
                        </div>
                      </li>
                    )}
                    getOptionLabel={option => option?.name}
                    value={selectedNode || null}
                    blurOnSelect
                    onChange={(e, value) => {
                      const newNode = {...node};
                      const selectedNode = value;
                      if (selectedNode) {
                        let next = {...newNode.routes.static[route]};
                        if (!next) {
                          next = createRoute(route);
                        }
                        next.id = next?.id || uuidv4();
                        next.target = selectedNode.id;
                        next.name = selectedNode.name;
                        newNode.routes.static[route] = next;
                      } else {
                        newNode.routes.static[route] = null;
                      }
                      onUpdate(newNode)
                    }}
                  />

                  <TextField
                    label={"Greet message"}
                    size={"small"}
                    fullWidth
                    value={node?.routes?.static[route]?.data?.greet || null}
                    onChange={(e) => {
                      setNode(prev => {
                        const newNode = {...prev};
                        let next = {...newNode.routes.static[route]};
                        if (!next) {
                          next = createRoute(route);
                        }
                        const data = {...next.data};
                        data.greet = e.target.value;

                        next.id = next?.id || uuidv4();
                        next.data = data;
                        newNode.routes.static[route] = next
                        return newNode;
                      })
                    }}
                    onBlur={() => onUpdate(node)}
                  />
                  <FormControlLabel control={
                    <Checkbox
                      size="small"
                      onChange={(e, value) => {
                        setNode(prev => {
                          const newNode = {...prev};
                          let next = {...newNode.routes.static[route]};
                          if (!next) {
                            next = createRoute(route);
                          }
                          next.id = next?.id || uuidv4();
                          next.backstack = value;
                          newNode.routes.static[route] = next
                          if (newNode !== node) {
                            onUpdate(newNode)
                          }
                          return newNode;
                        })
                      }}
                      checked={node?.routes?.static[route]?.backstack || false}
                    />} label={<Typography fontSize={13}>Backstack</Typography>}/>

                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography fontWeight={700} fontSize={13} sx={{flex: 1}}>Params</Typography>
                    <Tooltip title={"Add new param"}>
                      <IconButton
                        onClick={() => {
                          setNode(prev => {
                            const newNode = {...prev};
                            let next = {...newNode.routes.static[route]};
                            if (!next) {
                              next = createRoute(route);
                            }
                            const data = {...next.data};
                            data.params = [...data?.params || [], ""];
                            next.id = next?.id || uuidv4();
                            next.data = data;
                            newNode.routes.static[route] = next
                            return newNode;
                          })
                        }}
                      >
                        <IconPlus size={17}/>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {
                    node?.routes?.static[route]?.data?.params?.map((param, index) => {
                      return <Stack
                        key={`${param}-${index}`}
                        direction={"row"}
                        gap={1}
                      >
                        <TextField
                          label={`Param ${index + 1}`}
                          size={"small"}
                          sx={{flex: 1}}
                          defaultValue={param}
                          onBlur={e => {
                            setNode(prev => {
                              const newNode = {...prev};
                              let next = {...newNode.routes.static[route]};
                              if (!next) {
                                next = createRoute(route);
                              }
                              const data = {...next.data};
                              data.params[index] = e.target.value;
                              next.id = next?.id || uuidv4();
                              next.data = data;
                              newNode.routes.static[route] = next;

                              if (newNode !== node) {
                                onUpdate(newNode)
                              }
                              return newNode;
                            })
                          }}
                        />
                        <IconButton
                          color={"error"}
                          onClick={() => {
                            setNode(prev => {
                              const newNode = {...prev};
                              let next = {...newNode.routes.static[route]};
                              if (!next) {
                                next = createRoute(route);
                              }
                              const data = {...next.data};
                              data.params = data?.params?.filter((p, ind) => ind !== index);
                              next.id = next?.id || uuidv4();
                              next.data = data;
                              newNode.routes.static[route] = next;

                              if (newNode !== node) {
                                onUpdate(newNode)
                              }
                              return newNode;
                            })
                          }}
                        >
                          <IconTrash size={15}/>
                        </IconButton>
                      </Stack>
                    })
                  }
                </Stack>

              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </>
  )
}