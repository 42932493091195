export const IconSwitch = ({size = 16, color = "#252525"}) => (
  <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 17H5.397C6.20092 16.9999 6.99298 16.806 7.70601 16.4347C8.41904 16.0634 9.03203 15.5256 9.493 14.867L9.67 14.614M13.33 9.387L13.507 9.133C13.968 8.47437 14.581 7.9366 15.294 7.56528C16.007 7.19397 16.7991 7.00006 17.603 7H21"
      stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 4L21 7L18 10" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M3 7H5.397C6.20092 7.00006 6.99298 7.19397 7.70601 7.56528C8.41904 7.9366 9.03203 8.47437 9.493 9.133L13.507 14.867C13.968 15.5256 14.581 16.0634 15.294 16.4347C16.007 16.806 16.7991 16.9999 17.603 17H21"
      stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 20L21 17L18 14" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
