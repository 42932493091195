import axios from "../config/axios-kb"


export const KnowledgeRepository = {
  getSelectors: (organization_id, url) => {
    return axios.get(`/api/knowledge/selectors`, {
      params: {
        organization_id,
        url
      }
    });
  },
  getAll: () => {
    return axios.get(`/api/knowledge`);
  },
  getById: (id) => {
    return axios.get(`/api/knowledge/${id}`);
  },
  filterKnowledge: ({organization_id, topic_id = null, slot_id = null, query = null, page = 1, size = 10, ...props}) => {
    return axios.get(`/api/knowledge/filter`, {
      params: {
        ...props,
        organization_id,
        topic_id,
        slot_id,
        query,
        page,
        size
      }
    });
  },
  delete: (id) => {
    return axios.delete(`/api/knowledge/${id}`)
  },
  create: (formData, setUploadProgress) => {
    return axios.post('/api/knowledge',
      formData,
      {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      })
  },
  edit: (id, formData, setUploadProgress) => {

    return axios.post(`/api/knowledge/${id}/edit`,
      formData,
      {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      })
  },
  updateData: (id, data) => {
    let params = {
      "data": data
    }
    return axios.post(`/api/knowledge/${id}`, params)
  },
  getBySlotId: (slotId) => {
    return axios.get(`/api/knowledge/slot/${slotId}`);
  },
  publish: (id) => {
    return axios.get(`/api/knowledge/${id}/publish`)
  },
  editChunk: (id, uuid, data) => {
    //https://api.kb.openbrain.io/api/knowledge/doc_id/1914/uuid/f4284221-0ab8-45a1-9500-b302b2763737
    return axios.patch(`/api/knowledge/doc_id/${id}/uuid/${uuid}`, data);
  },
  unpublish: (id) => {
    return axios.get(`/api/knowledge/${id}/unpublish`)
  },
  republish:(id) => {
    return axios.get(`/api/knowledge/${id}/republish`)
  },
  preview:(id) => {
    return axios.get(`/api/knowledge/${id}`)
  },
  deleteByUUID(knowledgeId, uuid) {
    return axios.delete(`/api/knowledge/doc_id/${knowledgeId}/uuid/${uuid}`)
  }
}