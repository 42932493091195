import axios from "../config/axios-kb"


export const ConversationsRepository = {
  getAll: () => {
    return axios.get(`/api/conversations`);
  },
  getById: (id) => {
    return axios.get(`/api/conversations/${id}`);
  },
  filterConversations: ({model_id, query = null, page = 1, size = 10, ...props}) => {
    return axios.get(`/api/conversations`, {
      params: {
        ...props,
        model_id,
        // query,
        page,
        size
      }
    });
  },
  delete: (id) => {
    return axios.delete(`/api/conversations/${id}`)
  },
  create: (formData) => {
    return axios.post('/api/conversations', formData)
  },
  edit: (id, formData) => {

    return axios.put(`/api/conversations/${id}/edit`, formData)
  },
  addMessage: ({conversationId, message}) => {
    return axios.post(`/api/conversations/${conversationId}/messages`, message)
  }
}