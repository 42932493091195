import React, {useContext, useEffect, useState} from "react";
import {Button, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography} from "@mui/material";
import {KB_TYPE} from "../../../../../model/ModelData";
import {KBModal} from "../knowledge-base/KBModal";
import {SlotRepository} from "../../../../../repositories/SlotRepository";
import {TopicRepository} from "../../../../../repositories/TopicRepository";
import {SignInContext} from "../../../../../context/SignInContext";

export const KnowledgeBaseSettings = ({node, setNode, project, onUpdate}) => {
  const {organizations} = useContext(SignInContext);
  const [openKBIframe, setOpenKBIframe] = useState(false);
  const [topics, setTopics] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    setSelectedOrganization(project?.settings?.organization || null);
    TopicRepository.getAll()
      .then(response => {
        setTopics(response.data);
      })
      .catch(err => console.log(err));

    SlotRepository.getAll()
      .then(response => {
        setSlots(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  const onChangeData = (key, value) => {
    setNode(prev => {
      const state = {...node};
      state.data[key] = value;

      return state;
    })
  }

  return (
    <>
      {/*<Typography fontWeight={700} fontSize={13}>Knowledge base</Typography>*/}
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography>API</Typography>
        <Switch
          checked={node?.data?.kb_type === KB_TYPE.URL}
          onBlur={() => onUpdate(node)}
          onChange={(e) => onChangeData('kb_type', e.target.checked ? KB_TYPE.URL : KB_TYPE.API)}
        />
        <Typography>URL</Typography>
      </Stack>

      {
        node?.data?.kb_type === KB_TYPE.URL ?
          <TextField
            label={"URL"}
            size={"small"}
            value={node?.data?.url}
            placeholder={"Enter URL here"}
            helperText={"Knowledge base URL scroller"}
            onBlur={() => onUpdate(node)}
            onChange={(e) => {
              const value = e.target.value;
              if (isFinite(value) && value > 0) {
                onChangeData('url', value)
              }
            }}
          /> :
          <>
            <FormControl size={"small"}>
              <InputLabel id={`organizations-label`}
                          sx={{textTransform: "capitalize"}}>Organization</InputLabel>
              <Select
                labelId={`organizations-label`}
                id={`organizations-select`}
                label={"Organization"}
                value={selectedOrganization || ""}
                onBlur={() => onUpdate(node)}
                onChange={e => {
                  const value = e.target.value === "" ? null : e.target.value;
                  setSelectedOrganization(value)
                  setNode(prev => {
                    const state = {...prev};
                    state.data.slot_id = null;
                    state.data.topic_id = null;
                    return state;
                  })
                }}
              >
                <MenuItem value={""}><em>None</em></MenuItem>
                {organizations?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl size={"small"}>
              <InputLabel id={`topic-label`}
                          sx={{textTransform: "capitalize"}}>Topic</InputLabel>
              <Select
                labelId={`topic-label`}
                id={`topic-select`}
                label={"Topic"}
                value={node?.data?.topic_id}
                onBlur={() => onUpdate(node)}
                onChange={e => {
                  const value = e.target.value;
                  setNode(prev => {
                    const state = {...prev};
                    state.data.topic_id = value === "" ? null : value;
                    state.data.slot_id = null;
                    return state;
                  })
                }}
              >
                <MenuItem value={""}><em>None</em></MenuItem>
                {selectedOrganization &&
                  topics?.filter(item => item?.org_id === selectedOrganization)?.map(item => <MenuItem key={item?.id}
                                                                                                       value={item?.id}>{item?.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl size={"small"}>
              <InputLabel id={`slot-label`}
                          sx={{textTransform: "capitalize"}}>Slot</InputLabel>
              <Select
                labelId={`slot-label`}
                id={`slot-select`}
                label={"Slot"}
                value={node?.data?.slot_id}
                onBlur={() => onUpdate(node)}
                onChange={e => {
                  const value = e.target.value;
                  setNode(prev => {
                    const state = {...prev};
                    state.data.slot_id = value === "" ? null : value;
                    return state;
                  })
                }}
              >
                <MenuItem value={""}><em>None</em></MenuItem>
                {slots?.filter(item => item?.topic_id === node?.data?.topic_id)?.map(item => <MenuItem key={item?.id}
                                                                                                       value={item?.id}>{item?.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Button
              variant={"contained"}
              disabled={!node?.data?.slot_id || !node?.data?.topic_id || !selectedOrganization}
              onClick={() => setOpenKBIframe(true)}
            >
              Open Knowledge
            </Button>
            <KBModal
              open={openKBIframe && node?.data?.slot_id && node?.data?.topic_id && selectedOrganization}
              slot={node?.data?.slot_id}
              topic={node?.data?.topic_id}
              organization={selectedOrganization}
              project={project}
              handleClose={() => setOpenKBIframe(false)}
              // url={`https://kb.openbrain.io/knowledge/${selectedOrganization}/${node?.data?.topic_id}/${node?.data?.slot_id}`}
            />
          </>
      }
    </>
  )
}
