import React, {useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Modal from "@mui/material/Modal";
import {
  IconSquareRounded, IconSquareRoundedCheckFilled,
  IconSquareRoundedMinusFilled
} from "@tabler/icons-react";
import {TopicRepository} from "../../../../../repositories/TopicRepository";
import {SlotRepository} from "../../../../../repositories/SlotRepository";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "330px",
  maxHeight: "80vh",
  width: "100%",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const ChooseSlot = ({open, handleClose, handleSuccess, project}) => {
  const [topics, setTopics] = useState([]);
  const [slots, setSlots] = useState([]);

  const [topic, setTopic] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    TopicRepository.getAll()
      .then(response => {
        setTopics(response.data);
      })
      .catch(err => console.log(err));

    SlotRepository.getAll()
      .then(response => {
        setSlots(response.data);
      })
      .catch(err => console.log(err));
  }, [project])

  useEffect(() => {
    if (!open) {
      setTopic(null);
      setSlots(null);
      setDescription("");
    }
  }, [open])

  const onSave = () => {
    if (selectedSlots !== null) {
      const slotObjects = slots?.filter(s => selectedSlots?.includes(s?.id));
      if (slotObjects?.length > 0) {
        const newSlots = slotObjects?.map(slot => {
          return {id: slot?.id, name: slot?.name, topic_id: slot?.topic_id, desc: description || ""};
        });
        handleSuccess(newSlots);
        handleClose();
      }
    }
  }

  const filteredSlots = slots?.filter(item => item?.topic_id == topic) || [];

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Fade in={open} timeout={500}>
        <Stack direction={"column"} gap={2} sx={style}>
          <Typography variant={"h4"} fontWeight={700}>Choose slot</Typography>
          <FormControl size={"small"}>
            <InputLabel id={`topic-label`}
                        sx={{textTransform: "capitalize"}}>Topic</InputLabel>
            <Select
              labelId={`topic-label`}
              id={`topic-select`}
              label={"Topic"}
              value={topic === null ? "none" : topic}
              onChange={e => {
                const value = e.target.value;
                setTopic(value === "none" ? null : value);
                setSelectedSlots([]);
              }}
            >
              <MenuItem value={"none"}><em>None</em></MenuItem>
              {topics?.filter(item => item?.org_id == project?.settings?.organization)?.map(item => <MenuItem
                key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl size={"small"} sx={{maxHeight: "10rem"}}>
            <InputLabel id={`slot-label`}
                        sx={{textTransform: "capitalize"}}>Slot</InputLabel>
            <Select
              labelId={`slot-label`}
              id={`slot-select`}
              label={"Slot"}
              value={selectedSlots === null ? [] : selectedSlots}
              multiple
              onChange={e => {
                const value = e.target.value;
                let selected = typeof value === 'string' ? value.split(',') : value;
                if (selected?.includes('all')) {
                  if (filteredSlots.length === selectedSlots?.length) {
                   selected = [];
                  } else {
                    selected = filteredSlots?.map(s => s?.id);
                  }
                }
                setSelectedSlots(selected);
              }}
              inputProps={{ className: "hide-icon" }}
            >
              <MenuItem value={"all"}>
                <Checkbox className={"hidden-checkbox"} icon={<IconSquareRounded />} checkedIcon={<IconSquareRoundedMinusFilled />} checked={filteredSlots?.length === selectedSlots?.length} />
                {
                  filteredSlots?.length === selectedSlots?.length ?
                    <em>Select all slots</em>
                    :
                    <em>Unselect all slots</em>
                }
                </MenuItem>
              {filteredSlots?.map(item => (
                <MenuItem key={item?.id} value={item?.id}>
                  <Checkbox className={"hidden-checkbox"} icon={<IconSquareRounded />} checkedIcon={<IconSquareRoundedCheckFilled />} checked={selectedSlots?.includes(item?.id)} />
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label={"Description"}
            multiline
            minRows={2}
            maxRows={5}
          />

          <Stack direction={"row"} gap={1}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={onSave}
              disabled={!topic || !selectedSlots}
              sx={{flex: 1}}
            >
              Create
            </Button>
            <Button
              variant={"outlined"}
              color={"secondary"}
              onClick={handleClose}
              sx={{flex: 1}}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  )
}