import axios from "../config/axios-kb"


export const LLMFineTuningModelRepository = {
  getAll: ({organization_id}) => {
    return axios.get(`/api/llm_fine_tuned_models/all`, {
      params: {
        organization_id
      }
    });
  },
  getById: ({modelId}) => {
    return axios.get(`/api/llm_fine_tuned_models/${modelId}`);
  },
  getPaged: ({page = 1, size = 10, organization_id}) => {
    return axios.get(`/api/llm_fine_tuned_models/`, {
      params: {
        page, size, organization_id
      }
    });
  },
  delete: (id) => {
    return axios.delete(`/api/llm_fine_tuned_models/${id}`)
  },
  create: (organization) => {
    return axios.post('/api/llm_fine_tuned_models', organization)
  },
  edit: (id, organization) => {
    return axios.put(`/api/llm_fine_tuned_models/${id}`, organization)
  },
  publish: (id) => {
    return axios.patch(`/api/llm_fine_tuned_models/${id}/publish`)
  }
}