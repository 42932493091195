import React from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconTrash} from "@tabler/icons-react";

export const VarsTab = ({node, setNode, nodes, onUpdate, project}) => {
  const {primary, secondary} = menuItemsInfo.vars;

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"secondary2"} fontSize={13}>{secondary}</Typography>
      </Box>
      {
        node?.data?.params?.map((param, index) => {
          return <Stack direction={"row"} gap={2} flexWrap={"wrap"} useFlexGap sx={{borderBottom: 1, borderColor: "divider", pb:  2}}>
            <Typography fontWeight={700} fontSize={12} sx={{width: "100%"}}>Source</Typography>
            <FormControl size={"small"} sx={{flex: 1}}>
              <InputLabel id={`${index}-source-label`}
                          sx={{textTransform: "capitalize"}}>Scope [{index}]</InputLabel>
              <Select
                labelId={`${index}-source-label`}
                size={"small"}
                label={"Scope"}
                value={param?.source_scope}
                onBlur={() => onUpdate(node)}
                onChange={(e) => {
                  setNode(prev => {
                    const params = prev?.data?.params || [];
                    params[index].source_scope = e.target.value
                    return {
                      ...prev,
                      data: {
                        ...prev.data,
                        params: [
                          ...params
                        ]
                      }
                    }
                  })
                }}
              >
                {node?.data?.form_id && <MenuItem value={"form"}>Form</MenuItem>}
                <MenuItem value={"flow"}>Flow</MenuItem>
                <MenuItem value={"global"}>Global</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size={"small"}
              label={"Source"}
              value={param?.source || ""}
              onBlur={() => onUpdate(node)}
              sx={{flex: 1}}
              onChange={(e) => {
                setNode(prev => {
                  const params = prev?.data?.params || [];
                  params[index].source = e.target.value
                  return {
                    ...prev,
                    data: {
                      ...prev.data,
                      params: [
                        ...params
                      ]
                    }
                  }
                })
              }}
            />
            <Typography fontWeight={700} fontSize={12} sx={{width: "100%"}}>Target</Typography>
            <FormControl size={"small"} sx={{flex: 1}}>
              <InputLabel id={`${index}-target-label`}
                          sx={{textTransform: "capitalize"}}>Scope [{index}]</InputLabel>
              <Select
                labelId={`${index}-target-label`}
                size={"small"}
                label={"Scope"}
                value={param?.target_scope}
                onBlur={() => onUpdate(node)}
                onChange={(e) => {
                  setNode(prev => {
                    const params = prev?.data?.params || [];
                    params[index].target_scope = e.target.value
                    params[index].scope = e.target.value
                    return {
                      ...prev,
                      data: {
                        ...prev.data,
                        params: [
                          ...params
                        ]
                      }
                    }
                  })
                }}
              >
                {node?.data?.form_id && <MenuItem value={"form"}>Form</MenuItem>}
                <MenuItem value={"flow"}>Flow</MenuItem>
                <MenuItem value={"global"}>Global</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size={"small"}
              label={"Target"}
              sx={{flex: 1}}
              value={param?.target || ""}
              onBlur={() => onUpdate(node)}
              onChange={(e) => {
                setNode(prev => {
                  const params = prev?.data?.params || [];
                  params[index].target = e.target.value
                  return {
                    ...prev,
                    data: {
                      ...prev.data,
                      params: [
                        ...params
                      ]
                    }
                  }
                })
              }}
            />
            <Button
              variant={"outlined"}
              size={"small"}
              color={"error"}
              startIcon={<IconTrash size={15} />}
              fullWidth
              onClick={() => {
                setNode(prev => {
                  const state = {...prev}
                  const params = prev?.data?.params.filter((p, ind) => ind !== index);
                  state.data.params = params;
                  onUpdate(state);
                  return state;
                })
              }}
            >
              Delete
            </Button>
          </Stack>
        })
      }
      <Button
        variant={"outlined"}
        size={"small"}
        onClick={() => {
          setNode(prev => {
            const state = {...prev}
            const params = prev?.data?.params || [];
            state.data.params = [
              ...params,
              {
                source_scope: node?.data?.form_id ? "form" : "",
                source: "",
                target: "",
                scope: "",
                target_scope: "" // flow / global
              }
            ]
            return state;
          })
        }}
      >
        Add new var
      </Button>
    </>
  )
}