import {
  Box,
  Button, Chip,
  Collapse, IconButton,
  Menu, MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tooltip, Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {KnowledgeRepository} from "../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {
  IconAppWindow,
  IconChevronDown,
  IconChevronLeft,
  IconCopy,
} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {copyToClipboard} from "../../../../utils/functions";
import {Chunks} from "../knowledge-details/components/Chunks";
import {QueryValidatorRepository} from "../../../../repositories/QueryValidator";
import {Keywords} from "../knowledge-details/components/Keywords";
import {QaDatasetRepository} from "../../../../repositories/QaDatasetRepository";

export const KnowledgeVectorTable = ({
                                       knowledgeData,
                                       query,
                                       topics,
                                       slots,
                                       organization,
                                       project,
                                       updateKnowledge,
                                       fetchData
                                     }) => {

  return (
    <Table className={"kb-table"} sx={{borderCollapse: "collapse", overflowY: "none"}}>
      <TableHead>
        <TableRow>
          {/*<TableCell sx={{fontSize: 12, fontWeight: 700}}>Document</TableCell>*/}
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Content</TableCell>
          {/*<TableCell sx={{fontSize: 12, fontWeight: 700}}>Topic / Slot</TableCell>*/}
          {/*<TableCell sx={{fontSize: 12, fontWeight: 700}}>Preview</TableCell>*/}
        </TableRow>
      </TableHead>
      <TableBody>
        {knowledgeData?.map(knowledge => {
          return <KnowledgeItem
            key={knowledge?.chunk_id} query={query}
            knowledge={knowledge} topics={topics} slots={slots} organization={organization}
            fetchData={fetchData} updateKnowledge={updateKnowledge} project={project}
          />
        })}
      </TableBody>
    </Table>
  )
}

const KnowledgeItem = ({knowledge, project, query, updateKnowledge, organization}) => {
  const rowRef = useRef();
  const [collapse, setCollapse] = useState(false);
  const [docs, setDocs] = useState([]);
  const [isValidated, setIsValidated] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  useEffect(() => {
    if (collapse) {
      if (docs?.length === 0) {
        fetchDocs();
      }
      if (rowRef?.current) {
        setTimeout(() => {
          rowRef?.current?.scrollIntoView({block: "start", inline: "end", behavior: "smooth"});
        }, 300)
      }
    }
  }, [collapse])

  const fetchDocs = () => {
    KnowledgeRepository.getById(knowledge?.id)
      .then(response => {
        setDocs(response.data.docs || []);
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const updateChunk = ({content, keywords}) => {
    const uuid = knowledge?.chunk_id;
    const doc_id = knowledge?.id;
    const data = {
      content,
      keywords
    }

    KnowledgeRepository.editChunk(doc_id, uuid, data)
      .then(() => {
        const newKnowledge = {...knowledge};
        newKnowledge.keywords = keywords || [];
        updateKnowledge(newKnowledge);
      })
      .catch(error => {
        toast.error(error?.message || "Failed to update chunk");
      })
  }

  const updateChunks = (newChunk) => {
    const uid = newChunk?.id;
    setDocs(prev => {
      return prev?.map(doc => {
        const docUid = doc?.metadata?._additional?.id;
        return docUid === uid ? {
          ...doc,
          content: newChunk?.content,
          metadata: {
            ...doc.metadata,
            keywords: newChunk?.keywords
          }
        } : doc;
      })
    })
  }

  const onValidate = (score) => {
    const data = {
      question: query,
      content: knowledge?.document,
      org_id: organization?.id,
      topic_id: +knowledge?.topic_id,
      slot_id: +knowledge?.slot_id,
      relevance_score: knowledge?.relevance_score,
      chunk_id: knowledge?.chunk_id,
      doc_id: knowledge?.id,
      validator_score: score
    }
    QueryValidatorRepository.create(data)
      .then(response => {
        // update score
        const newKnowledge = {...knowledge};
        newKnowledge.validator_score = score;
        updateKnowledge(newKnowledge);
        setIsValidated(score > 0);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection) {
      setContextMenu(
        contextMenu === null
          ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
          : null,
      );
    }
  };

  const onAddKeyword = () => {
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection) {
      // const newChunk = {...chunk};
      // newChunk.metadata.keywords.push(selection);
      const newKnowledge = {...knowledge};
      const newKeywords = [...newKnowledge.keywords, selection] || [];
      updateChunk({content: knowledge?.document, keywords: newKeywords});
    }
    handleClose();
  }
  const onAddAnswer = () => {
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection && query) {
      const range = (window?.getSelection()?.getRangeAt(0) || document?.selection?.getRangeAt(0));

      let start_pos = range?.startOffset || 0;
      let end_pos = range?.endOffset;

      const answerData = {
        question: query,
        content: knowledge?.document,
        answer: selection,
        start_pos: start_pos || null,
        end_pos: end_pos || null,
        topic_id: knowledge?.topic_id || null,
        slot_id: knowledge?.slot_id || null,
        doc_id: knowledge?.id || null,
        org_id: organization?.id || null,
        chunk_id: knowledge?.chunk_id || null,
      }
      QaDatasetRepository.create(answerData)
        .then(() => {
          toast.success("Answer added successfully");
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.message || "Failed to add answer");
        })
    }
    handleClose();
  }

  const handleClose = () => {
    setContextMenu(null);
  };


  const url = `/project/${project.id}/knowledge-base/${knowledge.id}`;

  let {document: content, start_pos, end_pos} = knowledge;
  let highlightedText = null;
  if (content && start_pos && end_pos && end_pos > start_pos) {
    highlightedText = content.substring(start_pos, end_pos);
  }

  return (
    <>
      <TableRow hover={!collapse} ref={rowRef} className={collapse ? "selected" : ""}>
        <TableCell sx={{fontSize: 13, fontWeight: 500, border: 0}}>
          <Stack direction={"row"} gap={1} alignItems={"center"} sx={{py: 1}}>
            <Typography fontWeight={700}
                        fontSize={12}>{knowledge?.id}. {knowledge?.topic_name} / {knowledge?.slot_name}</Typography>
            <Tooltip title={knowledge?.relevance_score}>
              <Chip
                size={"small"}
                sx={{fontSize: 12, ml: "auto"}}
                label={knowledge?.relevance_score?.toFixed(2)}
                color={(+knowledge?.relevance_score || 0) >= 0.5 ? "success" : "error"}
              />
            </Tooltip>
            <Button
              variant={"outlined"}
              color={"success"}
              sx={{
                borderRadius: "1.75rem !important", fontSize: 12,
                border: isValidated === null || isValidated === false ? "0.8px solid transparent !important" : "1px solid !important"
              }}
              onClick={() => onValidate(1)}
            >
              HIT
            </Button>
            <Button
              variant={"outlined"}
              color={"error"}
              sx={{
                borderRadius: "1.75rem !important", fontSize: 12,
                border: isValidated === null || isValidated === true ? "0.8px solid transparent !important" : "1px solid !important"
              }}
              onClick={() => onValidate(0)}
            >
              MISS
            </Button>
            <IconButton size={"small"} onClick={() => setCollapse(!collapse)}>
              {collapse ? <IconChevronDown size={18}/> : <IconChevronLeft size={18}/>}
            </IconButton>
          </Stack>

          <Box style={{cursor: 'context-menu', position: "relative"}}>
            <Typography fontSize={11} sx={{zIndex: 0, opacity: 0, padding: "0"}}>{content}</Typography>
            <Typography fontSize={11} sx={{position: "absolute", inset: "0", zIndex: 2}}>
              {
                highlightedText ?
                  <>
                    {content.substring(0, start_pos)}
                    <span style={{
                      backgroundColor: 'rgba(255, 252, 127, 0.4',
                    }}>{highlightedText}</span>
                    {content.substring(end_pos)}
                  </> : content
              }
            </Typography>
            <Typography fontSize={11}  onMouseUp={handleContextMenu} sx={{position: "absolute", inset: "0", zIndex: 4}}>
              {content}
            </Typography>

            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                  : undefined
              }
              slotProps={{
                paper: {sx: {minWidth: 150}}
              }}
            >
              <MenuItem onClick={onAddKeyword}>Keyword</MenuItem>
              <MenuItem disabled={!query} onClick={onAddAnswer}>Answer</MenuItem>
            </Menu>
          </Box>
          <Keywords
            keywords={knowledge?.keywords || []}
            updateKeywords={(newKeywords) => {
              updateChunk({content: knowledge?.document, keywords: newKeywords});
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow className={collapse ? "selected" : ""}>
        <TableCell colSpan={5} padding={collapse ? "normal" : "none"} sx={{pt: 0}}>
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            <Stack direction={"row"} gap={1}>
              <Button
                component={Link} target={"_blank"}
                to={url}
                startIcon={<IconAppWindow size={18}/>} size={"small"}
                variant={"text"} sx={{color: "primary.dark"}}
              >
                Open
              </Button>
              <Button
                onClick={async () => {
                  try {
                    await copyToClipboard(new URL(window?.location?.href).origin + url);
                    toast.success("URL copied to clipboard")
                  } catch (e) {
                    toast.error(e?.message || "Failed to copy url")
                  }
                }}
                startIcon={<IconCopy size={18}/>} size={"small"}
                variant={"text"} sx={{color: "primary.dark"}}
              >
                Share
              </Button>
            </Stack>
            <Typography fontSize={14} fontWeight={700}>Resources</Typography>
            <Chunks knowledge={knowledge} chunks={docs} fetchData={fetchDocs} updateChunks={updateChunks}
                    query={query}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
