import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {SideMenuFT} from "./SideMenuFT";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {Conversations} from "./conversations/Conversations";
import {LLMFineTuningModelRepository} from "../../../repositories/LLMFineTuningModelRepository";
import {Conversation} from "./conversation/Conversation";
import {toast} from "react-toastify";

export const FineTuning = ({project}) => {
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const navigate = useNavigate();
  const {modelId} = useParams();
  useEffect(() => {
    const initData = async () => {
      try {
        const response = await LLMFineTuningModelRepository.getAll({organization_id: project?.settings?.organization});
        setModels(response.data.models)
        if (!modelId){
          const model = response.data.models[0];
          navigate(`/project/${project?.id}/fine-tuning/${model?.id}`)
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (project) {
      initData();
    }
  }, [project])

  useEffect(() => {
    if (modelId) {
      LLMFineTuningModelRepository.getById({modelId})
        .then(response => setModel(response.data))
        .catch(error => toast.error("Model not found"));
    }
  }, [modelId])

  return (

    <Stack direction={"row"} sx={{maxHeight: "100%", height: "100%"}}>
      <SideMenuFT
        project={project}
        models={models} setModels={setModels}
        model={model} setModel={setModel}
      />
      <Routes>
        <Route path="conversation/:conversationId" index
               element={<Conversation project={project} models={models} model={model} />} />
        <Route path="" index
               element={<Conversations project={project} models={models} model={model} setModel={setModel}/>}/>
      </Routes>
    </Stack>
  )
}