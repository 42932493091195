import React, {useEffect, useState} from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel, FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {TopicRepository} from "../../../../../repositories/TopicRepository";
import {SlotRepository} from "../../../../../repositories/SlotRepository";

export const TeachingSettings = ({node, setNode, project, onUpdate, onChangeData, nodes}) => {
  const [topics, setTopics] = useState([]);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    TopicRepository.getAll()
      .then(response => {
        setTopics(response.data);
      })
      .catch(err => console.log(err));
    SlotRepository.getAll()
      .then(response => {
        setSlots(response.data);
      })
      .catch(err => console.log(err));
  }, [project])

  const filteredSlots = slots?.filter(item => item?.topic_id == node?.data?.topic_id) || [];

  return (
    <>
      <FormControl size={"small"}>
        <InputLabel id={`topic-label`}
                    sx={{textTransform: "capitalize"}}>Topic</InputLabel>
        <Select
          labelId={`topic-label`}
          id={`topic-select`}
          label={"Topic"}
          value={node?.data?.topic_id === null ? "none" : node?.data?.topic_id}
          onChange={e => {
            const value = e.target.value;
            const newNode = {...node};
            newNode.data.topic_id = value === "none" ? null : value;
            newNode.data.slot_id = null;
            setNode(newNode);
          }}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {topics?.filter(item => item?.org_id == project?.settings?.organization)?.map(item => <MenuItem
            key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl size={"small"} sx={{maxHeight: "10rem"}}>
        <InputLabel id={`slot-label`}
                    sx={{textTransform: "capitalize"}}>Slot</InputLabel>
        <Select
          labelId={`slot-label`}
          id={`slot-select`}
          label={"Slot"}
          value={node?.data?.slot_id || "none"}
          // value={selectedSlots === null ? [] : selectedSlots}
          // multiple
          onChange={e => {
            const value = e.target.value;
            const newNode = {...node};
            newNode.data.slot_id = value === "none" ? null : value;
            setNode(newNode);
          }}
          inputProps={{ className: "hide-icon" }}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {filteredSlots?.map(item => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography fontWeight={700}>Paraphraser</Typography>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox checked={!!node?.data?.use_paraphraser}
                      onChange={(event =>  onChangeData("use_paraphraser", event.target.checked))}
                      onBlur={() => onUpdate(node)}/>
          )} label="Use Paraphraser" />
      </FormGroup>
      <TextField
        size={"small"}
        label={"Paraphraser persona"}
        placeholder={"Describe the persona"}
        multiline
        rows={3}
        disabled={!node?.data?.use_paraphraser}
        value={node?.data?.paraphrase_persona}
        onChange={(e) => onChangeData("paraphrase_persona", e.target.value)}
        onBlur={() => onUpdate(node)}
      />
    </>
  )
}