import React, {useEffect, useState} from "react";
import axios from "../config/axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import {OrganizationRepository} from "../repositories/OrganizationRepository";
import {CategoryRepository} from "../repositories/CategoryRepository";

export const TOKEN = "token"

export const SignInContext = React.createContext({
  isAuth: null,
  currentUser: null,
  organizations: [],
  categories: [],
  onLogin: () => {
  },
  onRegister: () => {
  },
  onLogout: () => {
  },
  onOAuth: () => {
  },
  updateOrganizations: () => {
  },
})

export const SignInContextProvider = props => {
  const [isAuth, setIsAuth] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    checkIsAuth();
    if (isAuth) {
      updateOrganizations();
    }
  }, [isAuth])

  const checkIsAuth = async () => {
    try {
      if (isAuth === null) {
        if (localStorage.getItem(TOKEN)) {
          const responseUser = await axios.post('/api/user/me',null , {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
            }
          });
          const user = responseUser.data;
          user.roles = user.authorityList?.map(item => item?.authority) || [];
          setCurrentUser(user);
          setIsAuth(true);
          const isAdmin = user?.roles?.includes("admin");
          let responseOrganizations;
          let responseCategories;
          if (isAdmin) {
            responseOrganizations = await OrganizationRepository.getAll();
            responseCategories = await CategoryRepository.getAll();
          } else {
            responseOrganizations = await OrganizationRepository.getUserOrganizations(user?.username);
            responseCategories = await CategoryRepository.getCategoriesByMember(user?.username);

          }
          setOrganizations(responseOrganizations?.data?.organizations || []);
          setCategories(responseCategories?.data?.categories || []);
        } else {
          setIsAuth(false);
        }
      }
    } catch (error) {
      toast.error("Failed to load user");
      if (localStorage.getItem(TOKEN)) {
        localStorage.removeItem(TOKEN);
      }
      setIsAuth(false)
    }
  }

  const onLogin = async ({username, password}) => {
    try {
      const response = await axios.post(`/login`, { username, password });
      const serviceToken = response.data;
      localStorage.setItem(TOKEN, serviceToken);

      if (serviceToken) {
        const responseUser = await axios.post('/api/user/me');
        const user = responseUser.data;
        user.roles = user.authorityList?.map(item => item?.authority) || [];
        
        setCurrentUser(user);
        setIsAuth(true);
        navigate("/")
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to login");
    }
  }

  const onOAuth = async (token) => {
    localStorage.setItem(TOKEN, token);
    if (token) {
      const responseUser = await axios.post('/api/user/me');
      const user = responseUser.data;
      user.roles = user.authorityList?.map(item => item?.authority) || [];

      setCurrentUser(user);
      setIsAuth(true);
      navigate("/")
    }
  }

  const onRegister = async ({username, email, password, name, lastname}) => {
    try {
      await axios.post('/api/user', {
        username,
        email,
        password,
        name,
        lastname
      });
      navigate("/login");
    } catch (error) {
      console.log(error);
      toast.error("Failed to register");
    }
  }
  const onLogout = async () => {
    try {
      localStorage.removeItem(TOKEN);
      setIsAuth(false);
      setCurrentUser(null);
    } catch (error) {
      console.log(error);
      toast.error("Failed to logout");
    }
  }

  const updateOrganizations = () => {
    const isAdmin = currentUser?.roles?.includes("admin");

    if (isAdmin) {
      OrganizationRepository.getAll()
        .then(response => {
          setOrganizations(response?.data?.organizations || []);
          console.log(response)
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.message || "Failed to fetch organizations");
        })
    } else {
      OrganizationRepository.getUserOrganizations(currentUser?.username)
        .then(response => {
          setOrganizations(response?.data?.organizations || []);
          console.log(response)
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.message || "Failed to fetch organizations");
        })
    }
  }

  return (
    <SignInContext.Provider
      value={{
        isAuth: isAuth,
        currentUser: currentUser,
        organizations: organizations,
        categories: categories,
        onLogin: onLogin,
        onLogout: onLogout,
        onRegister: onRegister,
        onOAuth: onOAuth,
        updateOrganizations: updateOrganizations
      }}
    >
      {isAuth === null ? <LoadingScreen/> : props.children}
    </SignInContext.Provider>
  )
}