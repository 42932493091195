import React, {useEffect, useState} from "react";
import {ExpandModal} from "./ExpandModal";
import {Button, InputBase, Stack, Typography} from "@mui/material";

export const PersonaModal = ({open, handleClose, text, onSuccess}) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (open) {
      setContent(text || "");
    }
  }, [open, text])

  return (
    <ExpandModal
      open={open}
      handleClose={handleClose}
    >
      <Typography fontWeight={700}>Persona</Typography>
      <InputBase
        value={content}
        size={"small"}
        label={"Persona"}
        fullWidth
        placeholder={"Describe persona"}
        sx={{border: 1, borderColor: 'divider', borderRadius: 0, p: 1}}
        multiline
        minRows={3}
        // helperText={"Ask for confirmation"}
        onChange={(e) => setContent(e.target.value)}
      />
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => {
            onSuccess(content);
            setContent("");
            handleClose();
          }}
        >
          Save
        </Button>
        <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={() => {
            setContent("");
            handleClose();
          }}
        >
          Cancel
        </Button>
      </Stack>
    </ExpandModal>
  )
}