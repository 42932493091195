import React, {useContext, useState} from "react";
import {
  Button,
  Stack,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Tooltip,
  Menu,
  Box,
  CircularProgress, Breadcrumbs, ListItemButton, List, ListItemIcon, ListItemText
} from "@mui/material";
import {Link} from "react-router-dom";
import {
  IconClipboardCopy,
  IconCloudUpload, IconFile, IconFileExport,
  IconMenu, IconPlayerPauseFilled, IconPlayerPlayFilled,
} from "@tabler/icons-react";
import {Context as AppContext} from "../../../context/AppContext";
import {grey} from "@mui/material/colors";
import {HeaderDrawer} from "./HeaderDrawer";
import {toast} from "react-toastify";
import {copyToClipboard} from "../../../utils/functions";

export const Header = ({project, flow, node, onUpdate, openChat, toggleChat}) => {
  const {publishVersion, updateProject} = useContext(AppContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const onPublish = async () => {
    try {
      setLoading(true);
      await publishVersion(project);
    } catch (e) {
      console.log(e);
      toast.error("Error! Please try again.")
    } finally {
      setLoading(false);
    }
  }

  const onUpdateProject = async (newProject) => {
    try {
      await updateProject(newProject);
      toast.success("Project updated successfully");
    } catch (err) {
      console.log(err);
      toast.error("Failed to update project")
    }
  }

  return (
    <>
      <HeaderDrawer
        open={openDrawer}
        handleClose={() => setOpenDrawer(false)}
        openCart={() => {
          setOpenDrawer(false);
        }}
      />
      <AppBar position="static"
              sx={{
                bgcolor: "transparent",
                boxShadow: 0,
                zIndex: 100,
                borderBottom: 1,
                minHeight: "48px !important",
                borderColor: "divider"
              }}
      >
        <Toolbar
          sx={{
            bgcolor: "transparent",
            // display: "none",
            zIndex: 100,
            py: "0 !important",
            px: "8px !important",
            minHeight: "48px !important",
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                 alignItems={"center"}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Button
                component={Link}
                to={"/"}
                variant={"text"}
                sx={{flexDirection: "row", color: "dark.main"}}
                startIcon={<img src={"/logo.svg"} alt={"logo"} width={30}/>}
              >
                <Typography fontWeight={700}>BlockFlow</Typography>
              </Button>
            </Stack>


            <Typography fontWeight={700} color="dark.main">{project?.name}</Typography>

            <Stack direction={"row"} gap={1}>


              <Button
                variant={"contained"}
                color={"primary"}
                disabled={!project || loading}
                onClick={onPublish}
                startIcon={loading ?
                  <CircularProgress size={18} sx={{color: grey[700]}}/> :
                  <IconCloudUpload size={18} style={{marginRight: 5}}/>}
                sx={{
                  borderRadius: 0, minWidth: 40, border: 0, boxShadow: 0
                }}
              >
                <Typography sx={{display: {xs: "none", md: "flex"}, p: 0}}>Publish</Typography>
              </Button>
              <Button
                variant={"outlined"}
                color={"primary"}
                disabled={!project || loading}
                onClick={toggleChat}
                sx={{
                  borderRadius: 0, minWidth: 40, boxShadow: 0
                }}
              >
                {openChat ? <IconPlayerPauseFilled size={18}/> : <IconPlayerPlayFilled size={18}/>}
              </Button>
            </Stack>
          </Stack>
        </Toolbar>

        <Toolbar
          sx={{
            bgcolor: "transparent",
            my: 1,
            display: "none"
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                 alignItems={"center"}>
            <Button
              component={Link}
              to={"/"}
              variant={"text"}
              sx={{color: "#252525", flexDirection: "column"}}
            >
              <img src={"/logo.svg"} alt={"logo"} width={50}/>
              {/*<Typography fontSize={13} fontColor={"#252525"} fontWeight={"bold"}>BlockFlow</Typography>*/}
            </Button>

            <IconButton onClick={() => setOpenDrawer(true)}>
              <IconMenu/>
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  )
}


export const ExportProjectButton = ({project, showIconText = false}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorElUser(null);
  };

  const onCopyExport = async (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (project.id) {
      try {
        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/apiProject/${project.id}`
        await copyToClipboard(url);
        toast.success("URL is copied successfully.")
      } catch (e) {
        console.log(e)
        toast.error("URL failed to copy.")
      }
    }

    handleCloseUserMenu(event);
  }
  const onExportFile = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    // Replace with the actual API endpoint that serves the file
    const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/export/${project?.id}`;

    fetch(apiUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${project.id}.json`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();

        // Clean up by removing the anchor and the URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading file: ', error);
      });

    handleCloseUserMenu(event);
  };


  return (
    <>
      {
        showIconText ?
          <Tooltip title="Export">
            <Button
              variant={"outlined"}
              // disabled={!project || loading}
              sx={{color: 'dark.main'}}
              onClick={handleOpenUserMenu}
              startIcon={
                showIconText ? <IconFileExport size={20} stroke={1}/> : null
              }
            >
              Export
            </Button>
          </Tooltip> :
          <Tooltip title="Export" placement={"right"}>
            <Button
              // disabled={!project || loading}
              className={"side-link"}
              sx={{color: 'dark.main', minWidth: 40}}
              onClick={handleOpenUserMenu}
            >
              <IconFileExport stroke={1.5}/>
            </Button>
          </Tooltip>
      }

      <Menu
        component={List}
        sx={{mt: '45px', p: 0, borderRadius: 0, minWidth: "200px"}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{sx: {py: 0, borderRadius: 0, minWidth: "200px"}}}
        PaperProps={{sx: {py: 0, borderRadius: 0, minWidth: "200px"}}}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <ListItemButton onClick={onCopyExport}>
          <ListItemIcon><IconClipboardCopy/></ListItemIcon>
          <ListItemText primary={"Copy URL"}/>
        </ListItemButton>
        <ListItemButton onClick={onExportFile}>
          <ListItemIcon><IconFile/></ListItemIcon>
          <ListItemText primary={"Download File"}/>
        </ListItemButton>
      </Menu>
    </>
  )
}
