import {Box, Divider, IconButton, Typography} from "@mui/material";
import {IconPlus, IconX} from "@tabler/icons-react";

export const MessageItem = ({message, isSelected, onSelect, onDelete, onSelectIndex}) => {
  let content = <PromptMessage onSelect={onSelect} message={message} isSelected={isSelected} onDelete={onDelete}/>;
  if (message?.role === "user") {
    content = <UserMessage onSelect={onSelect} message={message} isSelected={isSelected} onDelete={onDelete}/>
  } else if (message?.role === "assistant") {
    content = <AssistantMessage onSelect={onSelect} message={message} isSelected={isSelected} onDelete={onDelete}/>
  }

  return (
    <>
      {content}

      <Divider sx={{opacity: 0, "&:hover": {opacity: 1}}}><IconButton onClick={onSelectIndex} size={"small"}><IconPlus size={14}/></IconButton></Divider>
    </>
  )
}


const PromptMessage = ({message, isSelected, onSelect, onDelete}) => {
  let bgcolor = isSelected ? "#c7c7c7" : "#e5e5e5";

  return (
    <Box
      maxWidth={"sm"}
      sx={{
        bgcolor, mx: "auto",
        color: "dark.main", minWidth: 100,
        p: 1, borderRadius: 3,
        position: "relative", zIndex: 3,
        "&:hover > .close-btn": {opacity: 1}
      }}
      onClick={() => onSelect(message)}
    >
      <IconButton
        size={"small"}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(message?.id)
        }}
        className={"close-btn"}
        sx={{zIndex: 5, position: "absolute", "top": 3, right: 3, opacity: 0}}
      >
        <IconX size={13}/>
      </IconButton>
      <Typography fontSize={12} fontWeight={700} sx={{pr: 3}}>{message?.role}</Typography>
      <Typography fontSize={12} fontWeight={400}>{message?.content}</Typography>
    </Box>
  )
}

const AssistantMessage = ({message, isSelected, onSelect, onDelete}) => {
  let bgcolor = isSelected ? "#79a9fc" : "#93b7f5";

  return (
    <Box
      maxWidth={"md"}
      sx={{
        bgcolor, mr: "auto",
        color: "dark.main", minWidth: 100,
        p: 1, borderRadius: 3, borderTopLeftRadius: 1,
        position: "relative", zIndex: 3,
        "&:hover > .close-btn": {opacity: 1}
      }}
      onClick={() => onSelect(message)}
    >
      <IconButton
        size={"small"}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(message?.id)
        }}
        className={"close-btn"}
        sx={{zIndex: 5, position: "absolute", "top": 3, right: 3, opacity: 0}}
      >
        <IconX size={13}/>
      </IconButton>
      <Typography fontSize={12} fontWeight={700} sx={{pr: 3}}>{message?.role}</Typography>
      <Typography fontSize={12} fontWeight={400}>{message?.content}</Typography>
    </Box>
  )
}
const UserMessage = ({message, isSelected, onSelect, onDelete}) => {
  let bgcolor = isSelected ? "#7cbda4" : "#8ed3b8";

  return (
    <Box
      maxWidth={"md"}
      sx={{
        bgcolor, ml: "auto",
        color: "dark.main", minWidth: 100,
        p: 1, borderRadius: 3, borderTopRightRadius: 1,
        position: "relative", zIndex: 3,
        "&:hover > .close-btn": {opacity: 1}
      }}
      onClick={() => onSelect(message)}
    >
      <IconButton
        size={"small"}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(message?.id)
        }}
        className={"close-btn"}
        sx={{zIndex: 5, position: "absolute", "top": 3, right: 3, opacity: 0}}
      >
        <IconX size={13}/>
      </IconButton>
      <Typography fontSize={12} fontWeight={700} sx={{pr: 3}}>{message?.role}</Typography>
      <Typography fontSize={12} fontWeight={400}>{message?.content}</Typography>
    </Box>
  )
}