import React, {useContext, useEffect, useState} from "react";
import {
  Avatar,
  Box, Button, Divider,
  IconButton, Input,
  Stack,
  Typography
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconAppWindow, IconChecks, IconPencil, IconPlus, IconX} from "@tabler/icons-react";
import {Context as AppContext} from "../../context/AppContext";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import umAxios from "../../config/axios";
import {SignInContext} from "../../context/SignInContext";
import {EditProjectModal} from "./EditProjectModal";
import {CategoryRepository} from "../../repositories/CategoryRepository";
import {WebSettingsContext} from "../../context/WebSettingsContext";
import {SelectOrganizationModal} from "./components/SelectOrganizationModal";

export const Projects = () => {
  const {currentUser, isAuth, organizations} = useContext(SignInContext);
  const {state: {projects}, getUserProjects, updateProject} = useContext(AppContext);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editProject, setEditProject] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [filter, setFilter] = useState({});
  const {searchParams} = useContext(WebSettingsContext);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverItem, setDragOverItem] = useState(null);


  const organizationId = searchParams.organization || null

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await umAxios.get('/api/language-support/all');
        setLanguages(response.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    const fetchUserProjects = async () => {
      try {
        setLoading(true);
        await getUserProjects();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (isAuth) {
      fetchLanguages();
      fetchCategories();
      if (!projects?.length) {
        fetchUserProjects();
      }
    }
  }, [isAuth, organizations])

  const fetchCategories = async () => {
    const isAdmin = currentUser?.roles?.includes("admin")
    if (isAdmin) {
      CategoryRepository.getAll()
        .then(response => setCategories(response.data.categories))
        .catch(error => console.log(error))
    } else {
      CategoryRepository.getCategoriesByMember(currentUser.username)
        .then(response => setCategories(response.data.categories))
        .catch(error => console.log(error))
    }
  }

  // const onChangeFilter = e => {
  //   const key = e.target.name;
  //   const value = e.target.value;
  //   onUpdateFilter(key, value);
  // }

  // const onUpdateFilter = (key, value) => setFilter(prev => ({...prev, [key]: value}))

  const onUpdateCategory = (category, name) => {
    if (category?.name !== name && name) {
      const newValue = {...category, name};
      CategoryRepository.edit(category.id, newValue)
        .then(() => {
          fetchCategories();
        })
        .catch(error => {
          console.log(error);
        })
    }
  }

  const onDragEnd = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!draggedItem?.id || !draggedItem?.settings?.organization) {
      return;
    }
    const categoryId = dragOverItem?.id || null;
    const draggedProject = {...draggedItem};
    if (categoryId && dragOverItem?.organization_id !== draggedProject?.settings?.organization) {
      return;
    }

    try {
      setLoading(true);
      draggedProject.settings.category = categoryId;
      await updateProject(draggedProject);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  // const labels = [...new Set(projects?.map(project => {
  //   return project?.settings?.labels?.map(label => label.name) || []
  // })?.flat() || [])];

  const filteredProjects = projects?.filter(item => !organizationId || +item?.settings?.organization === +organizationId)
    ?.filter(project => project?.name?.toLowerCase()?.includes(searchParams?.term?.toLowerCase()))


  return (
    <>
      <EditProjectModal
        open={!!editProject} selectedProject={editProject}
        handleClose={() => setEditProject(null)}
      />
      <Stack direction={"row"} gap={2}
             sx={{maxWidth: "100%", maxHeight: "calc(100vh - 70px)", px: 3}}>
        <Stack
          direction={"column"} gap={1}
          sx={{
            p: 1, minWidth: {xs: "100vw", sm: "50vw", md: "35vw", lg: "20vw !important"}
          }}
          onDragOver={() => setDragOverItem(null)}
        >
          <Typography sx={{fontSize: 20, fontWeight: 600, py: 0.5}}>General</Typography>
          <Divider/>
          <Stack direction={"column"} gap={2} sx={{overflowY: "auto", p: 1}}>
            {filteredProjects?.filter(item => !item?.settings?.category)?.map(item => {
              const lang = languages?.find(i => i.id === item?.settings?.language);
              const org = organizations?.find(o => o.id === item?.settings?.organization);
              return (
                <ProjectItem
                  key={item?.id} project={item} language={lang} organization={org}
                  draggable={!loading}
                  onDragStart={() => setDraggedItem(item)}
                  onDragEnd={onDragEnd}
                />
              )
            })}
          </Stack>

          <Button component={Link} to={`/project/create`} startIcon={<IconPlus/>}>
            Create Assistant
          </Button>
        </Stack>

        {
          categories?.filter(category => organizationId === null || +category?.organization_id === +organizationId)?.map(category => {
            return (
              <Stack
                key={category?.id}
                direction={"column"}
                onDragOver={() => setDragOverItem(category)}
                draggable={!loading}
                gap={1}
                sx={{
                  p: 1, minWidth: {xs: "100vw", sm: "50vw", md: "25vw", lg: "20vw !important"}
                }}>
                <Input disableUnderline defaultValue={category?.name}
                       onBlur={(e) => onUpdateCategory(category, e.target.value)}
                       sx={{fontSize: 20, fontWeight: 600}}/>
                <Divider/>
                <Stack direction={"column"} gap={2} sx={{overflowY: "auto", p: 1}}>
                  {filteredProjects?.filter(item => +item?.settings?.category === +category?.id).map(item => {

                    const lang = languages?.find(i => i.id === item?.settings?.language);
                    const org = organizations?.find(o => o.id === item?.settings?.organization);
                    return (
                      <ProjectItem
                        key={item?.id} project={item} language={lang} organization={org}
                        draggable
                        onDragStart={() => setDraggedItem(item)}
                        onDragEnd={onDragEnd}
                      />
                    )
                  })}
                </Stack>

                <Button component={Link} to={`/project/create?category=${category?.id}`} startIcon={<IconPlus/>}>
                  Create Assistant
                </Button>
              </Stack>
            )
          })
        }
        <CategoryCreate fetchCategories={fetchCategories} organization={searchParams?.organization}
                        organizations={organizations}/>
      </Stack>
    </>
  )
}

const CategoryCreate = ({fetchCategories, organization, organizations}) => {
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [openOrganizaionModal, setOpenOrganizaionModal] = useState(false);

  useEffect(() => {
    setSelectedOrganization(organization || null);
  }, [organization])

  const onCreate = (org) => {
    if (name) {
      if (!org) {
        setOpenOrganizaionModal(true);
        return;
      }
      CategoryRepository.create({name, organization_id: org})
        .then(() => {
          fetchCategories();
          setShowForm(false);
          setName("");
        })
        .catch(error => toast.error(error?.message || "Failed to create category"))
    }
  }

  return (
    <>
      <SelectOrganizationModal
        open={openOrganizaionModal}
        onSelect={(org) => {
          onCreate(org?.id || org);
          setOpenOrganizaionModal(false);
        }}
        handleClose={() => setOpenOrganizaionModal(false)}
        organizations={organizations}/>
      <Stack direction={"column"} gap={1}
             sx={{overflowY: "auto", p: 1, minWidth: {xs: "100vw", sm: "50vw", md: "25vw", lg: "20vw !important"}}}>
        {
          showForm ?
            <Stack direction={"row"} gap={1} alignItems={"center"} fullWidth>
              <Input disableUnderline placeholder={"Enter category name"}
                     value={name} onChange={e => setName(e.target.value)}
                     sx={{flex: 1, fontSize: 20, fontWeight: 600}}/>
              <IconButton
                color={"error"}
                onClick={() => {
                  setShowForm(false);
                  setName("");
                }}
              >
                <IconX/>
              </IconButton>
              <IconButton
                onClick={() => onCreate(selectedOrganization)} color={"success"} disabled={!name}
              >
                <IconChecks/>
              </IconButton>
            </Stack> :
            <Button startIcon={<IconPlus/>} onClick={() => setShowForm(true)}>
              Create New
            </Button>
        }
        <Divider/>
      </Stack>
    </>

  )
}

const ProjectItem = ({project, organization, language, ...props}) => {
  return (
    <Stack
      {...props}
      direction={"column"} gap={0.5}
      className={"border-shadow"}
      sx={{
        p: 2,
        // flex: 1,
        bgcolor: "#fefeff",
        borderRadius: 4,
        // "&:hover": {borderColor: "light.main", boxShadow: 2}
      }}
    >

      <Stack direction={"row"} gap={1} justifyContent={"space-between"} sx={{pb: 0.5}}
             alignItems={"flex-start"}>
        <Typography fontSize={13} fontWeight={600}
                    color={grey[700]}>{moment(project?.createdAt).format("DD MMM YYYY")}</Typography>
        <IconButton
          size={"small"}
          component={Link}
          to={`/project/${project?.id}/settings`}
        >
          <IconPencil size={14}/>
        </IconButton>
      </Stack>

      <Stack direction={"row"} gap={1} justifyContent={"space-between"} sx={{pb: 0.5}}
             alignItems={"flex-start"}>
        {
          project?.settings?.img ?
            <img
              src={project?.settings?.img}
              alt={"Project"}
              width={70} height={70}
              style={{objectFit: 'scale-down'}}
            />
            :
            <Avatar
              src={project?.settings?.img || null}
              sx={{
                bgcolor: "transparent",
                color: "#153B50",
                border: 1,
                borderColor: "#153B50",
                height: {xs: 50, md: 70},
                width: {xs: 50, md: 70},
                fontSize: {xs: 25, md: 40},
                borderRadius: 3,
                mb: "auto"
              }}
            ></Avatar>
        }

        <Stack direction={"column"} sx={{flex: 1}}>
          <Typography fontSize={16} fontWeight={700} color={"#153B50"}>{project?.name}</Typography>
          <Typography fontSize={14} fontWeight={500} color={"#153B50"}>{project?.description}</Typography>
        </Stack>

      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box>
          <Typography fontSize={13} fontWeight={700} color={grey[900]}
                      sx={{pb: 0.5}}>Language</Typography>
          <Typography fontSize={13} fontWeight={500} color={grey[900]}
                      sx={{pb: 0.5}}>{language?.language}</Typography>
        </Box>
        <Box>
          <Typography fontSize={13} fontWeight={700} color={grey[900]}
                      sx={{pb: 0.5}}>Organization</Typography>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {organization?.img && <img src={organization?.img} alt={"organization"} width={20} height={20}/>}
            <Typography fontSize={13} fontWeight={500} color={grey[900]}
                        sx={{pb: 0.5}}>{organization?.name || "/"}</Typography>
          </Stack>
        </Box>

        <Box>
          <Button variant={"contained"} startIcon={<IconAppWindow/>}
                  component={Link} to={`/project/${project?.id}`}
                  sx={{borderRadius: "16px !important"}}>
            Open
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}