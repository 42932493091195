import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useConfirm from "../../../hooks/useConfirm";
import {Avatar, Box, Button, Divider, Grid, Stack, Typography} from "@mui/material";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../../config/firebase";
import {PROJECT_VERSION_COLLECTION, PROJECTS_COLLECTION} from "../../../db/collections";
import {toast} from "react-toastify";
import {
  IconCamera,
} from "@tabler/icons-react";

import {Context as AppContext} from "../../../context/AppContext";
import {ProjectCreateForm} from "../../project-creation/components/ProjectCreateForm";
import {ProjectUpdateForm} from "./ProjectUpdateForm";
import axios from "../../../config/axios";
import {OrganizationRepository} from "../../../repositories/OrganizationRepository";
import {SessionUMRepository} from "../../../repositories/SessionUMRepository";
import {SignInContext, TOKEN} from "../../../context/SignInContext";
import {ProjectEntity} from "../../../model/ModelData";
import {v4 as uuid} from "uuid";
import {uploadImage} from "../../../utils/uploadImage";


export const ProjectSettings = ({project}) => {
  const {updateProject} = useContext(AppContext);
  const {currentUser} = useContext(SignInContext);
  const [newProject, setNewProject] = useState({});
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [asriProjects, setAsriProjects] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this project?',
  );

  useEffect(() => {
    if (project) {
      setNewProject({
        id: project?.id,
        name: project?.name,
        description: project?.description,
        organization: project?.settings?.organization,
        language: project?.settings?.language,
        organization_type: project?.settings?.organization_type,
        speechToText: project?.settings?.speechToText,
        host: project?.settings?.host,
        port: project?.settings?.port,
        protocol: project?.settings?.protocol,
        team: project?.team || [],
      });
      setImage(project?.settings?.img || null);
    }
  }, [project]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/language-support/all');
      setLanguages(response.data);
      const organizationResponse = await OrganizationRepository.getUserOrganizations(currentUser?.username);
      setOrganizations(organizationResponse?.data?.organizations || []);
      SessionUMRepository.getUserSessions()
        .then(response => {
          setAsriProjects(response?.data?.filter(item => item?.module?.moduleType === "SPEECH_TO_TEXT") || []);
        })
        .catch(err => console.log(err));
      const userResponse = await axios.get('/api/user/all', null, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`
        }
      });
      const users = userResponse.data?.map(u => u.username);
      setUsers(users);
    } catch (err) {
      toast.error(err.message);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const org = organizations?.find(org => org?.id === newProject?.organization);
      const imgURL = await createImage();

      let formData = {...project}
      formData.name = newProject?.name;
      formData.description = newProject?.description;
      formData.team = newProject?.team || [];
      formData.settings.organization = newProject?.organization;
      formData.settings.language = newProject?.language;
      formData.settings.organization_type = org?.organization_type || "";
      formData.settings.speechToText = newProject?.speechToText || null;
      formData.settings.host = newProject?.host || null;
      formData.settings.port = newProject?.port || null;
      formData.settings.protocol = newProject?.protocol || null;
      formData.settings.img = imgURL;

      await updateProject(formData);
      toast.success("Project settings updated");
    } catch (e) {
      toast.error(e?.message || "Failed to create project")
    } finally {
      setLoading(false);
    }
  }

  const createImage = async () => {
    if (!image || image === project?.settings?.img) {
      return  image;
    }
    const id = uuid();
    const url =`projects/${id}-${image?.name}`;

    return await uploadImage({img: image, url: url});
  }

  const onDelete = async () => {
    const response = await confirmDelete();
    if (response && newProject?.id) {
      try {
        const batch = writeBatch(db);
        // Delete versions
        if (newProject?.versions?.length) {
          for (const version of newProject?.versions) {
            // Delete version
            const versionRef = doc(db, PROJECT_VERSION_COLLECTION, version?.id);
            batch.delete(versionRef);
          }
        }

        // Delete project
        const projectRef = doc(db, PROJECTS_COLLECTION, newProject?.id || newProject?.uid);
        batch.delete(projectRef);

        await batch.commit();
        // await getUserProjects();

        navigate("/")
        // onClose();
      } catch (err) {
        toast.error(err);
      }

      return;
    }
  }


  return (
    <>
      <Dialog/>
      <Grid container maxWidth={"lg"} sx={{mx: 'auto', py: 3}}>
        <Grid item xs={12} sx={{p: 2}}>
          <Typography variant={"h4"} fontWeight={600}>Update Project</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={4} sx={{p: 2}}>
          <Stack direction={"column"} sx={{borderRadius: 4}} alignItems={"center"} className={"border-shadow"}>
            <Box sx={{p: 2}}>
              <Avatar
                src={image !== null ? typeof image === "string" ? image : URL.createObjectURL(image) : null}
                sx={{
                  border: 1,
                  borderColor: "#153B50",
                  bgcolor: "transparent",
                  height: 120,
                  width: 120,
                  fontSize: 50,
                  borderRadius: 3,
                  mb: "auto", color: "#153B50"
                }}
              >
                {image ? null : <IconCamera size={50}/>}
              </Avatar>
            </Box>
            <Divider sx={{width: "100%"}}/>
            <Button
              component="label"
              fullWidth
              color={"secondary"}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={(event) => setImage(event.target.files[0])}
                accept="image/*"
              />
            </Button>
          </Stack>
        </Grid>
        <Grid component={"form"} onSubmit={handleSubmit} item xs={12} md={7} lg={8} sx={{p: 2}}>
          <Stack direction={"column"} gap={1} sx={{borderRadius: 4}} className={"border-shadow"}>
            <ProjectUpdateForm
              project={newProject} setProject={setNewProject}
              languages={languages} organizations={organizations}
              fetchData={fetchData} loading={loading}
              asriProjects={asriProjects} users={users}
              onDelete={onDelete}
            />
          </Stack>
        </Grid>

      </Grid>

    </>
  )
}