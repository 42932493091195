import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';

import {Box, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import {
    IconAlphabetLatin,
    IconCapture,
    IconSquare,
} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";


function BlockNode({id, data}) {
    const {steps, name} = data;

    return (
        <Box sx={{width: "100%", bgcolor: "#f9f9f9", border: 1, borderColor: "white", boxShadow: 1}}>
            <Handle type="target" position={Position.Left} id={id} />
            <div className="custom-node__header" style={{background: grey[200]}}>
                <Stack direction={"row"} gap={1}>
                    <IconSquare size={13} />
                    <Typography sx={{fontSize: 9, fontWeight: "bold"}}>{name}</Typography>
                </Stack>
            </div>
            <div className="custom-node__body" style={{background: "#FFF"}}>
                <List sx={{p: 0, m: 0}}>
                    {steps.map((step, index) => {
                        return {
                            "text": <ListItem
                                key={step?.id}
                                sx={{borderBottom: 1, borderColor: "divider", pb: 1, px: 1, m: 0, textAlign: "center"}}
                                className="custom-node__select"
                            >
                                <ListItemText sx={{p: 0}}>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} sx={{width: '100%'}}>
                                        <IconAlphabetLatin size={12}/>
                                        <Typography  sx={{flex: 1, fontSize: 10}} align={"left"}>
                                            {step?.text}
                                        </Typography>
                                    </Stack>
                                    {
                                        index === steps.length - 1 &&
                                        <Handle type="source" position={Position.Right} id={step?.id}/>
                                    }
                                </ListItemText>
                            </ListItem>,
                            "choice": <ListItem
                                key={step?.id}
                                sx={{bgcolor: grey[100], borderBottom: 1, borderColor: "divider", pb: 1, px: 1, m: 0, textAlign: "center"}}
                                className="custom-node__select"
                            >
                                <ListItemText sx={{p: 0}}>
                                    <Stack direction={"row"} gap={1} alignItems={"center"}
                                           sx={{width: '100%', height: '100%'}}>
                                        <IconCapture size={12}/>
                                        <Typography noWrap sx={{flex: 1, fontSize: 10}} align={"left"} fontWeight={"bold"}>
                                            {step?.text}
                                        </Typography>
                                    </Stack>
                                </ListItemText>
                            </ListItem>,
                        }[step.type]
                    })}
                </List>
            </div>
        </Box>
    )
}

export default memo(BlockNode);