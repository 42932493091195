import axios from "../config/axios-kb"


export const QueryValidatorRepository = {
  create: (data) => {
    return axios.post(`/api/query_validators`, data);
  },
  edit: (id, data) => {
    return axios.put(`/api/query_validators/${id}`, data)
  },
  remove: (id) => {
    return axios.delete(`/api/query_validators/${id}`)
  },
  get: (id) => {
    return axios.get(`/api/query_validators/${id}`);
  },
  filter: ({organization, topic, slot}) => {
    return axios.get(`/api/query_validators/filter`, {
      params: {
        organization, topic, slot
      }
    });
  }
}