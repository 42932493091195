import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  InputLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import {toast} from "react-toastify";
import {SlotRepository} from "../../../../repositories/SlotRepository";
import React from "react";

export const CreateSlotModal = ({open, handleClose, organization, topics, selectedSlot, onDelete, onSuccess}) => {

  const handleDelete = () => {
    if (selectedSlot) {
      SlotRepository.delete(selectedSlot.id)
        .then(() => {
          onDelete(selectedSlot.id);
          handleClose();
        })
        .catch(err => toast.error(err.message));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData).entries());
          formJson.org_id = organization?.id;
          if (!formJson.name || !formJson?.topic_id) {
            toast.error("Name and Topic are required field")
            return;
          }
          if (selectedSlot && selectedSlot?.id) {
            SlotRepository.edit(selectedSlot?.id, formJson)
              .then(response => {
                onSuccess(response.data.slot);
              })
              .catch(err => {
                toast.error("Failed to create slot");
              })
              .finally(() => {
                handleClose();
              });
          } else {
            SlotRepository.create(formJson)
              .then(response => {
                onSuccess(response.data.slot);
              })
              .catch(err => {
                toast.error("Failed to edit slot");
              })
              .finally(() => {
                handleClose();
              });
          }
        },
      }}
    >
      <DialogTitle>{selectedSlot ? "Edit" : "Create"} Slot</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12}>
          In natural language understanding (NLU), particularly in the context of dialogue systems or intent recognition, a "slot" refers to a placeholder for a specific type of information within a sentence or utterance.
          Slots are often associated with entities or variables that need to be extracted from user input to fulfill a particular task or intent.
        </DialogContentText>
        <DialogContentText fontSize={12}>
          For example, in a restaurant reservation system, slots might include "date", "time", "party size", "restaurant location", etc. The system needs to extract values for these slots from the user's input to complete the reservation.
        </DialogContentText>
        <TextField
          autoFocus
          required
          defaultValue={selectedSlot?.name || ""}
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          variant="standard"
        />
        <FormControl
          variant="standard"
          margin="dense"
          sx={{mt: 2}}
          size={"small"} fullWidth>
          <InputLabel id={`api-method-label`}
                      sx={{textTransform: "capitalize"}}>Topic</InputLabel>
          <Select
            labelId={`api-method-label`}
            id={`api-method-select`}
            name={"topic_id"}
            defaultValue={selectedSlot?.topic_id || ""}
            label={`Topic`}
          >
            <MenuItem value={""}><em>Select slot</em></MenuItem>
            {topics?.map(topic => (<MenuItem key={topic?.id} value={topic?.id}>{topic?.name}</MenuItem>))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {selectedSlot && <Button color={"error"} onClick={handleDelete} sx={{mr: "auto"}}>Delete</Button>}
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">{selectedSlot ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}