import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  Box,
  Button, CircularProgress,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {KnowledgeRepository} from "../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {ResourceTypeIcon} from "../KnowledgeBase";
import {IconChevronLeft, IconPencil, IconTrash} from "@tabler/icons-react";
import {CreateResource} from "../components/CreateResource";
import {MetadataTable} from "./components/MetadataTable";
import {Chunks} from "./components/Chunks"

export const KnowledgeDocument = ({organization, topics, slots, project}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [knowledge, setKnowledge] = useState(null);
  const [docs, setDocs] = useState([]);
  const navigate = useNavigate();

  const {knowledgeId} = useParams();

  useEffect(() => {
    if (knowledgeId) {
      fetchData()
    }
  }, [knowledgeId])

  const fetchData = () => {
    KnowledgeRepository.getById(knowledgeId)
      .then(response => {
        setKnowledge({...response.data.knowledge});
        setDocs([...response.data.docs]);
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const handleDelete = () => {
    if (knowledge && knowledge?.id) {
      KnowledgeRepository.delete(knowledge?.id)
        .then(() => {
          toast.success("Deleted successfully");
          navigate(-1)
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.response?.data?.message || error?.message || "Failed to delete");
        })
    }
  }

  const handlePublish = (knowledge) => {
    if (knowledge) {
      setLoading(true)
      if (knowledge.published) {
        KnowledgeRepository.unpublish(knowledge.id)
          .then((response) => {
            const data = response.data;
            setKnowledge(data);
          })
          .catch(err => toast.error(err.message || "Failed to unpublish"))
          .finally(() => setLoading(false))
      } else {
        KnowledgeRepository.publish(knowledge.id)
          .then((response) => {
            const data = response.data;
            setKnowledge(data);
          })
          .catch(err => toast.error(err.message || "Failed to unpublish"))
          .finally(() => setLoading(false))
      }
    }
  }

  const updateChunks = (newChunk) => {
    const uid = newChunk?.id;
    setDocs(prev => {
      return prev?.map(doc => {
        const docUid = doc?.metadata?._additional?.id;
        return docUid === uid ? {
          ...doc,
          content: newChunk?.content,
          metadata: {
            ...doc.metadata,
            keywords: newChunk?.keywords
          }
        }: doc;
      })
    })
  }

  const content = {
    "url": <a href={knowledge?.url} target={"_blank"} style={{color: "#252525"}}>{knowledge?.url}</a>,
    "pdf": knowledge?.document,
    "onlinepdf": <a href={knowledge?.url} target={"_blank"} style={{color: "#252525"}}>{knowledge?.url}</a>,
    "text": knowledge?.data
  }[knowledge?.resource_type]

  return (
    <Stack direction={"column"} gap={1} sx={{flex: 1, p: 2, overflowY: "auto"}}>
      <CreateResource
        open={open}
        handleClose={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          fetchData();
        }}
        selectedResource={knowledge}
        topics={topics} slots={slots}
        organization={organization}
      />
      <Stack direction={"row"} gap={1}>
        <Button startIcon={<IconChevronLeft size={16}/>} onClick={() => navigate(`/project/${project.id}/knowledge-base/`)}>
          Back
        </Button>
      </Stack>
      <Table sx={{borderCollapse: "collapse", overflow: "none"}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Type</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Id</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Content</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Topic</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Slot</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Publish</TableCell>
            <TableCell sx={{fontSize: 12, fontWeight: 700}}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{fontSize: 12}}>
              {ResourceTypeIcon[knowledge?.resource_type] || knowledge?.resource_type}
            </TableCell>
            <TableCell sx={{fontSize: 12}}>{knowledge?.id}</TableCell>
            <TableCell title={content} sx={{
              fontSize: 12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: '400px',
              textOverflow: "ellipsis"
            }}>{content}</TableCell>
            <TableCell sx={{fontSize: 12}}>{knowledge?.topic_name}</TableCell>
            <TableCell sx={{fontSize: 12}}>{knowledge?.slot_name}</TableCell>
            <TableCell sx={{fontSize: 12}}>
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <Switch size={"small"} disabled={loading} checked={!!knowledge?.published}
                        onChange={() => handlePublish(knowledge)}/>
                {loading && <CircularProgress size={14}/>}
              </Stack>
            </TableCell>
            <TableCell sx={{fontSize: 12}}>
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <Button
                  disabled={loading}
                  sx={{ml: "auto", color: "primary.dark"}} variant={"text"}
                  startIcon={<IconPencil size={16}/>}
                  onClick={() => setOpen(true)}
                >
                  Edit
                </Button>
                <Button
                  disabled={loading}
                  sx={{color: "primary.dark"}} variant={"text"}
                  startIcon={<IconTrash size={16}/>}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {
        knowledge?.resource_type === "text" &&
        <Box sx={{fontSize: 11}}>
          {content}
        </Box>
      }
      <Typography variant={"h4"} fontWeight={700}>Resources</Typography>
      <Stack direction={"row"} gap={3}>

        <Chunks knowledge={knowledge} chunks={docs} fetchData={fetchData} updateChunks={updateChunks} query={null} />
        <Box>
          <MetadataTable
            knowledge={knowledge}
          />
        </Box>
      </Stack>

    </Stack>
  )
}