import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import {Box, Stack, Typography} from "@mui/material";
import {IconPlayerPlay} from "@tabler/icons-react";
import {green} from "@mui/material/colors";


function StartNode({ id, data }) {
    return (
        <Box className={"start-node"} sx={{width: "60px", bgcolor: green[500], border: 1, p: 1, borderRadius: 0, borderColor: "white", boxShadow: 1}}>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{color: "#FFF"}}>
                <IconPlayerPlay size={13} />
                <Typography sx={{fontSize: 9, fontWeight: "bold" }}>Start</Typography>
            </Stack>
            <Handle type="source" position={Position.Right} id={id} />
        </Box>
    );
}

export default memo(StartNode);
