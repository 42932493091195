import createContext from "./createContext";
import {doc, setDoc, updateDoc} from "firebase/firestore";
import {
  projectsCollection,
  PROJECTS_COLLECTION,
  PROJECT_VERSION_COLLECTION
} from "../db/collections";
import {db} from "../config/firebase";
import {toast} from "react-toastify";
import {v4 as uuidv4} from 'uuid';
import {FlowEntity, NodeEntity, NodeType, UserDetailsEntity} from "../model/ModelData";
import axios from "axios";

const appInitialState = {
  userDetails: {...UserDetailsEntity},
  projects: [],
}

const appReducer = (state, action) => {
  switch (action.type) {
    case 'update_flow':
      return {...state, flow: action.payload}
    case 'update_flows':
      return {...state, flows: action.payload}
    case 'update_projects':
      return {...state, projects: action.payload}
    default:
      return state;
  }
}

// these functions will act as repositories, here we will make calls to the database and be able to reuse them in different components
const createProject = (dispatch, authContext) => {
  return async (data) => {
    try {
      const uuid = uuidv4();
      if (!data?.id) {
        data.id = uuid;
      }
      data.userUid = authContext.currentUser.username;
      data.team = [authContext.currentUser.username];
      //
      const flow = {...FlowEntity, id: uuidv4()};
      const textNode = {
        ...NodeEntity,
        id: uuidv4(),
        name: "Welcome",
        type: NodeType.TEXT,
        coords: {x: 150, y: 0},
        data: {
          greet: ["Како може да ви помогнам"]
        }
      }
      const startNode = {
        ...NodeEntity,
        id: uuidv4(),
        name: "Start",
        type: NodeType.START,
        data: {
          greet: ["Добредојде во контакт центарот на А1", "Добредојде"]
        },
        routes: {
          static: {
            next: {
              id: uuidv4(),
              type: "next",
              target: textNode.id,
              data: {}
            },
          }
        }
      }
      flow.nodes = [startNode, textNode];
      data.flows = [flow];
      data.entryNode = startNode?.id;

      console.log(data);
      await setDoc(doc(db, PROJECTS_COLLECTION, data?.id), data);
      await getUserProjects(dispatch, authContext)();
      toast.success("Successfully created project.")
    } catch (err) {
      toast.error(err);
    }
  }
}

const publishVersion = (dispatch, authContext) => {
  return async (project) => {
    try {
      if (!project) {
        throw Error("Project not found");
      }
      const newProject = {...project};
      const newVersion = {...newProject};
      newVersion.id = uuidv4();
      newVersion.projectId = newProject.id;
      newVersion.createdAt = new Date().getTime();
      newVersion.createdBy = authContext.currentUser.username;
      if (newVersion.versions) {
        delete newVersion.versions;
      }
      await setDoc(doc(db, PROJECT_VERSION_COLLECTION, newVersion?.id), newVersion);

      if (!newProject.versions) {
        newProject.versions = [];
      }
      newProject.versions.push(
        {
          id: newVersion.id,
          createdAt: newVersion.createdAt,
          version: `V${(newProject.versions.length + 1).toString().padStart(2, '0')}`
        }
      );
      await setDoc(doc(db, PROJECTS_COLLECTION, newProject?.id), newProject);

      const response = await axios.get(`https://intents.vb.openbrain.io/publish?project_id=${newProject.id}&export_url=${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/apiProject/${newProject.id}`)
      if (response.status !== 200) {
        toast.error("Error! Please try again.");
        throw Error("Publish version failed!");
      }

      await getUserProjects(dispatch, authContext)();
      toast.success("Successfully published new version.")
    } catch (err) {
      toast.error(err);
    }
  }
}

const updateProject = (dispatch, authContext) => {
  return async (data) => {
    try {
      const uuid = uuidv4();
      if (!data?.id) {
        data.id = uuid;
      }
      if (!data.userUid) {
        data.userUid = authContext.currentUser.username;
      }
      await updateDoc(doc(db, PROJECTS_COLLECTION, data?.id), data);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }
}

const getUserProjects = (dispatch, authContext) => {
  return async () => {
    try {
      if (authContext?.currentUser && authContext?.isAuth) {
        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/getProjects`
        const organizations = authContext?.organizations?.map(org => org?.id) || [];
        const response = await axios.post(url, {
          user: authContext.currentUser.username,
          organizations: organizations,
          roles: authContext.currentUser.roles
        })
        const items = response?.data?.projects?.sort((p1, p2) => (p1.createdAt < p2.createdAt) ? 1 : (p1.createdAt > p2.createdAt) ? -1 : 0) || [];
        console.log(items);
        // console.log(items);
        dispatch({type: "update_projects", payload: items});
      }
    } catch
      (err) {
      toast.error(err.message);
    }
  }
}

export const {Provider, Context} = createContext(
  appReducer,
  {
    getUserProjects,
    updateProject,
    createProject,
    publishVersion
  },
  {...appInitialState}
)