import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import React, {useEffect, useState} from "react";

export const UpdateChunk = ({open, handleClose, onSuccess, selectedContent}) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(selectedContent || "");
  }, [selectedContent])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {minWidth: "300px", maxWidth: "600px", width: "100%"}
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {selectedContent ? "Edit content" : "Create content"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            sx={{mt: 1, minWidth: "300px"}}
            fullWidth
            label={"Content"}
            multiline
            minRows={3}
            maxRows={10}
            autoFocus
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{color: lightBlue[700]}}>CLOSE</Button>
        <Button onClick={() => onSuccess(content)} disabled={!content} sx={{color: lightBlue[700]}}>
          AGREE
        </Button>
      </DialogActions>
    </Dialog>
  )
}