import React, {useContext, useEffect, useState} from "react";
import {Box, Stack} from "@mui/material";
import {Context as AppContext} from "../../../context/AppContext";
import ReactFlow, {MiniMap, useEdgesState, useNodesState, useReactFlow} from 'reactflow';

import 'reactflow/dist/style.css';
import BlockNode from "../../../nodes/BlockNode";
import {v4 as uuidv4} from 'uuid';
import "../../../assets/css/overview.css"
import StartNode from "../../../nodes/StartNode";
import IntentNode from "../../../nodes/IntentNode";
import {NodeMenu} from "./NodeMenu"
import {
  getRoutes, mapNode, mapStartFormToNode,
} from "../../../utils/nodeMapper";
import {toast} from "react-toastify";
import {SideMenu} from "./side-menu/SideMenu";
import {NodeEntity, NodeType, ScopeType, TargetType} from "../../../model/ModelData";
import CustomNode from "../../../nodes/CustomNode";
import EndNode from "../../../nodes/EndNode";
import FormNode from "../../../nodes/FormNode";
import FlowNode from "../../../nodes/FlowNode";
import {EditNodeMenuWrapper} from "./edit-menu/EditNodeMenuWrapper";
import {useLLMNodes} from "./edit-menu/settings/LLMSettings";
import useConfirm from "../../../hooks/useConfirm";
import CustomEdgeStartEnd from "../../../nodes/edges/CustomEdgeStartEnd";
import {useSearchParams} from "react-router-dom";


const nodeTypes = {
  block: BlockNode,
  start: StartNode,
  intent: IntentNode,
  custom: CustomNode,
  end: EndNode,
  flow: FlowNode,
  form: FormNode
};

const edgeTypes = {
  'start-end': CustomEdgeStartEnd,
};

const minimapStyle = {
  height: 120,
};

export const Project = ({project, openChat}) => {
  const {updateProject} = useContext(AppContext)
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [useLLM, setUseLLM] = useState(false);
  const {setCenter} = useReactFlow();
  const [EdgeDialog, confirmEdgeDelete] = useConfirm(
    'Are you sure you want to delete this route?',
  );
  const [flowNodes, setFlowNodes] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (flowNodes && flowNodes?.length > 0) {
      const nodeItems = [];
      let edgeItems = [];
      for (const flowNode of flowNodes) {
        let node = null;
        if (flowNode?.data.form_id) {
          if (flowNode.type === NodeType.START_FORM) {
            const {newNode, edgeItems: newEdges} = mapStartFormToNode({node: flowNode, nodes: flowNodes});
            node = newNode;
            edgeItems = [...edgeItems, ...newEdges];
          }
        } else {
          const {newNode, edgeItems: newEdges} = mapNode({node: flowNode, nodes: flowNodes});
          node = newNode;
          edgeItems = [...edgeItems, ...newEdges];
        }

        if (node) {
          nodeItems.push(node)
        }
      }
      setNodes(nodeItems)
      setEdges(edgeItems)
    }
  }, [flowNodes])

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = async (params) => {
    if (openChat) {
      return;
    }
    try {
      const sourceNode = flowNodes?.find(n => n?.id === params.source);
      const routes = getRoutes(sourceNode?.routes);
      let route = routes?.find(r => r.id === params.sourceHandle);
      const target = flowNodes.find(n => n.id === params.target);
      if (sourceNode && route && target) {
        if (route?.target === undefined) {
          route = {
            id: uuidv4(),
            target: null,
            name: null,
            type: params?.sourceHandle,
            backstack: false,
            data: {
              greet: "",
              params: []
            }
          }
        }
        route.target = target.id;
        route.name = target.name;
        for (const staticKey of Object.keys(sourceNode.routes.static)) {
          if (staticKey === route.type) {
            sourceNode.routes.static[staticKey] = route;
          }
        }
        for (const dynamic in sourceNode.routes.dynamic) {
          const dynamicRoute = sourceNode?.routes?.dynamic[dynamic];

          if (dynamicRoute && dynamicRoute?.id === route?.id) {
            sourceNode.routes.dynamic[dynamic] = route;
          }
        }
        await onUpdateNode(sourceNode);
      }
    } catch (error) {
      toast.error("Failed to connect")
    }
  };

  useEffect(() => {
    if (project && project?.flows?.length) {
      let newFlow = project?.flows[0];
      if (selectedFlow) {
        newFlow = project.flows.find(i => i.id === selectedFlow.id);
      }
      setFlowNodes(newFlow?.nodes);
      setSelectedFlow(newFlow);
    }
  }, [project])

  useEffect(() => {
    if (selectedNode && openChat) {
      const node = nodes?.find(i => i.id === selectedNode?.id);
      if (node) {
        const center = {x: selectedNode?.coords?.x || 0, y: selectedNode?.coords?.y || 0, zoom: 1};
        setCenter(center.x + 100, center.y, {zoom: 0.9, duration: 700});
        setNodes(prev => {
          return prev.map(n => {
            if ((n.id === selectedNode.id)
              || (selectedNode?.data?.form_id
                && n.data?.node?.data?.form_id
                && n.data?.node?.data?.form_id === selectedNode
                && n.data?.node?.data?.type === NodeType.START_FORM
              )) {
              return {...n, selected: true};
            }
            return {...n, selected: false};
          })
        });
      }
    }
  }, [selectedNode])

  const addNode = async (type) => {
    if (type === NodeType.START_FORM) {
      await addFormNode();
      return;
    }
    const newNode = {
      ...NodeEntity,
      id: uuidv4(),
      name: `${type?.toLowerCase()?.replaceAll("_", " ")} [${nodes?.length}]`,
      type: type, // NodeType
      coords: {x: 150, y: 150},
      parameters: [],
      data: {
        greet: [],
        max_ttl: project?.settings?.max_ttl || 4
      },
      routes: {
        static: {
          next: null,
          no_match: null,
          fail: null,
        },
        dynamic: []
      }
    };
    if (type === NodeType.QA || type === NodeType.RECOMMENDATION) {
      newNode.data.qa_method = project?.settings?.qa_method || "";
      newNode.data.qa_api = project?.settings?.qa_api || "";
      newNode.data.please_wait_messages = [];
      newNode.data.persona = "";
      newNode.data.slots = [];
      newNode.data.paraphrazer = true;
      newNode.data.lemmatizer = true;
      newNode.data.conversation_buffer_window = 4;
    } else if (type === NodeType.HOOK) {
      newNode.data.api_url = "";
      newNode.data.api_method = "GET";
      newNode.data.target = TargetType.FLOW;
      newNode.data.params = [{id: uuidv4(), source: null, scope: ScopeType.FLOW}];
    } else if (type === NodeType.TEACHING) {
      newNode.data.topic_id = null;
      newNode.data.slot_id = null;
      newNode.data.use_llm = true;
      newNode.data.teach_kb_extractor_hook = "";
      newNode.data.qa_method = "GET";
      newNode.data.kb_add_hook = "";
      newNode.data.extractor_parser = {};
    }
    if (type === NodeType.RECOMMENDATION || type === NodeType.TEACHING || type === NodeType.JAVASCRIPT) {
      newNode.data.parser = `
function parser(result) {
  // Write your code here
}
`;
    }
    if (type !== NodeType.QUERY && type !== NodeType.END && type !== NodeType.END_FLOW) {
      newNode.routes.static.next = {
        id: uuidv4(),
        target: " ",
        name: null,
        type: "next",
        backstack: false,
        data: {
          greet: "",
          params: []
        }
      }
    }
    await onUpdateNodes([...flowNodes, newNode]);
    setCenter(newNode.coords.x, newNode.coords.y, {zoom: 0.9, duration: 700});
  }

  const addFormNode = async (type) => {
    const form_id = uuidv4();

    const endNode = {
      ...NodeEntity,
      id: uuidv4(),
      name: "End node",
      type: NodeType.END_FORM, // NodeType
      coords: {x: 0, y: 0},
      parameters: [],
      data: {
        greet: [],
        form_id: form_id,
      },
      routes: {
        static: {
          next: null,
          no_match: null,
          fail: null,
        },
        dynamic: []
      }
    };
    const startNode = {
      ...NodeEntity,
      id: uuidv4(),
      name: "Start node",
      type: NodeType.START_FORM, // NodeType
      coords: {x: 0, y: 0},
      parameters: [],
      data: {
        greet: [],
        form_id: form_id,
      },
      routes: {
        static: {
          next: {
            id: uuidv4(),
            target: endNode.id,
            name: null,
            type: "next",
            backstack: false,
            data: {
              greet: ""
            }
          },
          no_match: null,
          fail: null,
        },
        dynamic: []
      }
    };

    await onUpdateNodes([...flowNodes, startNode, endNode])
  }

  const removeNode = async (id) => {
    const node = flowNodes?.find(i => i?.id === id);
    let newNodes = flowNodes?.filter(i => i?.id !== id) || [];
    newNodes = newNodes.map(i => {
      for (const key of Object.keys(i.routes.static)) {
        if (i.routes.static[key]?.target === id) {
          i.routes.static[key].target = null;
        }
        if (i.routes.dynamic && i.routes.dynamic.length > 0) {
          i.routes.dynamic = i.routes.dynamic.filter(route => route.target !== id);
        }
        return i;
      }
    })
    if (node?.data?.form_id && node?.type === NodeType?.START_FORM) {
      newNodes = newNodes?.filter(i => i?.data?.form_id !== node?.data?.form_id)
    }
    await onUpdateNodes(newNodes)
  }

  const removeFormNode = async (form_id) => {
    const startNode = flowNodes?.find(i => i?.data?.form_id === form_id && i?.type === NodeType.START_FORM);
    const newNodes = flowNodes?.filter(i => i?.data?.form_id !== form_id)
      ?.map(i => {
        for (const key of Object.keys(i.routes.static)) {
          if (i.routes.static[key]?.target === startNode?.id) {
            i.routes.static[key].target = null;
          }
        }
        if (i.routes.dynamic && i.routes.dynamic.length > 0) {
          i.routes.dynamic = i.routes.dynamic.filter(route => route.target !== startNode?.id);
        }
        return i;
      });

    await onUpdateNodes(newNodes);
  }

  const onDeleteEdge = async (_, edge) => {
    const {source, sourceHandle} = edge;
    const sourceNode = flowNodes?.find(n => n?.id === source);
    if (!sourceNode) {
      return;
    }
    const response = await confirmEdgeDelete();
    if (!response) return;

    const node = {...sourceNode}
    for (const key of Object.keys(node?.routes.static)) {
      if (node?.routes?.static[key]?.id === sourceHandle) {
        node.routes.static[key] = null;
        break;
      }
    }
    node.routes.dynamic = node?.routes?.dynamic?.filter(route => route?.id !== sourceHandle);
    const newNodes = flowNodes.map(item => {
      if (item?.id === node?.id) {
        return node;
      }
      return item;
    })
    await onUpdateNodes(newNodes);
  }

  const onUpdateNode = async (node) => {
    const newNodes = flowNodes?.map(n => {
      if (n?.id === node?.id) {
        return node;
      }
      return n;
    });
    await onUpdateNodes(newNodes);
  }

  const onUpdateNodes = async (newNodes) => {
    try {
      const newProject = {...project};
      const newFlow = {...selectedFlow, nodes: newNodes};
      const newFlows = newProject?.flows?.map(flow => {
        if (flow.id === selectedFlow?.id) {
          return newFlow;
        }
        return flow;
      })
      newProject.flows = newFlows;
      await updateProject(newProject);
      // setSelectedFlow(newFlow);
      // setFlowNodes(newNodes);
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <>
      <Stack direction={"row"} sx={{flex: 1, maxHeight: '100%'}}>
        <EdgeDialog />
        {
          !openChat &&
          <SideMenu
            project={project}
            flows={project?.flows}
            selectedFlow={selectedFlow}
            onSelectFlow={(flow) => {
              setSelectedFlow(flow);
              setFlowNodes(flow?.nodes || [])
              setSelectedNode(null);
            }}
          />
        }
        <Box sx={{flow: 1, width: "100%", position: "relative", bgcolor: "transparent"}}>
          <NodeMenu
            openChat={openChat}
            addNode={addNode}
            useLLM={useLLM}
            onToggleLLM={() => setUseLLM(!useLLM)}
            updatePosition={(id) => {
              let nodes = [...flowNodes];

              let node = nodes.find(n => n?.id === id);
              if (!node) {
                for (const flowItem of project?.flows) {
                  const index = flowItem?.nodes?.findIndex(i => i?.id === id);
                  if (isFinite(index) && index >= 0) {
                    setSelectedFlow(flowItem);
                    setFlowNodes(flowItem?.nodes || [])
                    node = flowItem.nodes[index];
                  }
                }
              }
              if (node?.data?.form_id && selectedNode?.data?.form_id === node?.data?.form_id) {
                return;
              } else {
                setSelectedNode(node || null);
              }
            }}
          />
          <ReactFlow
            style={{
              background: openChat ? "#F9F9F9" : "#FAFAFA",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0
            }}
            edgeTypes={edgeTypes}
            className={`${openChat ? "darkness" : ""} ${useLLM ? "magic-cursor" : ""}`}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onEdgeClick={(e, edge) => {
              var node = flowNodes?.find(i => i?.id === edge.source);
              if (node?.data?.flow_id && node?.type === NodeType.END_FORM) {
                node = flowNodes?.find(i => i?.data?.flow_id === node?.data?.flow_id && node?.type === NodeType.START_FORM);
              }
              if (node) {
                setSelectedNode(node);

                setSearchParams({
                  node: edge.source,
                  route: edge.sourceHandle
                })
              }
            }}
            onEdgeDoubleClick={onDeleteEdge}
            defaultViewport={{x: 0, y: 0, zoom: 0.7}}
            // onInit={onInit}
            onPaneClick={() => {
              setSelectedNode(null);
              setSearchParams({})
            }}
            onNodeClick={(event, clickedNode) => {
              const id = clickedNode.id;
              const node = flowNodes.find(n => n?.id === id);
              if (node?.data?.form_id && selectedNode?.data?.form_id === node?.data?.form_id) {
                return;
              }
              if (useLLM) {
                if (useLLMNodes.includes(node?.type)) {
                  onUpdateNode({...node, data: {...node.data, use_llm: !node.data.use_llm}})
                } else {
                  toast.info("This node cant use LLM");
                }
              } else {
                setSelectedNode(node || null);
              }
            }}
            onNodeDragStop={async (event, draggedNode) => {
              const id = draggedNode?.id;
              if (draggedNode.position.x === draggedNode.data.node.coords?.x &&
                draggedNode.position.y === draggedNode.data.node.coords?.y) {
                return;
              }
              const node = flowNodes.find(n => n?.id === id);
              setSelectedNode(node);
              const newNodes = flowNodes.map(item => {
                if (item?.id === id) {
                  return {...item, coords: {x: draggedNode.position.x, y: draggedNode.position.y}}
                }
                return item;
              })
              await onUpdateNodes(newNodes);
            }}
            attributionPosition="top-right"
            nodeTypes={nodeTypes}
            fitView
          >
            {/*<Controls />*/}
            <MiniMap style={minimapStyle} zoomable pannable/>
            {/*<Background variant="lines" gap={12} size={1}/>*/}
          </ReactFlow>
        </Box>
        {
          selectedNode && openChat === false &&
          <EditNodeMenuWrapper
            open={!!selectedNode}
            selectedNode={selectedNode}
            flow={selectedFlow}
            project={project}
            nodes={flowNodes}
            onClose={() => setSelectedNode(null)}
            onUpdateNodes={onUpdateNodes}
            onUpdate={async (node) => {
              if (node !== selectedNode) {
                await onUpdateNode(node);
                setSelectedNode(node);
              }
            }}
            onRemoveForm={removeFormNode}
            onRemove={removeNode}
          />
        }

      </Stack>
    </>
  )
}

