import {KnowledgeRepository} from "../../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {Stack} from "@mui/material";
import {Chunk} from "./Chunk";
import React from "react";

export const Chunks = ({knowledge, fetchData, chunks, updateChunks, query}) => {

  const updateChunk = (newChunk) => {
    const uuid = newChunk?.metadata?._additional?.id;
    const doc_id = knowledge?.id;
    const data = {
      content: newChunk?.content,
      keywords: newChunk?.metadata?.keywords
    }

    KnowledgeRepository.editChunk(doc_id, uuid, data)
      .then((response) => {
        updateChunks(response.data)
      })
      .catch(error => {
        console.log(error);
        toast.error(error?.message || "Failed to update chunk");
      })
  }

  return (
    <Stack direction={"column"} gap={1} sx={{flex: 1}}>
      {chunks?.map((chunk) => {
        return <Chunk
          key={chunk?.metadata?._additional?.id}
          knowledge={knowledge} query={query} chunk={chunk}
          updateChunk={updateChunk} fetchData={fetchData}
        />
      })}
    </Stack>
  )
}
