import {MarkerType} from "reactflow";
import {
  IconAccessPoint,
  IconBrain, IconBrandJavascript,
  IconChalkboard, IconCornerDownRight, IconLogout,
  IconPlayerPlay, IconTerminal,
  IconVariable,
  IconWorld
} from "@tabler/icons-react";
import {NodeType} from "../model/ModelData";
import {IconHook} from "../assets/icons/IconHook";
import {IconQuery} from "../assets/icons/IconQuery";
import {IconSwitch} from "../assets/icons/IconSwitch";
import {IconExit} from "../assets/icons/IconExit";
import {IconText} from "../assets/icons/IconText";
import {IconQA} from "../assets/icons/IconQA";

export const mapNode = ({node, nodes}) => {
  let edgeItems = [];
  let steps = [];
  const routes = getRoutes(node.routes);

  for (const route of routes) {

    if (route?.target) {
      edgeItems.push({
        id: `${node?.id}_${route?.target}_${route?.id}`,
        source: node?.id,
        sourceHandle: route?.id,
        target: route?.target,
        type: 'smoothstep',
        data: {selectIndex: 0},
        markerEnd: {type: MarkerType.Arrow}
      });
    }
    steps.push({
      icon: route?.type?.toLowerCase() === "intent" ? <IconAccessPoint color={"#252525"} size={12}/> :
        <IconCornerDownRight color={"#252525"} size={12}/>,
      text: `${route?.name?.toLowerCase() || ""}`,
      secondary: route?.type?.toLowerCase() === "intent" ? route?.data?.intent_name : route?.type?.toLowerCase(),
      id: route?.id,
      type: route?.type,
    });
  }

  const iconComponent = getIcon(node?.type);

  const newNode = {
    id: node?.id,
    type: getNodeBlockType(node.type),
    text: {},
    position: {x: node?.coords?.x || 0, y: node?.coords?.y || 0},
    data: {
      greet: node?.data?.greet[0] || "",
      icon: iconComponent,
      name: node.name,
      steps: steps,
      node: node
    }
  };

  return {newNode, edgeItems};
};

const getNodeBlockType = (type) => {
  let blockType;
  switch (type) {
    case NodeType.START:
      blockType = "start";
      break;
    case NodeType.END:
      blockType = "end";
      break;
    case NodeType.START_FLOW:
      blockType = "flow";
      break;
    default:
      blockType = "custom";
  }
  return blockType;
}

export const mapStartFormToNode = ({node, nodes}) => {
  let edgeItems = [];
  let steps = [];
  // const routes = getRoutes(node.routes);
  const formNodes = nodes?.filter(n => n?.data?.form_id === node?.data?.form_id);

  mapStartFormSteps(node, formNodes, nodes, edgeItems, steps);

  const newNode = {
    id: node?.id,
    type: 'form',
    text: {},
    position: {x: node?.coords?.x || 0, y: node?.coords?.y || 0},
    data: {
      greet: node?.data?.greet[0] || "",
      icon: <IconBrain color={"#252525"} size={13}/>,
      name: node.name,
      steps: steps,
      node: node,
      isConnected: edgeItems.length > 0
    },
  }
  return {newNode, edgeItems};
}


const mapStartFormSteps = (node, formNodes, nodes, edges = [], steps = []) => {
  let icon = getIcon(node?.type);
  const step = {
    icon: icon,
    text: node?.data.greet[0] || node?.name || "",
    type: node?.type,
    id: node?.id
  };

  steps.push(step);

  const nextNodeId = node?.routes?.static?.next?.target;
  let next = formNodes?.find(i => i?.id === nextNodeId);
  if (next) {
    mapStartFormSteps(next, formNodes, nodes, edges, steps);
  } else {
    next = nodes.find(i => i?.id === nextNodeId);
    const startNode = nodes?.find(i => i?.data?.form_id === node?.data?.form_id && i?.type === NodeType.START_FORM);
    if (next) {
      edges.push({
        id: `${node?.id}_${next?.id}`,
        source: startNode?.id,
        sourceHandle: node?.id,
        target: next?.id,
        type: 'smoothstep',
        data: {
          selectIndex: 0,
        },
        markerEnd: {
          type: MarkerType.Arrow,
        },
      })
    }
  }
  return {steps, edges};
}

export const getRoutes = (routes) => {
  let newRoutes = routes?.dynamic ? routes?.dynamic?.map(route => ({...route, variant: "dynamic"})) : [];
  if (routes?.static) {
    for (const key of Object.keys(routes.static).sort()) {
      const item = routes.static[key] ? {...routes.static[key]} : {};
      item.type = key;
      item.id = item?.id || key;
      item.variant = "static"
      newRoutes.unshift(item);
    }
  }
  return newRoutes;
}

const getIcon = (type) => {
  let iconComponent;
  switch (type) {
    case NodeType.QUERY:
      iconComponent = <IconQuery color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.HOOK:
      iconComponent = <IconHook stroke={1} size={14}/>;
      break;
    case NodeType.SWITCH:
      iconComponent = <IconSwitch color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.SET_VAR:
      iconComponent = <IconVariable color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.TEACHING:
      iconComponent = <IconChalkboard color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.START_FLOW:
      iconComponent = <IconWorld color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.QA:
      iconComponent = <IconQA color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.START_FORM:
      iconComponent = <IconPlayerPlay color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.END:
      iconComponent = <IconExit color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.END_FORM:
      iconComponent = <IconLogout color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.START:
      iconComponent = <IconPlayerPlay color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.JAVASCRIPT:
      iconComponent = <IconBrandJavascript color={"#252525"} stroke={1} size={14}/>;
      break;
    case NodeType.RECOMMENDATION:
      iconComponent = <IconTerminal color={"#252525"} stroke={1} size={14}/>;
      break;
    default:
      iconComponent = <IconText color={"#252525"} stroke={1} size={14}/>;
  }
  return iconComponent;
}
