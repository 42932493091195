import { CircularProgress, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {useContext, useEffect} from "react";
import {toast} from "react-toastify";
import {SignInContext} from "../../../context/SignInContext";

export const OAuthSuccess = () => {
  const {onOAuth} = useContext(SignInContext);
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    if (token) {
      const checkIsAuth = async () => {
        try {
          await onOAuth(token);
        } catch (e) {
          toast.error('Failed to authenticate please provide valid token!')
          navigate("/login");
        }
      }
      checkIsAuth();
    } else {
      toast.error('Token not found please try again!')
      navigate("/login");
    }
  }, [token])

  return (
    <Stack direction={"column"} gap={4} alignItems={"center"} justifyContent={"center"} sx={{width: "100vw", height: "100lvh"}}>
      <CircularProgress size={40} />
      <Typography fontWeight={700} variant={"h3"}>Please wait we are trying to authenticate you!</Typography>
    </Stack>
  )
}