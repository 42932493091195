import {Chip, IconButton, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {CreateKeyword} from "./CreateKeyword";
import {IconPlus} from "@tabler/icons-react";

export const Keywords = ({keywords, updateKeywords}) => {
  const [createKeyword, setCreateKeyword] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const onEditKeyword = async (keyword, index) => {
    setSelectedIndex(index);
    setCreateKeyword(true);
  }

  const onDeleteKeyword = async (keyword, index) => {
    const newKeywords = [...keywords]?.filter((k, i) => k !== keyword && i !== index) || [];
    updateKeywords(newKeywords);
  }

  return (
    <>
      <CreateKeyword
        open={createKeyword}
        selectedKeyword={isFinite(selectedIndex) ? keywords[selectedIndex] || null : null}
        handleClose={() => {
          setSelectedIndex(null);
          setCreateKeyword(false);
        }}
        onSuccess={(newKeyword) => {
          const newKeywords = [...keywords];
          if (selectedIndex !== null && isFinite(selectedIndex)) {
            newKeywords[selectedIndex] = newKeyword;
          } else {
            newKeywords.push(newKeyword);
          }
          setSelectedIndex(null);
          setCreateKeyword(false);
          updateKeywords(newKeywords);
        }}
      />
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography sx={{fontSize: 12, fontWeight: "bold"}}>Keywords</Typography>
        <IconButton
          size={"small"}
          onClick={() => setCreateKeyword(true)}
        >
          <IconPlus size={14} />
        </IconButton>
      </Stack>
      <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
        {keywords?.map((keyword, index) => {
          return <Chip
            size={"small"}
            sx={{fontSize: 11, alignItems:"center", maxWidth: 200}}
            label={keyword} color={"primary"}
            onClick={() => onEditKeyword(keyword, index)}
            onDelete={() => onDeleteKeyword(keyword, index)}
          />
        })}
      </Stack>
    </>

  )
}