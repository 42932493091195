import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_KB_GATEWAY}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Content-Type": "application/json"
  },
});

export default instance;
