import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import {Box} from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({open, title, children}) {

  return (
    open &&
    <Box
      id={"dialog"}
      component={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{position: "absolute", top: 10, left: 10, zIndex: 100000000, minWidth: "300px", maxWidth: "30rem", maxHeight: "80vh", overflowY: "auto"}}
    >
      <DialogTitle style={{cursor: 'move'}} sx={{p: 0.5}} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent sx={{p: 0.5}}>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
    </Box>
  );
}