import React, {useEffect, useState} from "react";
import {
  Fade,
  Stack
} from "@mui/material";
import {Chat} from "../../../components/chat/Chat";
import {v4 as uuidv4} from "uuid"

export const ChatDrawer = ({open, handleClose, project}) => {
  const [json, setJson] = useState(null);

  useEffect(() => {
    if (project) {
      const debugId = uuidv4();
      localStorage.setItem("debug", debugId);
      const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/apiCurrentProject/${project?.id}?debug=${debugId}`
      setJson(url);
    }
  }, [project])

  return (
    <Fade in={open} timeout={500}>
      <Stack direction={"column"} gap={1} sx={{
        display: open ? "flex" : "none",
        minWidth: "400px",
        maxWidth: "460px",
        width: "100% !important",
        bgcolor: "#FFF",
        overflowX: "hidden",
        borderLeft: 1,
        borderColor: "divider"
      }}>
        {json && open && <Chat json={json} project={project}/>}
      </Stack>
    </Fade>
  )
}
