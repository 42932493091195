import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import {Box, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconExit} from "../assets/icons/IconExit";


function EndNode({ id, data }) {

    const {name} = data;

    return (
        <Box className={"end-node"} sx={{width: "auto", border: 1, p: 1, pr: 2, borderRadius: 0, borderColor: "white", boxShadow: 1}}>
            <Handle type="target" position={Position.Left} id={id} />

            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{color: grey[50]}}>
                <IconExit size={13} color={grey[50]} />
                <Typography noWrap sx={{fontSize: 9, fontWeight: "bold" }}>{name || "End"}</Typography>
            </Stack>
        </Box>
    );
}

export default memo(EndNode);
