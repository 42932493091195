import axios from "../config/axios-kb"


export const TopicRepository = {
  getAll: () => {
    return axios.get(`/api/topic`);
  },
  getById: (id) => {
    return axios.get(`/api/topic/${id}`);
  },
  delete: (id) => {
    return axios.delete(`/api/topic/${id}`)
  },
  create: (data) => {
    return axios.post('/api/topic', data)
  },
  edit: (id, data) => {
    return axios.put(`/api/topic/${id}`, data)
  },
  getAllByOrgId(orgId) {
    return axios.get(`/api/topic/organization/${orgId}`);
  }
}