
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// project imports

// assets

import image from '../../assets/images/img-build.svg';
import imageBackground from '../../assets/images/img-bg-grid.svg';
import imageDarkBackground from '../../assets/images/img-bg-grid-dark.svg';
import imageParts from '../../assets/images/img-bg-parts.svg';
import {IconBulldozer} from "@tabler/icons-react";
import React, {useContext} from "react";
import {SignInContext} from "../../context/SignInContext";
import {WebSettingsContext} from "../../context/WebSettingsContext";

// styles
const CardMediaWrapper = styled('div')({
  maxWidth: 720,
  margin: '0 auto',
  position: 'relative'
});

const PageContentWrapper = styled('div')({
  maxWidth: 350,
  margin: '0 auto',
  textAlign: 'center'
});

const ConstructionCard = styled(Card)({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const CardMediaBuild = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '5s bounce ease-in-out infinite'
});

const CardMediaParts = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '10s blink ease-in-out infinite'
});

// ========================|| UNDER CONSTRUCTION PAGE ||======================== //

const UnderMaintenance = () => {
  const {currentUser} = useContext(SignInContext);
  const {onUnderMaintenance} = useContext(WebSettingsContext);

  const theme = useTheme();
  const isAdmin = currentUser?.roles?.includes("admin")

  return (
    <ConstructionCard>
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <CardMediaWrapper>
              <CardMedia
                component="img"
                image={theme.palette.mode === 'dark' ? imageDarkBackground : imageBackground}
                title="Slider 3 image"
              />
              <CardMediaParts src={imageParts} title="Slider 1 image" />
              <CardMediaBuild src={image} title="Slider 2 image" />
            </CardMediaWrapper>
          </Grid>
          <Grid item xs={12}>
            <PageContentWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1" component="div">
                    Under Maintenance
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    This site is on under maintenance!! Please check after some time
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/*<Button>*/}
                  {/*  <Button variant="contained" size="large" component={Link} to={"/"}>*/}
                  {/*    <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> Home*/}
                  {/*  </Button>*/}
                  {
                    isAdmin && <Button
                      size="large"
                      variant={"contained"}
                      startIcon={<IconBulldozer/>}
                      onClick={onUnderMaintenance}
                      sx={{ml: 1}}
                    >
                      Under Maintenance
                    </Button>
                  }
                  {/*</Button>*/}
                </Grid>
              </Grid>
            </PageContentWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ConstructionCard>
  );
};

export default UnderMaintenance;
