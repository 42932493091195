import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Stack,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {MetadataRepository} from "../../../../repositories/MetadataRepository";

export const CreateMetadata = ({open, handleClose, onSuccess, knowledge}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (open) {
      setFormData({subject: "", predicate: "", object: ""});
    }
  }, [open]);

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    onUpdate(key, value);
  }

  const onUpdate = (key, value) => {
    setFormData(prev => ({...prev, [key]: value}))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        sx: {minWidth: "320px", maxWidth: "540", width: "100%"},
        onSubmit: (event) => {
          event.preventDefault();
          if (!formData?.subject || !formData?.predicate || !formData?.object) {
            toast.error("All fields are required!")
            return;
          }
          MetadataRepository.addMetadataToKnowledgeItemId(knowledge.id, formData)
            .then(() => {
              onSuccess();
            })
            .catch(err => {
              toast.error(err?.response?.data?.message || err?.message || "Failed to create metadata")
            })
        },
      }}
    >
      <DialogTitle>Create Triple</DialogTitle>
      <DialogContent>
        {/*<DialogContentText fontSize={12}>*/}
        {/*  In natural language processing (NLP), a "knowledge base" refers to a structured repository of information that*/}
        {/*  is used to support various NLP tasks such as question answering, information retrieval, and natural language*/}
        {/*  understanding.*/}
        {/*</DialogContentText>*/}

        <Stack direction={"row"} gap={1} sx={{py: 1}}>
          <TextField
            label={"Subject"}
            required
            fullWidth
            name={"subject"}
            value={formData?.subject}
            onChange={onChange}
          />
          <TextField
            label={"Predicate"}
            required
            fullWidth
            name={"predicate"}
            value={formData?.predicate}
            onChange={onChange}
          />
          <TextField
            label={"Object"}
            required
            fullWidth
            name={"object"}
            value={formData?.object}
            onChange={onChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  )
}