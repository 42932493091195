import React, {useContext, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {SignInContext} from "../../../context/SignInContext";
import {SessionUMRepository} from "../../../repositories/SessionUMRepository";

export const OrganizationData = ({newProject, setNewProject}) => {
  const {organizations} = useContext(SignInContext);
  const [asriProjects, setAsriProjects] = useState([]);

  useEffect(() => {
    SessionUMRepository.getUserSessions()
      .then(response => {
        setAsriProjects(response?.data?.filter(item => item?.module?.moduleType === "SPEECH_TO_TEXT") || []);
      })
      .catch(err => console.log(err))
  }, [])

  return (
    newProject?.settings &&
    <Stack direction={"column"} gap={2} useFlexGap flexWrap={"wrap"}>
      <FormControl size={"small"}>
        <InputLabel id={`organizations-label`}
                    sx={{textTransform: "capitalize"}}>Organization</InputLabel>
        <Select
          labelId={`organizations-label`}
          id={`organizations-select`}
          label={"Organization"}
          value={newProject?.settings?.organization || "none"}
          onChange={e => {
            const value = e.target.value;
            setNewProject(prev => {
              const state = {...prev};
              state.settings.organization = value === "none" ? null : value;
              return state;
            })
          }}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {organizations?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
        </Select>
      </FormControl>

      <TextField
        size={"small"}
        name={"org_type"}
        label={"Organization type"}
        placeholder={"Organization type"}
        value={newProject?.settings?.organization_type}
        onChange={e => {
          const value = e.target.value;
          setNewProject(prev => {
            const state = {...prev};
            state.settings.organization_type = value === "" ? null : value;
            return state;
          })
        }}
      />

      <FormControl size={"small"}>
        <InputLabel id={`asri-label`}
                    sx={{textTransform: "capitalize"}}>Speech to Text</InputLabel>
        <Select
          labelId={`asri-label`}
          id={`asri-select`}
          label={"Speech to Text"}
          value={newProject?.settings?.speechToText || "none"}
          onChange={e => {
            const value = e.target.value;
            setNewProject(prev => {
              const state = {...prev};
              state.settings.speechToText = value === "none" ? null : value;
              return state;
            })
          }}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {asriProjects?.map(item => <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Stack>
  )
}