export const IconText = ({size = 20, color = "#252525"}) => (
  <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_465_105)">
      <path d="M4 8V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8" stroke={color}
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 16V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H8" stroke={color}
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V8" stroke={color}
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V16" stroke={color}
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16V9" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 9H15" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_465_105">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)