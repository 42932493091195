import axios from "../config/axios-kb";

export const QaDatasetRepository = {
  create: (data) => {
    return axios.post(`/api/qa_datasets`, data);
  },
  edit: (id, data) => {
    return axios.put(`/api/qa_datasets/${id}`, data);
  },
  remove: (id) => {
    return axios.delete(`/api/qa_datasets/${id}`);
  },
  get: (id) => {
    return axios.get(`/api/qa_datasets/${id}`);
  },
  filter: ({ organization, topic, slot }) => {
    return axios.get(`/api/qa_datasets/filter`, {
      params: {
        organization,
        topic,
        slot,
      },
    });
  },
};
