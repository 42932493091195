import React, {useEffect, useState} from "react";
import {
  Box,
  Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField, Tooltip
} from "@mui/material";
import {
  IconDeviceFloppy, IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {ProjectForm} from "../../project-creation/components/ProjectForm";
import Typography from "@mui/material/Typography";
import {lightBlue} from "@mui/material/colors";

export const ProjectUpdateForm = ({
                                    project,
                                    setProject,
                                    languages,
                                    organizations,
                                    asriProjects,
                                    fetchData,
                                    users,
                                    loading,
                                    onDelete
                                  }) => {

  const [addMemberDialog, setAddMemberDialog] = useState(false);

  const onChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    updateProject(key, value);
  }

  const updateProject = (key, value) => {
    setProject(prev => ({...prev, [key]: value}))
  }

  return (
    <>
      <AddMemberDialog
        open={addMemberDialog}
        handleClose={() => setAddMemberDialog(false)}
        users={users}
        onSuccess={(newUser) => {
          if (newUser) {
            const team = project?.team || [];
            const uniqueMembers = new Set([...team, newUser]);
            updateProject("team", [...uniqueMembers]);
          }
          setAddMemberDialog(false);
        }}
      />
      <Box sx={{pt: 3, px: 4}}>
        <Typography variant={"h4"} fontWeight={600}>Settings</Typography>
      </Box>
      <Box sx={{py: 1}}>
        <ProjectForm setProject={setProject} project={project} languages={languages} organizations={organizations}
                     asriProjects={asriProjects} fetchData={fetchData}/>

        <Grid container sx={{px: 2, py: 0}}>
          <Grid item xs={12} sm={4} sx={{p: 1}}>
            <TextField
              label={"Host"}
              value={project?.host || ""}
              name={"host"}
              fullWidth
              placeholder={"ex: myhost.com"}
              InputLabelProps={{shrink: true}}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{p: 1}}>
            <TextField
              label={"Port"}
              value={project?.port || ""}
              name={"port"}
              fullWidth
              placeholder={"ex: 5050"}
              InputLabelProps={{shrink: true}}
              onChange={onChange}
              helperText={"Between 1 and 6555"}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{p: 1}}>

            <FormControl fullWidth>
              <InputLabel id={`sip-label`}
                          shrink={true}
                          sx={{textTransform: "capitalize"}}>Protocol</InputLabel>
              <Select
                labelId={`sip-label`}
                id={`sip-select`}
                label={"Protocol"}
                name={"protocol"}
                value={project?.protocol || ""}
                onChange={onChange}
              >
                <MenuItem value={""}><em>None</em></MenuItem>
                <MenuItem value={"TCP"}>TCP</MenuItem>
                <MenuItem value={"UDP"}>UDP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            component={Stack} direction={"row"} useFlexGap flexWrap={"wrap"}
            item xs={12} gap={1} sx={{p: 1}}
          >
            <Typography fontWeight={600} sx={{width: "100%"}}>Members</Typography>
            {project?.team?.map(member => (
              <Chip
                key={member} label={member}
                onDelete={() => {
                  const team = project?.team || [];
                  const newTeam = team?.filter(m => m !== member);
                  updateProject("team", newTeam);
                }}
              />
            ))
            }
            <Tooltip title={"Add member"}>
              <IconButton sx={{borderRadius: "50% !important"}} onClick={() => setAddMemberDialog(true)} size={"small"}>
                <IconPlus size={16}/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Stack direction={"row"} justifyContent={"space-between"} sx={{px: 3, pb: 3}}>
        <Button type={"button"} variant={"outlined"} color={"error"}
                onClick={onDelete} disabled={loading}
                sx={{borderRadius: "16px !important", border: "0.4px solid !important", borderColor: "error"}}
                startIcon={<IconTrash/>}>
          Delete
        </Button>
        <Button type={"submit"} variant={"contained"} disabled={loading}
                color={"success"} sx={{borderRadius: "16px !important", border: 1}}
                startIcon={<IconDeviceFloppy/>}>
          Save
        </Button>
      </Stack>
    </>
  )
}

const AddMemberDialog = ({open, handleClose, users, onSuccess}) => {
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (!open) {
      setSelectedUser(null);
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Add Member"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            sx={{flex: 1, m: 1, minWidth: "300px"}}
            value={selectedUser || ""}
            onChange={e => setSelectedUser(e.target.value)}
          >
            {users.map((name) => (
              <MenuItem
                key={name}
                value={name}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{mr: "auto", color: lightBlue[700]}} onClick={handleClose}>Cancel</Button>
        <Button onClick={() => onSuccess(selectedUser)} disabled={selectedUser === null} sx={{color: lightBlue[700]}}
                autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}