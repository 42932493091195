import React, {useEffect, useState} from "react";
import {
  Fade,
  IconButton,
  Stack,
  TablePagination,
} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {KnowledgeRepository} from "../../../../../repositories/KnowledgeRepository";
import {KnowledgeDocumentTable} from "../../../knowledge-base/knowledge-table/KnowledgeDocumentTable";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "80vw",
  maxHeight: "80dvh",
  height: "100%",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 2,
  py: 1,
  overflowY: "auto"
};

export const KBModal = ({open, handleClose, slot, topic, organization, project}) => {
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [pagination, setPagination] = useState({page: 1, size: 10, totalPages: 1, totalElements: 1});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organization) {
      fetchData();
    }
  }, [organization, topic, slot])

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await KnowledgeRepository.filterKnowledge({
        organization_id: organization,
        topic_id: topic || null,
        slot_id: slot || null,
        page: pagination?.page,
        size: pagination?.size,
      });
      setKnowledgeData(response.data.content || []);
      setPagination(prev => {
        if (prev) {
          return ({...prev, totalElements: response.data.totalElements, totalPages: response.data.totalPages})
        }
        return ({
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages
        })
      })
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }


  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={handleClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack direction={"column"} sx={style}>
            <Stack id={"kb"} direction={"column"} sx={{flex: 1, overflowY: "auto"}}>
              <KnowledgeDocumentTable
                fetchData={fetchData} project={project} query={null}
                knowledgeData={knowledgeData} updateKnowledge={() => {}}
                organization={organization}
                topics={[]} slots={[]}
                showEdit={false}
              />
            </Stack>
            <TablePagination
              component="div"
              count={pagination?.totalElements}
              page={pagination?.page - 1}
              // onPageChange={(e, page) => console.log(pagination.page, page)}
              onPageChange={(e, page) => setPagination(prev => ({...prev, page: page + 1}))}
              rowsPerPage={pagination?.size}
              onRowsPerPageChange={(e) => setPagination(prev => ({...prev, page: 1, size: +e.target.value}))}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
