import React, {useContext, useEffect, useState} from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import {UserAvatar} from "./header/Header";
import "./main.css"
import {WebSettingsContext} from "../../context/WebSettingsContext";
import {SignInContext} from "../../context/SignInContext";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import {
  IconBuilding,
  IconHome,
  IconMenu,
  IconSearch,
  IconSelector
} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {useDebounce} from "../../hooks/useDebounce";

export const MainLayout = () => {
  const {settings, searchParams, onUpdateParams} = useContext(WebSettingsContext);
  const {currentUser} = useContext(SignInContext);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState();
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 500);

  useEffect(() => {
    if (currentUser !== null) {
      const isAdmin = currentUser?.roles?.includes("admin")
      if (!isAdmin && settings?.underMaintenance) {
        navigate("/under-maintenance");
      }
    }
  }, [settings, currentUser, navigate])

  useEffect(() => {
    onUpdateParams({term: searchDebounced})
  }, [searchDebounced])


  return (
    <Stack direction={"row"} alignItems={"stretch"} sx={{width: "100vw", height: "100vh", bgcolor: grey[50]}}>
      {/*<Header/>*/}
      <SideBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} searchParams={searchParams}
               onUpdateParams={onUpdateParams}/>
      <Box sx={{flex: 1, overflowY: "auto", position: "relative"}}>
        <Toolbar sx={{
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          bgcolor: "rgba(255,255,255,0.1)",
          backdropFilter: "blur(4px)"
        }}>
          <IconButton sx={{display: {xs: "flex", md: "none"}}} onClick={() => setOpenDrawer(true)}>
            <IconMenu/>
          </IconButton>
          <Input
            sx={{minWidth: "280px"}}
            placeholder={"Search"} disableUnderline
            startAdornment={<InputAdornment position={"start"}><IconSearch/></InputAdornment>}
            value={search} onChange={(e) => setSearch(e.target.value)}
          />
        </Toolbar>
        <Toolbar/>
        <Outlet/>
      </Box>
    </Stack>
  )
}

export const SideBar = ({openDrawer, setOpenDrawer, searchParams, onUpdateParams}) => {
  return (
    <>
      <Box
        sx={{
          display: {xs: "none", md: "flex"}
        }}
      >
        <SideBarContent searchParams={searchParams} onUpdateParams={onUpdateParams}/>
      </Box>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          height: "100vh",
          width: '100%',
          flex: 1,
          display: {xs: "inherit", md: "none"},

        }}
        PaperProps={{
          sx: {
            maxWidth: "360px",
            width: "90% !important",
          },
        }}
      >
        <SideBarContent searchParams={searchParams} onUpdateParams={onUpdateParams}/>
      </Drawer>
    </>
  )
}

export const SideBarContent = ({searchParams, onUpdateParams}) => {
  const {currentUser, organizations, onLogout} = useContext(SignInContext);
  console.log(searchParams)

  const organization = organizations?.find(org => +org?.id === +searchParams?.organization);

  return (
    <Stack direction={"column"} gap={1} sx={{p: 1, height: "100%", bgcolor: "#fefefe", minWidth: {xs: 48, md: 280}}}
           className={"border-shadow"}>
      <Stack direction={"row"} gap={1} alignItems={"center"} sx={{p: {xs: 1, md: 2}}}>
        <img src={"/logo.svg"} alt={"logo"} width={35}/>
        <Typography fontWeight={600}>
          BlockFlow
        </Typography>
      </Stack>
      <Box sx={{my: 3}}>
        <Typography fontWeight={700} sx={{px: 1}}>Organization</Typography>
        <Select
          sx={{
            borderRadius: 2,
            border: "0 !important",
            bgcolor: "#fff",
            pr: 2,
            py: 0.5,
            my: 1,
            "&>*": {border: "0 !important"}
          }}
          fullWidth
          className={"border-shadow"}
          label={"Organization"}
          IconComponent={() => <IconSelector/>}
          inputProps={{sx: {display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}}
          value={organization?.id || "none"}
          onChange={(e) => {
            const value = e.target.value;
            onUpdateParams({organization: value === "none" ? null : +value})
          }}
        >
          <MenuItem value={"none"}>
            All Projects
          </MenuItem>
          {organizations?.map(item => <MenuItem key={item?.id} value={item?.id} sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center"
          }}>
            {item?.img &&
              <img src={item?.img} alt={"organization"} width={20} height={20} style={{objectFit: "contain"}}/>}
            {item?.name}
          </MenuItem>)}
        </Select>
      </Box>

      <List sx={{minWidth: 0}}>
        <ListItemButton
          component={NavLink} to={`/`}
          sx={{
            borderRadius: "8px !important",
            alignItems: "center", mt: 1,
            minWidth: "0 !important",
            "&.active": {
              bgcolor: "#efefef"
            }
          }}
        >
          <ListItemIcon sx={{minWidth: 0}}>
            <IconHome/>
          </ListItemIcon>
          <ListItemText primary={"Projects"} sx={{pl: 1}}/>
        </ListItemButton>
        <ListItemButton
          component={NavLink} to={`/organizations`}
          sx={{
            borderRadius: "8px !important",
            alignItems: "center", mt: 0.5,
            minWidth: "0 !important",
            "&.active": {
              bgcolor: "#efefef",
              fontWeight: "bold !important"
            }
          }}
        >
          <ListItemIcon sx={{minWidth: 0}}>
            <IconBuilding/>
          </ListItemIcon>
          <ListItemText primary={"Organizations"} sx={{pl: 1}}/>
        </ListItemButton>
      </List>

      <Divider fullWidth sx={{mt: "auto"}}/>
      <Box sx={{pb: {xs: 1, md: 2}}}>
        <UserAvatar currentUser={currentUser} onLogout={onLogout}/>
      </Box>
    </Stack>
  )
}