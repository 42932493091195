import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';

import {Box, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import {
  IconChevronRight, IconCornerRightDownDouble,
} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {IconFlow} from "../assets/icons/IconFlow";

function FlowNode({id, data}) {
  const {name, greet} = data;
  var steps = data.steps || [];
  const totalSteps = steps?.length;

  const nextRoute = steps?.find(item => item?.type === "next");
  const noMatchRoute = steps?.find(item => item?.type === "no_match");
  const failRoute = steps?.find(item => item?.type === "fail");
  steps = steps?.filter(item => !["next", "no_match", "fail", "data"].includes(item?.type))

  return (
    <Box
      className={"flow-node"}
      sx={{
        minWidth: "200px",
        maxWidth: "200px",
        width: "100%",
        bgcolor: "#d3e5f4",
        border: 1,
        borderRadius: 0,
        borderColor: "#d3e5f4",
        boxShadow: 1
      }}>
      <Handle type="target" position={Position.Left} id={id} style={{top: 15}}/>
      <div className="custom-node__header" style={{borderRadius: "inherit", border: 0,}}>
        <Stack direction={"row"} gap={1} sx={{width: "100%"}}>
          <IconFlow size={16}/>
          <Typography noWrap sx={{fontSize: 9, flex: 1, color: "dark.main"}}>{name}</Typography>
        </Stack>
      </div>
      <Box
        sx={{
          position: "relative",
          borderBottom: totalSteps > 0 ? 1 : 0,
          borderColor: "divider",
          px: 1,
          py: 1.5,
          mb: steps?.length > 0 ? 0.2 : 0,
          bgcolor: "#fff",
          borderTopRightRadius: "inherit",
          borderTopLeftRadius: "inherit",
        }}>
        <Typography noWrap fontSize={9}>{greet || <em>No greet message</em>}</Typography>
        {/*<Tooltip title={"Next route"} placement={"right"}>*/}
        <Handle type="source" position={Position.Right} className={"static next"}
                style={{top: 0, transform: "translateY(0)"}} id={nextRoute?.id}/>
        {/*</Tooltip>*/}
        {/*<Tooltip title={"No match route"} placement={"right"}>*/}
        <Handle type="source" position={Position.Right} className={"static no-match"} style={{top: "50%"}}
                id={noMatchRoute?.id}/>
        {/*</Tooltip>*/}
        {/*<Tooltip title={"Fail route"} placement={"right"}>*/}
        <Handle type="source" position={Position.Right} className={"static fail"}
                style={{top: "100%", transform: "translateY(-100%)"}} id={failRoute?.id}/>
        {/*</Tooltip>*/}
      </Box>

      <div className="custom-node__body" style={{borderRadius: "inherit", background: "#FFF"}}>
        <List sx={{p: 0, m: 0,}}>
          {steps.map((step, index) => {
            return <ListItem
              key={step?.id}
              sx={{
                px: 0.5,
                py: 0.1,
                m: 0,
                textAlign: "center",
              }}
              className="custom-node__select"
            >
              <ListItemIcon sx={{minWidth: 20}}>
                {step?.icon || <IconCornerRightDownDouble color={"#252525"} size={12}/>}
              </ListItemIcon>
              <ListItemText sx={{p: 0}}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <Typography noWrap sx={{flex: 1, fontWeight: 600, fontSize: 8, color: grey[600]}} align={"left"}>
                    {step?.secondary}
                  </Typography>
                </Stack>

                <Handle type="source" className={"dynamic"} position={Position.Right} id={step?.id}/>
              </ListItemText>
            </ListItem>
          })}
        </List>
      </div>
    </Box>
  )
}

export default memo(FlowNode);