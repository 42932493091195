import axios from "../config/axios-kb"


export const MetadataRepository = {
  getByKnowledgeItemId: (knowledgeItemId) => {
    return axios.get(`/api/metadata/${knowledgeItemId}`);
  },
  deleteById: (id) => {
    return axios.delete(`/api/metadata/${id}`)
  },
  addMetadataToKnowledgeItemId: (knowledgeItemId, data) => {
    return axios.post(`/api/metadata/${knowledgeItemId}`, data);
  }
}