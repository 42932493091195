import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {TopicRepository} from "../../../../../repositories/TopicRepository";


export const RecommendationSettings = ({node, setNode, onUpdate, project, onChangeData}) => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    TopicRepository.getAll()
      .then(response => {
        setTopics(response.data);
        console.log(response.data)
      })
      .catch(err => console.log(err));
  }, [])

  console.log(node?.data)
  return (
    <>
      <Stack direction={"row"} gap={1}>
        <FormControl size={"small"}>
          <InputLabel id={`api-method-label`}
                      sx={{textTransform: "capitalize"}}>Api Method</InputLabel>
          <Select
            labelId={`api-method-label`}
            id={`api-method-select`}
            value={node?.data?.recommendation_method || "none"}
            label={`Api Method`}
            onChange={(e) => {
              setNode(prev => {
                return {
                  ...prev,
                  data: {
                    ...prev.data,
                    recommendation_method: e.target.value === "none" ? null : e.target.value
                  }
                }
              })
            }}
            onBlur={() => onUpdate(node)}
          >
            <MenuItem value={"none"}><em>None</em></MenuItem>
            <MenuItem value={"GET"}>GET</MenuItem>
            <MenuItem value={"POST"}>POST</MenuItem>
            <MenuItem value={"PUT"}>PUT</MenuItem>
            <MenuItem value={"PATCH"}>PATCH</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={"Api URL"}
          size={"small"}
          value={node?.data?.recommendation_api}
          InputLabelProps={{shrink: true}}
          sx={{flex: 1}}
          onChange={(e) => {
            setNode(prev => {
              return {
                ...prev,
                data: {
                  ...prev.data,
                  recommendation_api: e.target.value
                }
              }
            })
          }}
          onBlur={() => onUpdate(node)}
        />
      </Stack>
      <FormControl size={"small"}>
        <InputLabel id={`topic-label`}
                    sx={{textTransform: "capitalize"}}>Topic</InputLabel>
        <Select
          labelId={`topic-label`}
          id={`topic-select`}
          label={"Topic"}
          value={node?.data?.topic_id === null ? "none" : node?.data?.topic_id}
          onChange={e => {
            const value = +e.target.value || null;
            setNode(prev => {
              return {
                ...prev,
                data: {
                  ...prev.data,
                  topic_id: value
                }
              }
            })
          }}
          onBlur={() => onUpdate(node)}
        >
          <MenuItem value={"none"}><em>None</em></MenuItem>
          {topics?.filter(item => item?.org_id == project?.settings?.organization)?.map(item => <MenuItem
            key={item?.id} value={item?.id}>{item?.name}</MenuItem>)}
        </Select>
      </FormControl>
      <TextField
        label={"Entity Type"}
        size={"small"}
        value={node?.data?.entity_type}
        InputLabelProps={{shrink: true}}
        onChange={(e) => {
          setNode(prev => {
            return {
              ...prev,
              data: {
                ...prev.data,
                entity_type: e.target.value
              }
            }
          })
        }}
        onBlur={() => onUpdate(node)}
      />
      <TextField
        label={"History Buffer"}
        size={"small"}
        value={node?.data?.conversation_buffer_window}
        InputLabelProps={{shrink: true}}
        onChange={(e) => {
          setNode(prev => {
            return {
              ...prev,
              data: {
                ...prev.data,
                conversation_buffer_window: e.target.value
              }
            }
          })
        }}
        onBlur={() => onUpdate(node)}
      />
    </>
  )
}