import React from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {ConfirmationSettings} from "./ConfirmationSettings";

export const InputSettings = ({node, setNode, project, onUpdate, onChangeData, nodes}) => {
  return (
    <>
      <Typography fontWeight={700} fontSize={13}>Parameter</Typography>
      <FormControl size={"small"} fullWidth>
        <InputLabel id="demo-entity-select-label">Parameter Entity</InputLabel>
        <Select
          labelId="demo-entity-select-label"
          id="demo-entity-select"
          value={node?.data?.parameter?.entity_type}
          label="Parameter Entity"
          onChange={(e) => onChangeData('parameter', {
            entity_type: e.target.value,
            name: node?.data?.parameter?.name || ""
          })}
          onBlur={() => onUpdate(node)}
        >
          <MenuItem value={""}><em>None</em></MenuItem>
          {
            project?.entities?.map(entity => <MenuItem key={entity?.id}
                                                       value={entity?.name}>{entity?.name}</MenuItem>)
          }
        </Select>
      </FormControl>
      <TextField
        value={node?.data?.parameter?.name || ""}
        size={"small"}
        label={"Parameter name"}
        placeholder={"Enter the name of the parameter"}
        helperText={"With this name you will be able to further access the variable."}
        onChange={(e) => onChangeData('parameter', {
          entity_type: node?.data?.parameter?.entity_type || "",
          name: e.target.value || ""
        })}
        onBlur={() => onUpdate(node)}
      />
      <ConfirmationSettings node={node} setNode={setNode} project={project}
                            nodes={nodes}
                            onChangeData={onChangeData}
                            onUpdate={onUpdate}/>
      {/*<KnowledgeBaseSettings node={node} setNode={setNode} project={project} onChangeData={onChangeData}*/}
      {/*                       onUpdate={onUpdate}/>*/}
    </>
  )
}