import axios from "../config/axios-kb"


export const CategoryRepository = {
  getAll: () => {
    return axios.get(`/api/categories`);
  },
  getCategoriesByMember: (id) => {
    return axios.get(`/api/categories/member/${id}`);
  },
  delete: (id) => {
    return axios.delete(`/api/categories/${id}`)
  },
  create: (data) => {
    return axios.post('/api/categories', data)
  },
  edit: (id, data) => {
    return axios.put(`/api/categories/${id}`, data)
  }
}