import React, {Suspense, useContext, useEffect} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {theme} from "./config/Theme";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import {LoginPage} from "./pages/auth/login/LoginPage";
import {Projects} from "./pages/projects/Projects";
import {SignInContext} from "./context/SignInContext";
import {ReactFlowProvider} from "reactflow";
import UnderMaintenance from "./pages/under-maintenance/UnderMaintenance";
import {ProjectHomePage} from "./pages/project/ProjectHomePage";
import {RegisterPage} from "./pages/auth/register/RegisterPage";
import {Organizations} from "./pages/organizations/Organizations";
import {OAuthSuccess} from "./pages/auth/login/OAuthSuccess";
import {ProjectCreationPage} from "./pages/project-creation/ProjectCreationPage";
import {MainLayout} from "./layouts/main-layout/MainLayout";

export const App = () => {
  const {isAuth} = useContext(SignInContext);

  return (
    <ThemeProvider theme={theme}>
      <ReactFlowProvider>
        <ToastContainer theme={"colored"} />
        <Suspense fallback={<LoadingScreen/>}>
          {
            isAuth === null ? null
              : <Routes>
                <Route path="/under-maintenance" exact index element={<UnderMaintenance/>}/>

                {
                  !isAuth ?
                    <>
                      <Route path="/login" index element={<LoginPage/>}/>
                      <Route path="/register" index element={<RegisterPage/>}/>
                      <Route path="/oauth2/redirect" index element={<OAuthSuccess/>}/>
                      <Route path="*" element={<Navigate to="/login"/>}/>
                    </>
                    :
                    <>
                      <Route element={<MainLayout />}>
                        <Route path="/" exact index element={<Projects/>}/>
                        <Route path="/organizations" exact index element={<Organizations/>}/>
                        <Route path="/projects" exact index element={<Projects/>}/>
                        <Route path="/project/create" exact index element={<ProjectCreationPage/>}/>
                      </Route>
                      <Route >

                      </Route>

                      <Route path="/project/edit/:projectId" exact index element={<ProjectCreationPage/>}/>
                      <Route path="/project/:projectId/*" element={<ProjectHomePage/>}/>
                      <Route path="*" element={<Navigate to="/"/>}/>
                    </>
                }
              </Routes>
          }
        </Suspense>
      </ReactFlowProvider>
    </ThemeProvider>
  );
}
