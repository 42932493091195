import React from "react";
import {NodeType} from "../../../../../model/ModelData";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export const useLLMNodes = [NodeType.QA, NodeType.INPUT, NodeType.TEACHING, NodeType.TEXT, NodeType.QUERY, NodeType.RECOMMENDATION, NodeType.START_FORM]

export const LLMSettings = ({node, setNode, project, onUpdate, onChangeData, nodes}) => {

  return (
    useLLMNodes.includes(node?.type) &&
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox checked={!!node?.data?.use_llm}
                    onChange={(event =>  onChangeData("use_llm", event.target.checked))}
                    onBlur={() => onUpdate(node)}/>
        )} label="Use LLM" />
    </FormGroup>
  )
}