import {Box, Button, Grid, Stack, Typography} from "@mui/material";

export const ProjectTemplatesType = {
  BLANK: "blank",
}

export const SelectTemplate = ({project, setProject}) => {

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={4} sx={{p: 2}}>
        <Stack
          direction={"column"}
          sx={{borderRadius: 4, p: 2}} className={"border-shadow"}
        >
          <Box>
            <Typography variant={"h4"} fontWeight={600}>Blank template</Typography>
            <Typography variant={"subtitle2"}>This template is empty</Typography>
            <Button
              variant={"outlined"}
              onClick={() => setProject(prev => ({...prev, template: ProjectTemplatesType.BLANK}))}
              color={project?.template === ProjectTemplatesType.BLANK ? "primary" : "secondary"}
            >
              Select
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}