import {TOKEN} from "../../../context/SignInContext";


export const AsriPage = ({project}) => {

  return (
    <iframe title={"ASRI"}
            allow=""
            src={`${process.env.REACT_APP_ASRI_GATEWAY}/cross-origin/project/${project?.settings?.speechToText}/documents?auth=${localStorage.getItem(TOKEN)}`}
            style={{width: "100%", height: "100%", overflowX: "hidden", border:  0}}
            // onLoad={handleLoad}
    >
    </iframe>
  )
}