export const IconFlow = ({size = 16, color = "#252525"}) => (
  <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9.2532" y="0.35" width="5.49355" height="5.49355" stroke={color} strokeWidth="0.7"/>
    <rect x="10.0274" y="19.3177" width="4.33226" height="4.33226" stroke={color} strokeWidth="0.7"/>
    <line x1="12.35" y1="6.19354" x2="12.35" y2="18.9677" stroke={color} strokeWidth="0.7"/>
    <rect x="1.12417" y="14.6726" width="4.33226" height="4.33226" stroke={color} strokeWidth="0.7"/>
    <line x1="3.13389" y1="14.3226" x2="3.13389" y2="11.6129" stroke={color} strokeWidth="0.7"/>
    <rect x="18.5435" y="14.6726" width="4.33226" height="4.33226" stroke={color} strokeWidth="0.7"/>
    <line x1="20.5532" y1="14.3226" x2="20.5532" y2="11.6129" stroke={color} strokeWidth="0.7"/>
    <path d="M3.0968 11.4774H20.9032" stroke={color} strokeWidth="0.7"/>
  </svg>
)
