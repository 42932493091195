import React, {useState} from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {PersonaModal} from "../../../../../components/modals/PersonaModal";

export const QASettings = ({node, setNode, project, onUpdate, onChangeData, nodes}) => {
  const [expandPersona, setExpandPersona] = useState(false);
  const [expandParaphrazerPersona, setExpandParaphrazerPersona] = useState(false);

  const {primary, secondary} = menuItemsInfo.knowledge_base;

  return (
    <>
     <PersonaModal
       handleClose={() => setExpandPersona(false)}
       open={expandPersona}
       onSuccess={(content) => onChangeData('persona', content)}
       text={node?.data?.persona}
     />
     <PersonaModal
       handleClose={() => setExpandParaphrazerPersona(false)}
       open={expandParaphrazerPersona}
       onSuccess={(content) => onChangeData('persona_paraphrazer', content)}
       text={node?.data?.persona_paraphrazer}
     />
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"secondary2"} fontSize={13}>{secondary}</Typography>
      </Box>

      <TextField
        label={"QA Api"}
        size={"small"}
        value={node?.data?.qa_api}
        InputLabelProps={{shrink: true}}
        onBlur={() => onUpdate(node)}
        onChange={(e) => {
          setNode(prev => {
            return {
              ...prev,
              data: {
                ...prev.data,
                qa_api: e.target.value
              }
            }
          })
        }}
      />
      <FormControl size={"small"} fullWidth>
        <InputLabel id={`qa-method-label`}
                    sx={{textTransform: "capitalize"}}>QA Method</InputLabel>
        <Select
          labelId={`qa-method-label`}
          id={`qa-method-select`}
          value={node?.data?.qa_method || null}
          label={`QA Method`}
          onBlur={() => onUpdate(node)}
          onChange={(e) => {
            setNode(prev => {
              return {
                ...prev,
                data: {
                  ...prev.data,
                  qa_method: e.target.value
                }
              }
            })
          }}
        >
          <MenuItem value={null}><em>None</em></MenuItem>
          <MenuItem value={"GET"}>GET</MenuItem>
          <MenuItem value={"POST"}>POST</MenuItem>
          <MenuItem value={"PUT"}>PUT</MenuItem>
          <MenuItem value={"PATCH"}>PATCH</MenuItem>
        </Select>
      </FormControl>

      {/*<Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>*/}
      {/*  <FormControlLabel control={*/}
      {/*    <Checkbox*/}
      {/*      size={"small"}*/}
      {/*      checked={node?.data?.paraphrazer}*/}
      {/*      onChange={(e) => onChangeData('paraphrazer', e.target.checked)}*/}
      {/*      onBlur={() => onUpdate(node)}*/}
      {/*    />} label="Paraphrazer"/>*/}
      {/*  <FormControlLabel control={*/}
      {/*    <Checkbox*/}
      {/*      size={"small"}*/}
      {/*      checked={node?.data?.lemmatizer}*/}
      {/*      onChange={(e) => onChangeData('lemmatizer', e.target.checked)}*/}
      {/*      onBlur={() => onUpdate(node)}*/}
      {/*    />} label="Lemmatizer"/>*/}
      {/*</Stack>*/}
      {/*{*/}
      {/*  node?.data?.paraphrazer &&*/}
      {/*  <Box>*/}
      {/*    <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>*/}
      {/*      <Typography fontWeight={700}>Persona for Paraphrazer</Typography>*/}
      {/*      <Box>*/}
      {/*        <IconButton onClick={() => setExpandParaphrazerPersona(true)}>*/}
      {/*          <IconArrowsDiagonal size={15}/>*/}
      {/*        </IconButton>*/}
      {/*      </Box>*/}
      {/*    </Stack>*/}
      {/*    <TextField*/}
      {/*      value={node?.data?.persona_paraphrazer}*/}
      {/*      size={"small"}*/}
      {/*      label={"Persona for paraphrazer"}*/}
      {/*      fullWidth*/}
      {/*      placeholder={"Describe persona"}*/}
      {/*      multiline*/}
      {/*      minRows={3}*/}
      {/*      maxRows={9}*/}
      {/*      // helperText={"Ask for confirmation"}*/}
      {/*      onChange={(e) => onChangeData('persona_paraphrazer', e.target.value)}*/}
      {/*      onBlur={() => onUpdate(node)}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*}*/}

      {/*<TextField*/}
      {/*  type={"number"}*/}
      {/*  value={node?.data?.conversation_buffer_window}*/}
      {/*  size={"small"}*/}
      {/*  label={"Conversation buffer window"}*/}
      {/*  placeholder={"Buffer window"}*/}
      {/*  // helperText={"Ask for confirmation"}*/}
      {/*  onChange={(e) => onChangeData('conversation_buffer_window', e.target.value)}*/}
      {/*  onBlur={() => onUpdate(node)}*/}
      {/*/>*/}
      {/*<Box>*/}
      {/*  <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>*/}
      {/*    <Typography fontWeight={700}>Persona</Typography>*/}
      {/*    <Box>*/}
      {/*      <IconButton onClick={() => setExpandPersona(true)}>*/}
      {/*        <IconArrowsDiagonal size={15}/>*/}
      {/*      </IconButton>*/}
      {/*    </Box>*/}
      {/*  </Stack>*/}
      {/*  <TextField*/}
      {/*    value={node?.data?.persona}*/}
      {/*    size={"small"}*/}
      {/*    label={"Persona"}*/}
      {/*    fullWidth*/}
      {/*    placeholder={"Describe persona"}*/}
      {/*    multiline*/}
      {/*    minRows={3}*/}
      {/*    maxRows={9}*/}
      {/*    // helperText={"Ask for confirmation"}*/}
      {/*    onChange={(e) => onChangeData('persona', e.target.value)}*/}
      {/*    onBlur={() => onUpdate(node)}*/}
      {/*  />*/}
      {/*</Box>*/}
    </>
  )
}