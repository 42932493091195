import React, {useMemo, useState} from "react";
import {NodeType} from "../../../model/ModelData";
import {IconText} from "../../../assets/icons/IconText";
import {IconQuery} from "../../../assets/icons/IconQuery";
import {
  IconBrandJavascript,
  IconChalkboard,
  IconChevronDown, IconChevronRight,
  IconChevronUp,
  IconLogicAnd, IconMessageCircle2,
  IconTerminal,
  IconVariable, IconWand
} from "@tabler/icons-react";
import {IconAI} from "../../../assets/icons/IconAI";
import {IconHook} from "../../../assets/icons/IconHook";
import {IconForm} from "../../../assets/icons/IconForm";
import {IconFlow} from "../../../assets/icons/IconFlow";
import {IconExit} from "../../../assets/icons/IconExit";
import {IconSwitch} from "../../../assets/icons/IconSwitch";
import {Box, Button, Fade, Menu, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {grey, teal} from "@mui/material/colors";
import {Debugger} from "./debugger/Debugger";
import {IconQA} from "../../../assets/icons/IconQA";

export const NodeMenu = ({addNode, openChat, updatePosition, useLLM, onToggleLLM}) => {
  const [showMore, setShowMore] = useState(true);
  const nodeItems = useMemo(() => {
    return [
      {
        type: "output",
        label: "Output",
        icon: <IconMessageCircle2 size={25} color={"#252525"} strokeWidth={1}/>,
        items: [
          {type: NodeType.TEXT, label: "Text", icon: <IconText size={25}/>},
          {type: NodeType.JAVASCRIPT, label: "Javascript", icon: <IconBrandJavascript size={25} strokeWidth={1}/>},
        ]
      },
      {type: NodeType.QUERY, label: "Query", icon: <IconQuery size={22}/>},
      {type: "use_llm", label: <>LLM<br/>Booster</>, title: "LLM Booster", icon: <IconWand size={20} color={"#252525"} strokeWidth={1}/>},
      {
        type: "logic",
        label: "Logic",
        icon: <IconLogicAnd size={25} color={"#252525"} strokeWidth={1}/>,
        items: [
          {type: NodeType.SWITCH, label: "Switch", icon: <IconSwitch size={20}/>},
          {type: NodeType.SET_VAR, label: "Set", icon: <IconVariable size={25} strokeWidth={1}/>},
        ]
      },
      {
        type: "ai",
        label: "AI",
        icon: <IconAI size={25} color={"#252525"} strokeWidth={1}/>,
        items: [
          {type: NodeType.RECOMMENDATION, label: "Recommendation", icon: <IconTerminal size={25} color={"#252525"} strokeWidth={1}/>},
          {type: NodeType.QA, label: "QA", icon: <IconQA size={25} color={"#252525"} strokeWidth={1}/>},
          {type: NodeType.TEACHING, label: "Teach", icon: <IconChalkboard size={25} color={"#252525"} strokeWidth={1}/>},
        ]
      },
      {type: NodeType.HOOK, label: "Hook", icon: <IconHook size={25}/>},
      {type: NodeType.START_FORM, label: "Form", icon: <IconForm size={25}/>},
      {type: NodeType.START_FLOW, label: "Flow", icon: <IconFlow size={25}/>},
      {type: NodeType.END, label: "End", icon: <IconExit size={25}/>},
    ]
  }, [])

  return (
    !openChat ?
      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          border: 0,
          borderColor: "divider",
          borderRadius: 0,
          bgcolor: "#FFF",
          boxShadow: 1,
          zIndex: 1000,
          p: 0.5,
          maxWidth: "50px",
          width: "100%"
        }}
      >
        <Stack direction={"column"} gap={0.5}>
          {
            nodeItems?.slice(0, showMore ? nodeItems?.length : 6)
              .map(item => <NodeMenuItem key={item.type} icon={item.icon}
                                         items={item.items || []}
                                         title={item.title || ""}
                                         type={item.type}
                                         useLLM={useLLM}
                                         onToggleLLM={onToggleLLM}
                                         label={item.label} addNode={addNode}/>)
          }
          <Tooltip placement={"right"} title={showMore ? "Hide" : "Show More"}>
            <Button
              size={"small"}
              onClick={() => setShowMore(!showMore)}
              sx={{
                minWidth: 0,
                border: 0,
                px: 0,
                py: 0.5,
                borderRadius: 0,
                borderTopRightRadius: 2,
                borderTopLeftRadius: 2,
                color: grey[600]
              }}
            >
              {showMore ? <IconChevronUp size={20}/> : <IconChevronDown size={20}/>}
            </Button>
          </Tooltip>
        </Stack>
      </Box>
      :
      <Debugger open={openChat} updatePosition={updatePosition}/>
  )
}

const NodeMenuItem = ({type, label, title, icon, items = [], useLLM, addNode, onToggleLLM}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (items.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      if (type === "use_llm") {
        onToggleLLM();
      } else {
        addNode(type);
      }
    }
  };
  const handleClose = (selectedType) => {
    if (Object.values(NodeType)?.includes(selectedType)){
      addNode(selectedType);
    }
    if (selectedType === "use_llm") {
      onToggleLLM();
    }
    setAnchorEl(null);
  };

  const active = type === "use_llm" && useLLM;

  return (
    <>
      <Fade in timeout={500}>
        <Tooltip placement={"right"} title={`Create ${title || label} node`}>
          <Button
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              textTransform: "none",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#FFF",
              borderColor: "divider",
              minWidth: 0,
              py: 0.5,
              px: 0,
              borderRadius: 0,
              fontWeight: active ? 700 : 400,
              color: grey[600],
              "&>svg": { stroke: active ? teal[600] : null, strokeWidth: active ? "1.5px" : "1px" }
            }}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            noWrap
            onClick={handleClick}
          >
            {items?.length > 0 ? <IconChevronRight size={10} color={"#252525"} style={{ position: "absolute", top: '50%', right: 0, transform: 'translateY(-50%)'}} /> : null}
            {icon}
            <Typography noWrap fontSize={11} sx={{
              p: 0,
              fontWeight: active ? 700 : 400,
              color: active ? teal[600] : "#252525",
            }}>{label}</Typography>
          </Button>
        </Tooltip>
      </Fade>
      {
        items?.length > 0 && open &&
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ml: 1}}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {items.map(item => {
            return (
              <MenuItem
                key={item.type}
                onClick={() => handleClose(item.type)}
                sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1, minWidth: "170px"}}
              >
                {item.icon}
                <Typography noWrap fontSize={13} sx={{p: 0, color: "#252525"}}>{item.label}</Typography>
              </MenuItem>
            )
          })}
        </Menu>
      }

    </>
  )
}