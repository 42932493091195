import {Avatar, Box, Button, Divider, Grid, IconButton, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {IconArrowLeft, IconCamera} from "@tabler/icons-react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {ProjectCreateForm} from "./components/ProjectCreateForm";
import axios from "../../config/axios";
import {toast} from "react-toastify";
import {OrganizationRepository} from "../../repositories/OrganizationRepository";
import {SignInContext} from "../../context/SignInContext";
import {ProjectEntity} from "../../model/ModelData";
import {Context as AppContext} from "../../context/AppContext";
import {v4 as uuid} from "uuid";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {uploadImage} from "../../utils/uploadImage";
import {SessionUMRepository} from "../../repositories/SessionUMRepository";
import {WebSettingsContext} from "../../context/WebSettingsContext";

export const ProjectCreationType = {
  NEW_ASSISTANT: "NEW_ASSISTANT",
  IMPORT_ASSISTANT: "IMPORT_ASSISTANT",
}

export const ProjectCreationPage = () => {
  const {currentUser} = useContext(SignInContext);
  const {searchParams} = useContext(WebSettingsContext);
  const {createProject, getUserProjects} = useContext(AppContext);
  const [languages, setLanguages] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [asriProjects, setAsriProjects] = useState([]);
  const [project, setProject] = useState({organization: searchParams?.organization});
  const [type, setType] = useState(ProjectCreationType.NEW_ASSISTANT);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams()

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/language-support/all');
      setLanguages(response.data);
      const organizationResponse = await OrganizationRepository.getUserOrganizations(currentUser?.username);
      setOrganizations(organizationResponse?.data?.organizations || []);
      SessionUMRepository.getUserSessions()
        .then(response => {
          setAsriProjects(response?.data?.filter(item => item?.module?.moduleType === "SPEECH_TO_TEXT") || []);
        })
        .catch(err => console.log(err));
    } catch (err) {
      toast.error(err.message);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!project?.name || !project?.language || !project?.organization) {
      toast.error("All fields are required!");
      return;
    }
    if (type === ProjectCreationType.NEW_ASSISTANT) {
      createNewAssistant();
    } else if (type === ProjectCreationType.IMPORT_ASSISTANT) {
      importAssistant();
    }
  }

  const createNewAssistant = async () => {
    try {
      setLoading(true);
      const org = organizations?.find(org => org?.id === project?.organization);
      const imgURL = await createImage();

      let formData = {...ProjectEntity, name: project?.name || "", description: project?.description || ""}
      formData.settings.organization = project?.organization || null;
      formData.settings.language = project?.language || null;
      formData.settings.organization_type = org?.organization_type || "";
      formData.settings.speechToText = project?.speechToText || null;
      formData.settings.img = imgURL || "";
      formData.settings.category = queryParams.get('category') || null;
      formData.id = uuid();

      await createProject(formData);
      navigate(formData?.id ? `/project/${formData?.id}` : "/projects")

    } catch (e) {
      toast.error(e?.message || "Failed to create project")
    } finally {
      setLoading(false);
    }
  }

  const importAssistant = async () => {
    console.log(project)
    if (!project?.file) {
      toast.error("All fields are required!");
      return;
    }

    try {
      setLoading(true);
      const org = organizations?.find(org => org?.id === project?.organization);
      const imgURL = await createImage();
      // Create a FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append('file', project?.file); // Replace 'file' with the actual file input
      formData.append('username', currentUser?.username);
      formData.append('name', project?.name);
      formData.append('description', project?.description || "");
      formData.append('organization', project?.organization);
      formData.append('organization_type', org?.organization_type || "");
      formData.append('speechToText', project?.speechToText);
      formData.append('language', project?.language);
      formData.append('img', imgURL);
      formData.append('category',      queryParams.get('category') || null);

      const response = await axios.post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/importProject`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': "*"
          }
        });
      console.log('File uploaded successfully:', response.data);
      await getUserProjects();
      navigate(response?.data?.id ? `/project/${response?.data?.id}` : "/projects")

      toast.success("Project imported successfully!")
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error("Failed to import project!")
    } finally {
      setLoading(false);
    }

  }


  const createImage = async () => {
    if (!image) {
      return;
    }
    const id = uuid();
    const url = `projects/${id}-${image?.name}`;

    return await uploadImage({img: image, url: url});
  }

  return (
    <Grid container maxWidth={"lg"} sx={{mx: 'auto', py: 3}}>
      <Grid item xs={12} sx={{p: 2, display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}>
        <IconButton component={Link} to={"/"}>
          <IconArrowLeft/>
        </IconButton>
        <Typography variant={"h4"} fontWeight={600}>Create Project</Typography>
      </Grid>
      <Grid item xs={12} md={5} lg={4} sx={{p: 2}}>
        <Stack direction={"column"} sx={{borderRadius: 4}} alignItems={"center"} className={"border-shadow"}>
          <Box sx={{p: 2}}>
            <Avatar
              src={image !== null ? typeof image === "string" ? image : URL.createObjectURL(image) : null}
              sx={{
                border: 1,
                borderColor: "#153B50",
                bgcolor: "transparent",
                height: 120,
                width: 120,
                fontSize: 50,
                borderRadius: 3,
                mb: "auto", color: "#153B50"
              }}
            >
              {image ? null : <IconCamera size={50}/>}
            </Avatar>
          </Box>
          <Divider sx={{width: "100%"}}/>
          <Button
            component="label"
            fullWidth
            color={"secondary"}
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={(event) => setImage(event.target.files[0])}
              accept="image/*"
            />
          </Button>
        </Stack>
      </Grid>
      <Grid component={"form"} onSubmit={handleSubmit} item xs={12} md={7} lg={8} sx={{p: 2}}>
        <Stack direction={"column"} gap={1} sx={{borderRadius: 4}} className={"border-shadow"}>
          <ProjectCreateForm
            project={project} setProject={setProject}
            languages={languages} organizations={organizations}
            fetchData={fetchData} type={type} setType={setType}
            loading={loading} asriProjects={asriProjects}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}