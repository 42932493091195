import React, {useState} from "react";
import {Box, Button, Stack, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {IconChevronDown, IconChevronLeft} from "@tabler/icons-react";

function RecursiveTable({data, title}) {
  const [open, setOpen] = useState(!title);

  const renderTable = (obj) => {
    return Object.entries(obj).map(([key, value]) => (
      <TableRow key={key}>
        <TableCell sx={{p: 0.3, fontSize: 12, border: 1, borderColor: "divider", fontWeight: 700}}>{key}</TableCell>
        <TableCell sx={{p: 0.3, fontSize: 12, border: 1, borderColor: "divider"}}>
          {
            !value ? "/" :
              Array.isArray(value)
                ? value.join(', \n')
                : typeof value === 'object' ?
                  renderTable(value)
                  : value?.length > 25 ? `${value?.substr(0, 25)}...` : value
          }
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Box sx={{mt: 2}}>
      {
        title &&
        <Button fullWidth onClick={() => setOpen(!open)} sx={{ color: "#000", textTransform: "none" }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%"}}>
            <Typography>{title}</Typography>
            {open ? <IconChevronDown /> : <IconChevronLeft />}
          </Stack>
        </Button>
      }
      {open && <Table sx={{mt: 1}}>
        <TableBody>
          {renderTable(data)}
        </TableBody>
      </Table>}
    </Box>
  );
}

export default RecursiveTable;