import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import React, {useEffect, useState} from "react";
import {MetadataRepository} from "../../../../../repositories/MetadataRepository";
import {CreateMetadata} from "../../components/CreateMetadata";
import useConfirm from "../../../../../hooks/useConfirm";

export const MetadataTable = ({knowledge}) => {
  const [open, setOpen] = useState(false);
  const [metadata, setMetadata] = useState([]);
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this metadata?',
  );

  useEffect(() => {
    if (knowledge) {
      fetchMetadata()
    }
  }, [knowledge])

  const fetchMetadata = () => {
    MetadataRepository.getByKnowledgeItemId(knowledge.id)
      .then(response => {
        setMetadata(response.data);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const handleDelete = async (id) => {
    const response = await confirmDelete();
    if (response) {
      MetadataRepository.deleteById(id)
        .then(response => {
          fetchMetadata();
        })
        .catch(err => {
          console.log(err);
        })
    }
  }


  return (
    <Box sx={{minWidth: "300px", minHeight: "40dvh", border: 1, borderColor: "divider", borderRadius: 3, p: 2}}>
      <Dialog />
      <CreateMetadata
        open={open}
        handleClose={() => setOpen(false)}
        onSuccess={() => {
          fetchMetadata();
          setOpen(false);
        }}
        knowledge={knowledge}
      />

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontWeight={700} fontSize={14}>Knowledge Graph</Typography>
        <IconButton onClick={() => setOpen(true)} size={"small"} sx={{borderRadius: "50% !important"}}>
          <IconPlus size={15} />
        </IconButton>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 700, fontSize: 11}}>Subject</TableCell>
            <TableCell sx={{fontWeight: 700, fontSize: 11}}>Predicate</TableCell>
            <TableCell sx={{fontWeight: 700, fontSize: 11}}>Object</TableCell>
            <TableCell sx={{fontWeight: 700, fontSize: 11}}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metadata?.map(meta => {
            return (
              <TableRow key={meta.id}>
                <TableCell sx={{fontWeight: 500, fontSize: 11}}>{meta.subject}</TableCell>
                <TableCell sx={{fontWeight: 500, fontSize: 11}}>{meta.predicate}</TableCell>
                <TableCell sx={{fontWeight: 500, fontSize: 11}}>{meta.object}</TableCell>
                <TableCell sx={{fontWeight: 500, fontSize: 11}}><Button onClick={() => handleDelete(meta.id)} sx={{fontSize:11}} color={"error"} >Delete</Button></TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}