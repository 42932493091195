import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton,
  InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableRow,
  TextField, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ResourceType} from "../KnowledgeBase";
import FileUpload from "react-mui-fileuploader";
import {KnowledgeRepository} from "../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {IconChecks, IconPlus, IconTrash, IconX} from "@tabler/icons-react";

export const CreateResource = ({open, handleClose, organization, topics, slots, type, onSuccess, selectedResource}) => {
  const [loading, setLoading] = useState(false);
  const [showSelectorForm, setShowSelectorForm] = useState(false);
  const [loadingSelectors, setLoadingSelectors] = useState(false);
  const [formData, setFormData] = useState({});
  const [selector, setSelector] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      console.log(selectedResource);
      setFormData(selectedResource ? {...selectedResource, topic_id: selectedResource.topic} : {resource_type: type});
      setUploadProgress(0);
    }
  }, [selectedResource, type, open]);

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    onUpdate(key, value);
  }

  const onUpdate = (key, value) => {
    setFormData(prev => ({...prev, [key]: value === "none" ? null : value}))
  }

  const handleDelete = () => {
    if (selectedResource && selectedResource?.id) {
      KnowledgeRepository.delete(selectedResource?.id)
        .then(() => {
          toast.success("Deleted successfully");
          onSuccess();
          handleClose();
          navigate(-1)
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.response?.data?.message || error?.message || "Failed to delete");
        })
    }
  }

  const fetchSelectors = async (e) => {
    const value = e.target.value
    if (value && !selectedResource) {
      try {
        setLoadingSelectors(true);
        const response = await KnowledgeRepository.getSelectors(organization?.id, value);
        if (response?.data) {
          setFormData(prev => {
            const selectors = prev?.selectors ? [...prev?.selectors] : [];
            for (const item of response.data) {
              if (!selectors.includes(item)){
                selectors.push(item);
              }
            }
            return {
              ...prev,
              selectors
            }
          })
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingSelectors(false);
      }
    }
  }

  const addSelector = () => {
    if (selector) {
      const text = selector;
      setFormData(prev => {
        const selectors = prev?.selectors ? [...prev.selectors] : [];
        if (!selectors.includes(text)){
          selectors.push(text);
        } else {
          toast.error("Selector already exist!")
        }
        return {
          ...prev,
          selectors: selectors
        }
      })
      setSelector("");
      setShowSelectorForm(false);
    }
  }

  const removeSelector = (text) => {
    setFormData(prev => {
      return {
        ...prev,
        selectors: prev?.selectors?.filter(item => item !== text)
      }
    })
  }

  const intervals = [
    {value: 1, label: "1 minute"},
    {value: 5, label: "5 minutes"},
    {value: 10, label: "10 minutes"},
    {value: 15, label: "15 minutes"},
    {value: 30, label: "30 minutes"},
    {value: 60, label: "1 hour"},
    {value: 120, label: "2 hours"},
    {value: 240, label: "4 hours"},
    {value: 480, label: "8 hours"},
    {value: 720, label: "12 hours"}
  ];
  // Function to format time for display


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        sx: {minWidth: "340px"},
        onSubmit: (event) => {
          event.preventDefault();
          let form = new FormData();

          for (const key of Object.keys(formData)) {
            if (formData[key] || formData[key] === 0) {
              form.append(key, formData[key]);
            }
          }
          if (form.has("selectors")){
            form.delete("selectors");
            formData?.selectors?.forEach(item => form.append('selectors[]', item));
          }
          form.append("organization_id", organization.id);
          if (selectedResource && selectedResource?.id) {
            setLoading(true);
            KnowledgeRepository.edit(selectedResource?.id, form, setUploadProgress)
              .then((response) => {
                onSuccess(response?.data?.knowledge);
              })
              .catch(err => {
                toast(err.message);
              })
              .finally(() => setLoading(false))
          } else {
            setLoading(true);
            KnowledgeRepository.create(form, setUploadProgress)
              .then((response) => {
                onSuccess(response?.data?.knowledge);
              })
              .catch(err => {
                toast(err.message);
              })
              .finally(() => setLoading(false))
          }
        },
      }}
    >
      <DialogTitle>{selectedResource ? "Edit" : "Add"} knowledge item</DialogTitle>
      <DialogContent>
        <FormControl
          variant="outlined"
          margin="dense"
          sx={{mt: 2}}
          size={"small"} fullWidth>
          <InputLabel id={`api-method-label`}
                      sx={{textTransform: "capitalize"}}>Topic</InputLabel>
          <Select
            labelId={`topics-label`}
            id={`topics-select`}
            name={"topic_id"}
            value={formData?.topic_id || ""}
            label={`Topic`}
            onChange={onChange}
          >
            <MenuItem value={""}><em>Select slot</em></MenuItem>
            {topics?.map(topic => (<MenuItem key={topic?.id} value={topic?.id}>{topic?.name}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          margin="dense"
          sx={{mt: 2}}
          size={"small"} fullWidth>
          <InputLabel id={`slot-label`}
                      sx={{textTransform: "capitalize"}}>Slot</InputLabel>
          <Select
            labelId={`slot-label`}
            id={`slot-select`}
            name={"slot_id"}
            value={formData?.slot_id || ""}
            label={`Slot`}
            onChange={onChange}
          >
            <MenuItem value={""}><em>Select slot</em></MenuItem>
            {
              slots?.filter(slot => formData?.topic_id === null || slot?.topic_id == formData?.topic_id)
                .map(slot => (<MenuItem key={slot?.id} value={slot?.id}>{slot?.name}</MenuItem>))
            }
          </Select>
        </FormControl>
        {
          selectedResource &&
          <FormControl
            variant="outlined"
            margin="dense"
            sx={{mt: 2}}
            size={"small"} fullWidth>
            <InputLabel id={`resource_type-label`}
                        sx={{textTransform: "capitalize"}}>Type</InputLabel>
            <Select
              labelId={`resource_type-label`}
              id={`resource_type-select`}
              name={"resource_type"}
              value={formData?.resource_type || ""}
              label={`Type`}
              onChange={onChange}
            >
              <MenuItem value={""}><em>Select resource</em></MenuItem>
              {Object.keys(ResourceType).map(type => {
                return (
                  <MenuItem key={type} value={type}>
                    {ResourceType[type]}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        }

        {
          {
            "url": (
              <>
                <TextField
                  sx={{mt: 2}}
                  name={"url"}
                  fullWidth
                  size={"small"}
                  variant={"outlined"}
                  placeholder={"https://ireason.mk"}
                  label={ResourceType.url}
                  value={formData?.url || ""}
                  onChange={e => {
                    onChange(e);
                    fetchSelectors(e);
                  }}
                />

                <Table size={"small"} sx={{px: 1, mt: 2}}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{width: "100%"}}>
                        <Typography fontWeight={700} fontSize={13}>Selectors:</Typography>
                      </TableCell>
                      <TableCell sx={{textAlign: "end"}}>
                        <IconButton onClick={() => setShowSelectorForm(true)} size={"small"}><IconPlus
                          size={15}/></IconButton>
                      </TableCell>
                    </TableRow>
                    {loadingSelectors &&
                      <TableRow><TableCell colSpan={2}><CircularProgress size={15} /></TableCell></TableRow>
                    }
                    {showSelectorForm &&
                      <TableRow>
                        <TableCell padding={"none"} colSpan={2} sx={{border: 0}}>
                          <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mt: 1}}>
                            <TextField
                              sx={{flex: 1}}
                              name={"selector"}
                              autoFocus
                              size={"small"}
                              variant={"outlined"}
                              placeholder={"div.active"}
                              value={selector}
                              onChange={e => setSelector(e.target.value)}
                            />
                            <IconButton color={"error"}
                                        onClick={() => {
                                          setShowSelectorForm(false);
                                          setSelector("");
                                        }}
                                        size={"small"}
                            >
                              <IconX size={18}/>
                            </IconButton>
                            <IconButton color={"success"}
                                        disabled={!selector}
                                        onClick={addSelector}
                                        size={"small"}
                            >
                              <IconChecks size={18}/>
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    }
                    {formData?.selectors?.map(text => {
                      return <TableRow key={text}>
                        <TableCell>
                          <Typography fontWeight={500} fontSize={13}>{text}</Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton size={"small"} onClick={() => removeSelector(text)}>
                            <IconTrash size={18} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>

                </Table>
              </>
            ),
            "text": (
              <TextField
                sx={{mt: 2}}
                name={"data"}
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                variant={"outlined"}
                value={formData?.data}
                placeholder={"Write text here.."}
                label={ResourceType.text}
                onChange={onChange}
              />
            ),
            "onlinepdf": (
              <TextField
                sx={{mt: 2}}
                name={"pdfLink"}
                fullWidth
                value={formData?.pdfLink}
                variant={"outlined"}
                label={ResourceType.onlinepdf}
                placeholder={"Write pdf link here..."}
                onChange={onChange}
              />
            ),
            "live": (
              <>
                <Stack direction={"row"} alignItems={"center"} gap={1} sx={{mt: 2}}>
                  <FormControl sx={{flex: 1}}>
                    <InputLabel id={`api-method-label`}
                                sx={{textTransform: "capitalize"}}>Method</InputLabel>
                    <Select
                      labelId={`api-method-label`}
                      id={`api-method-select`}
                      label={`Method`}
                      name={"method"}
                      value={formData?.method}
                      variant={"outlined"}
                      onChange={onChange}
                    >
                      <MenuItem value={"none"}><em>None</em></MenuItem>
                      <MenuItem value={"GET"}>GET</MenuItem>
                      <MenuItem value={"POST"}>POST</MenuItem>
                      <MenuItem value={"PUT"}>PUT</MenuItem>
                      <MenuItem value={"PATCH"}>PATCH</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{flex: 4}}
                    name={"url"}
                    value={formData?.url}
                    variant={"outlined"}
                    label={ResourceType.url}
                    placeholder={"Write link here..."}
                    onChange={onChange}
                  />
                </Stack>
                <Box>
                  <FormControl sx={{mt: 2}}>
                    <FormLabel id="demo-radio-buttons-group-label">Repeat</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="repetitive"
                      value={formData?.repetitive}
                      onChange={onChange}
                    >
                      <FormControlLabel value="realtime" control={<Radio/>} label="Realtime"/>
                      <FormControlLabel value="day" control={<Radio/>} label="Every day"/>
                      <FormControlLabel value="repetitive" control={<Radio/>} label="repetitive"/>
                    </RadioGroup>
                  </FormControl>
                </Box>
                {
                  {
                    "repetitive": <Box>
                      <FormControl size={"small"} sx={{minWidth: 100, mt: 2}}>
                        <InputLabel id={`api-method-label`}
                                    sx={{textTransform: "capitalize"}}>Every:</InputLabel>
                        <Select
                          labelId={`api-custom-label`}
                          id={`api-custom-select`}
                          label={`Every day at:`}
                          name={"time"}
                          value={formData?.time}
                          variant={"outlined"}
                          onChange={onChange}
                        >
                          {intervals.map(interval => (
                            <MenuItem key={interval.value} value={interval.value}>{interval.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>,
                    "day": <Box>
                      <TextField
                        type={"time"}
                        name={"time"}
                        value={formData?.time}
                        variant={"outlined"}
                        label={"Time"}
                        InputLabelProps={{shrink: true}}
                        onChange={onChange}
                      />
                    </Box>
                  }[formData?.repetitive]
                }
              </>
            ),
            "pdf": (
              <FormControl sx={{mt: 2, minWidth: 300}}>
                <FileUpload
                  multiFile={false}
                  title="Choose PDF file"
                  header="Drag and drop"
                  onFilesChange={files => onUpdate("pdfFile", files[0])}
                />
                <LinearProgress variant="determinate" value={uploadProgress}/>
              </FormControl>
            )
          }[formData?.resource_type]
        }
      </DialogContent>
      <DialogActions>
        {selectedResource &&
          <Button disabled={loading} onClick={handleDelete} color={"error"} sx={{mr: "auto"}}>Delete</Button>}
        <Button disabled={loading} onClick={handleClose}>Cancel</Button>
        <Button disabled={loading} type="submit">{selectedResource ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}