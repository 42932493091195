import {Box, IconButton, Input, MenuItem, Select, Skeleton, Slide, Stack} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {ConversationsRepository} from "../../../../repositories/ConversationsRepository";
import LoadingScreen from "../../../../components/loading-screen/LoadingScreen";
import {grey} from "@mui/material/colors";
import {IconSend, IconX} from "@tabler/icons-react";
import {MessageItem} from "./MessageItem";
import {MessageRepository} from "../../../../repositories/MessageRepository";
import useConfirm from "../../../../hooks/useConfirm";
import {toast} from "react-toastify";

export const Conversation = ({model}) => {
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [index, setIndex] = useState(null);
  const [message, setMessage] = useState({content: "", role: null})
  const [DialogMessage, confirmDeleteMessage] = useConfirm(
    'Are you sure you want to delete this message?',
  );
  const {conversationId} = useParams();

  useEffect(() => {
    if (model) {
      setMessage({content: "", role: model?.roles[0]?.id || null})
    }
  }, [model])

  useEffect(() => {
    if (conversationId) {
      fetchConversation();
    }
  }, [conversationId])


  const fetchConversation = async () => {
    try {
      setLoading(true);
      const response = await ConversationsRepository.getById(conversationId);
      setConversation(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }


  const onDelete = async (id) => {
    if (id) {
      const response = await confirmDeleteMessage();
      if (response) {
        MessageRepository.delete(id)
          .then(() => {
            toast.success("Successfully deleted message");
            fetchConversation();
          })
          .catch(error => {
            toast.error(error?.message || "Failed to delete message");
          })
      }
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();

    if (message?.role && message?.content && model) {
      const data = {...message, index};

      if (message?.id) {
        MessageRepository.edit(message?.id, data)
          .then(response => {
            setMessage(prev => ({content: "", role: prev?.role || model?.roles[0]?.id || null}));
            const updatedMessage = response.data?.updated_message || null;
            if (updatedMessage) {
              setConversation(prev => {
                return {
                  ...prev,
                  messages: prev?.messages?.map(item => {
                    return item?.id === updatedMessage?.id ? updatedMessage : item;
                  })
                }
              })
              setIndex(null);
            }
          })
      } else {
        ConversationsRepository.addMessage({conversationId: conversation?.id, message: data})
          .then(response => {
            setMessage(prev => ({content: "", role: prev?.role || model?.roles[0]?.id || null}));
            setConversation(response?.data);
            setIndex(data.index + 1);
          })
      }
    }
  }

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    onUpdate(key, value);
  }

  const onUpdate = (key, value) => setMessage(prev => ({...prev, [key]: value}))


  const messageItems = useMemo(() => {
    if (!model || model?.roles?.length === 0) {
      return [];
    }
    const selectedRole = model?.roles?.find(role => role?.id === message?.role);
    let messageItems = conversation?.messages?.map((msg) => (
      <MessageItem
        key={msg?.id} message={msg}
        onSelect={(item) => {
          let prevMessage = {...message};
          let ind = null;
          if (item?.id !== prevMessage?.id) {
            prevMessage = {...item, role: item?.role_id};
            ind = item?.index;
          } else {
            prevMessage = {content: "", role: model?.roles[0]?.id || null}
          }
          setMessage(prevMessage);
          setIndex(ind);
        }}
        onSelectIndex={() => {
          setIndex(msg?.index + 1);
          setMessage({content: "", role: model?.roles[0]?.id})
        }}
        onDelete={onDelete}
        isSelected={message?.id === msg?.id}/>
    ))

    if (index !== null && !message?.id) {
      let margin = {mx: "auto"};
      if (selectedRole?.name === "user") {
        margin = {ml: "auto"}
      } else if (selectedRole?.name === "assistant") {
        margin = {mr: "auto"}
      }
      messageItems = [
        ...messageItems.slice(0, index),
        <Box sx={{...margin, bgcolor: grey[100], p: 1, borderRadius: 3, position: "relative", minWidth: 200, "&:hover > .close-btn": {opacity: 1} }}>
          <IconButton
            size={"small"}
            onClick={() => setIndex(null)}
            className={"close-btn"}
            sx={{zIndex: 5, position: "absolute", "top": 3, right: 3, opacity: 0}}
          >
            <IconX size={13}/>
          </IconButton>
          <Skeleton variant="text" sx={{ fontSize: '1rem', maxWidth: 50 }} />

          <Skeleton variant="text" sx={{ fontSize: '1rem', width: "100%" }} />
        </Box>,
        ...messageItems.slice(index)
      ];
    }
    return messageItems;
  }, [conversation?.messages, message?.role, model, index])


  return (
    <Stack direction={"column"} maxWidth={"md"} p sx={{p: 1, flex: 1, position: "relative", mx: "auto"}}>
      {loading && <LoadingScreen/>}
      <DialogMessage/>
      <Stack direction={"column"} gap={0.5} sx={{flex: 1, overflowY: "auto"}}>
        {messageItems}
      </Stack>
      <Slide direction="up" in={index !== null} mountOnEnter unmountOnExit>
        <Stack
          component={"form"} onSubmit={onSubmit}
          direction={"row"} alignItems={"center"}
          sx={{position: "relative", border: 1, borderColor: "divider", borderRadius: 3, bgcolor: grey[100], p: 1}}
        >
          <Box sx={{position: "absolute", top: 0, right: 0, transform: 'translate(50%, -50%)'}}>
            <IconButton
              size={"small"}
              sx={{
                bgcolor: grey[100],
                border: 1,
                borderColor: "divider",
                borderRadius: "50% !important",
                "&:hover": {bgcolor: grey[100]}
              }}
              onClick={() => setIndex(null)}
            >
              <IconX size={16}/>
            </IconButton>
          </Box>
          <Box sx={{flex: 1}}>
            <Select
              size={"small"} variant={"standard"}
              disableUnderline sx={{minWidth: 150}}
              value={message?.role}
              onChange={onChange}
              name={"role"}
            >
              {model?.roles?.map(role => {
                return <MenuItem key={role.id} value={role.id}>{role?.name}</MenuItem>
              })}
            </Select>
            <Input
              fullWidth
              multiline
              maxRows={3}
              name={"content"}
              onChange={onChange}
              value={message?.content}
              autoFocus
              disableUnderline
              placeholder={"Write your message here"}
            />
          </Box>
          <Box>
            <IconButton type={"submit"}>
              <IconSend/>
            </IconButton>
          </Box>
        </Stack>
      </Slide>
    </Stack>
  )
}