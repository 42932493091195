import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  InputLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ConversationsRepository} from "../../../../repositories/ConversationsRepository";

export const CreateConversation = ({open, handleClose, model, onSuccess, selectedConversation}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (open) {
      setFormData(selectedConversation ? {...selectedConversation} : {});
    }
  }, [selectedConversation, open]);

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    onUpdate(key, value);
  }

  const onUpdate = (key, value) => {
    setFormData(prev => ({...prev, [key]: value === "none" ? null : value}))
  }

  const handleDelete = () => {
    if (selectedConversation && selectedConversation?.id) {
      ConversationsRepository.delete(selectedConversation?.id)
        .then(() => {
          toast.success("Deleted successfully");
          onSuccess();
          handleClose();
        })
        .catch(error => {
          toast.error(error?.response?.data?.message || error?.message || "Failed to delete");
        })
    }
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          let form = {...formData};
          form.model_id = model?.id

          if (selectedConversation && selectedConversation?.id) {
            setLoading(true);
            ConversationsRepository.edit(selectedConversation?.id, form)
              .then((response) => {
                onSuccess(response?.data?.conversation);
                handleClose();
              })
              .catch(err => {
                toast(err.message);
              })
              .finally(() => setLoading(false))
          } else {
            setLoading(true);
            ConversationsRepository.create(form)
              .then((response) => {
                onSuccess(response?.data?.conversation);
                handleClose();
              })
              .catch(err => {
                toast(err.message);
              })
              .finally(() => setLoading(false))
          }
        },
      }}
    >
      <DialogTitle>{selectedConversation ? "Edit" : "Add"} Conversation</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12}>
          Fine-tuning a model is the process of adapting a pre-trained machine learning model to a new task or dataset, leveraging its existing knowledge to improve performance on specific objectives.
        </DialogContentText>

        <TextField
          sx={{mt: 2}}
          name={"name"}
          fullWidth
          variant={"standard"}
          placeholder={"Conversation name"}
          label={"Name"}
          value={formData?.name}
          onChange={onChange}
        />
        <FormControl fullWidth variant={"standard"} sx={{mt: 2}}>
          <InputLabel id={`type-label`}
                      sx={{textTransform: "capitalize"}}>Type</InputLabel>
          <Select
            labelId={`type-label`}
            id={`type-select`}
            label={"Type"}
            name={"type"}
            value={formData?.type}
            onChange={onChange}
          >
            <MenuItem value={"none"}><em>None</em></MenuItem>
            <MenuItem value={"TRAINING"}>Training</MenuItem>
            <MenuItem value={"TESTING"}>Testing</MenuItem>
            <MenuItem value={"VALIDATION"}>Validation</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{mt: 2}}
          name={"description"}
          fullWidth
          variant={"standard"}
          placeholder={"Conversation description"}
          label={"Description"}
          multiline rows={3}
          value={formData?.description}
          onChange={onChange}
        />
        <TextField
          sx={{mt: 2}}
          name={"system_message"}
          fullWidth
          variant={"standard"}
          placeholder={"System prompt message"}
          label={"System Prompt Message"}
          multiline rows={3}
          value={formData?.system_message}
          onChange={onChange}
        />

      </DialogContent>
      <DialogActions>
        {selectedConversation &&
          <Button disabled={loading} onClick={handleDelete} color={"error"} sx={{mr: "auto"}}>Delete</Button>}
        <Button disabled={loading} onClick={handleClose}>Cancel</Button>
        <Button disabled={loading} type="submit">{selectedConversation ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}