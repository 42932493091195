import React, {useEffect, useState} from "react";
import {
  Box,
  Button, Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import LoadingScreen from "../../../../components/loading-screen/LoadingScreen";
import {ConversationsRepository} from "../../../../repositories/ConversationsRepository";
import {CreateConversation} from "./CreateConversation";
import {IconAppWindow, IconFileExport, IconPlus} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {Link} from "react-router-dom";

export const Conversations = ({project, model, setModel, models}) => {
  const [loading, setLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [pagination, setPagination] = useState({page: 1, size: 10, totalPages: 1, totalElements: 1});

  useEffect(() => {
    if (model) {
      fetchData();
    }
  }, [model])

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ConversationsRepository.filterConversations({
        model_id: model?.id,
        page: pagination?.page,
        size: pagination?.size,
      });
      setConversations(response.data.conversations || []);
      setPagination(prev => {
        if (prev) {
          return ({...prev, totalElements: response.data.totalElements, totalPages: response.data.totalPages})
        }
        return ({
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages
        })
      })
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Stack direction={"column"} sx={{p: 1, flex: 1, position: "relative"}}>
      <CreateConversation
        model={model}
        open={createModal}
        selectedConversation={selectedConversation}
        handleClose={() => {
          setCreateModal(false);
          setSelectedConversation(null);
        }}
        onSuccess={fetchData}
      />
      {loading && <LoadingScreen/>}
      <Stack direction={"row"} gap={1}>
        <Button
          onClick={() => setCreateModal(true)}
          variant={"contained"}
          sx={{fontSize: 13, boxShadow: 0, mb: "1px", borderRadius: 0}}
          startIcon={<IconPlus size={16}/>}
        >
          Conversation
        </Button>
        <Button
          component={"a"}
          href={`${process.env.REACT_APP_KB_GATEWAY}/api/conversations/fine-tuning?model_id=${model?.id}`}
          target={"_blank"}
          variant={"contained"}
          sx={{fontSize: 13, boxShadow: 0, mb: "1px", borderRadius: 0}}
          startIcon={<IconFileExport size={16}/>}
        >
          Export
        </Button>
      </Stack>
      <Box sx={{flex: 1}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: "bold"}}>Id</TableCell>
              <TableCell sx={{fontWeight: "bold", width: "100%"}}>Conversation</TableCell>
              <TableCell sx={{fontWeight: "bold"}}>Type</TableCell>
              <TableCell sx={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations?.map(conversation => {
              let bgcolor = "light";
              if (conversation?.type === "TRAINING") {
                bgcolor = "primary";
              } else if(conversation?.type === "TESTING") {
                bgcolor = "info";
              }
              return (
                <TableRow key={conversation?.id} hover>
                  <TableCell>{conversation?.id}</TableCell>
                  <TableCell>
                    <Typography fontWeight={500}>{conversation?.name}</Typography>
                    <Typography fontWeight={400} color={grey[600]}>{conversation?.description}</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      color={bgcolor}
                      label={conversation?.type?.toLowerCase()}
                      sx={{textTransform: "capitalize", width: "100%"}}
                    />
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to={`/project/${project?.id}/fine-tuning/${model?.id}/conversation/${conversation?.id}`} startIcon={<IconAppWindow size={18}/>}>
                      Open
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={pagination?.totalElements}
        page={pagination?.page - 1}
        // onPageChange={(e, page) => console.log(pagination.page, page)}
        onPageChange={(e, page) => setPagination(prev => ({...prev, page: page + 1}))}
        rowsPerPage={pagination?.size}
        onRowsPerPageChange={(e) => setPagination(prev => ({...prev, page: 1, size: +e.target.value}))}
      />
    </Stack>
  )
}