import React from "react";
import {NodeType} from "../../../../../model/ModelData";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";

export const FlowSettings = ({node, setNode, onUpdate, project, onChangeData}) => {
  const flowStartNodes = project?.flows?.map(f => {
    const startNode = {...f?.nodes?.find(n => n?.type === NodeType.START)};
    startNode.flow = f;
    return startNode;
  })

  return (
    <>
      <Typography fontWeight={700} fontSize={13}>Flow</Typography>
      <FormControl size={"small"} fullWidth>
        <InputLabel id="demo-start-flow-select-label">Flow</InputLabel>
        <Select
          labelId="demo-start-flow-select-label"
          id="demo-start-flow-select"
          value={node?.data?.flow_id || ""}
          label="Flow"
          onChange={(e) => onChangeData('flow_id', e.target.value)}
          onBlur={() => onUpdate(node)}
        >
          <MenuItem value={""}><em>None</em></MenuItem>
          {
            flowStartNodes?.map(startNode => <MenuItem key={startNode?.id} value={startNode?.id}>{startNode?.flow?.name}</MenuItem>)
          }
        </Select>
      </FormControl>
      <TextField
        size={"small"}
        label={"Flow params"}
        fullWidth
        value={node?.data?.flow_params?.join(", ") || ""}
        onBlur={() => onUpdate(node)}
        onChange={(e) => onChangeData('flow_params', e.target.value.replaceAll(", ", ",").split(","))}
      />
    </>
  )
}
