import React, {useRef, useState} from "react";
import {
  Box,
  Button, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem, Popover,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {IconAdjustmentsAlt, IconPlus, IconSearch} from "@tabler/icons-react";
import {ResourceType, ResourceTypeIcon} from "../KnowledgeBase";
import {CreateResource} from "./CreateResource";

export const FilterKB = ({slots, organization, topics, filterParams, setFilterParams, onFilter, onCreate}) => {

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    updateFilter(key, value);
  }

  const updateFilter = (key, value) => {
    setFilterParams(prev => {
      const newValue = {...prev};
      newValue[key] = value === "none" ? null : value;
      if (key === "topic") {
        newValue.slot = null;
      }
      return newValue;
    })
  }

  return (
    <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} alignItems={"flex-end"} sx={{p: 1}}>
      <CreateResourceButton onCreate={onCreate} topics={topics} slots={slots} organization={organization} />

      <QueryInput
        updateFilter={updateFilter}
        filterParams={filterParams} setFilterParams={setFilterParams}
      />

      {organization && <Box sx={{minWidth: "150px", ml: "auto"}}>
        <Typography fontSize={12}>Organization</Typography>
        <Select
          fullWidth
          size={"small"}
          value={organization?.id}
          disabled
        >
          <MenuItem value={organization?.id}>{organization?.name}</MenuItem>
        </Select>
      </Box>}
      <Box sx={{minWidth: "150px"}}>
        <Typography fontSize={12}>Topic</Typography>
        <Select
          fullWidth
          size={"small"}
          value={filterParams?.topic || "none"}
          name={"topic"}
          onChange={onChange}
        >
          <MenuItem value={"none"}><em>Filter by topic</em></MenuItem>
          {topics?.map(topicItem => {
            return (
              <MenuItem key={topicItem} value={topicItem?.id}>{topicItem?.name}</MenuItem>
            )
          })}
        </Select>
      </Box>
      <Box sx={{minWidth: "150px"}}>
        <Typography fontSize={12}>Slot</Typography>
        <Select
          fullWidth
          size={"small"}
          value={filterParams?.slot || "none"}
          name={"slot"}
          onChange={onChange}
        >
          <MenuItem value={"none"}><em>Filter by slot</em></MenuItem>
          {slots?.filter(slotItem => filterParams?.topic === null || slotItem?.topic_id == filterParams?.topic)?.map(slotItem => {
            return (
              <MenuItem key={slotItem} value={slotItem?.id}>{slotItem?.name}</MenuItem>
            )
          })}
        </Select>
      </Box>
    </Stack>
  )
}

const QueryInput = ({updateFilter, filterParams}) => {
  const inputRef = useRef();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(inputRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = "filter-params-popover";
  return (
    <Box sx={{minWidth: "150px", flex: 1}}>
      <Typography fontSize={12}>Search</Typography>
      <TextField
        ref={inputRef}
        placeholder={"Search..."}
        size={"small"}
        name={"query"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            updateFilter("query", query);
          }
        }}
        sx={{color: "primary.dark"}}
        InputProps={{
          startAdornment: <InputAdornment position={"start"} sx={{color: "primary.dark"}}>
            <IconSearch size={15}/>
          </InputAdornment>,
          endAdornment: <InputAdornment position={"end"}>
            <IconButton onClick={handleClick}
                        aria-describedby={id}
                        sx={{color: "primary.dark", borderRadius: 2}}><IconAdjustmentsAlt size={15}/></IconButton>
          </InputAdornment>
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
        slotProps={{
          paper: {sx: {width: inputRef?.current?.offsetWidth + 2, p: 2}}
        }}
      >
        <Typography fontWeight={600} fontSize={15}>Adjust Filter Params</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              size={"small"}
              onChange={e => updateFilter("use_qa", e.target.checked)}
              checked={!!filterParams?.use_qa}/>}
            label="Use LM for Answer Markup"
          />
          <FormControlLabel
            control={
              <Checkbox size={"small"}
                        onChange={e => updateFilter("report", e.target.checked)}
                        checked={!!filterParams?.report}/>}
            label="Show Summarized Report"/>
          <FormControlLabel
            control={
              <Checkbox size={"small"}
                        onChange={e => updateFilter("knowledge_graphs", e.target.checked)}
                        checked={!!filterParams?.knowledge_graphs}/>}
            label="Include Results From Knowledge Graphs"/>
        </FormGroup>
      </Popover>
    </Box>
  )
}


const CreateResourceButton = ({onCreate, topics, slots, organization}) => {
  const [type, setType] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (t) => {
    setType(t);
    handleClose();
  }

  return (
    <div>
      <CreateResource
        open={!!type}
        handleClose={() => setType(null)}
        onSuccess={() => {
          setType(null);
          onCreate();
        }}
        type={type}
        topics={topics} slots={slots}
        organization={organization}
      />
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={"contained"}
        sx={{fontSize: 13, boxShadow: 0, mb: "1px"}}
        startIcon={<IconPlus size={16}/>}
      >
        Add Resource
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {sx: {minWidth: "150px", mt: 0.5, borderRadius: 2}},
        }}
        sx={{minWidth: "150px"}}
      >
        {Object.keys(ResourceType).map(type => {
          if (type === "vector") {
            return null;
          }
          return (
            <MenuItem key={type} onClick={() => handleSelect(type)}>
              <ListItemIcon>
                {ResourceTypeIcon[type]}
              </ListItemIcon>
              <ListItemText primary={ResourceType[type]}
                            primaryTypographyProps={{fontSize: 14, textTransform: "capitalize"}}/>
            </MenuItem>
          )
        })}

      </Menu>
    </div>
  );
}