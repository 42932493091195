import React from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconX} from "@tabler/icons-react";
import {NodeType} from "../../../../../model/ModelData";
import {v4 as uuidv4} from "uuid";
import {FlowSettings} from "./FlowSettings";
import {InputSettings} from "./InputSettings";
import {ConfirmationSettings} from "./ConfirmationSettings";
import {HookSettings} from "./HookSettings";
import {QASettings} from "./QASettings";
import {LLMSettings} from "./LLMSettings";
import {TeachingSettings} from "./TeachingSettings";
import {RecommendationSettings} from "./RecommendationSettings";

export const SettingsTab = ({node, setNode, onUpdate, project, nodes}) => {
  const onChangeData = (key, value) => {
    setNode(prev => {
      const state = {...node};
      state.data[key] = value;

      return state;
    })
  }

  const {primary, secondary} = menuItemsInfo.settings;

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"secondary2"} fontSize={13}>{secondary}</Typography>
      </Box>
      <TextField
        value={node?.data?.max_ttl || ""}
        size={"small"}
        type={"number"}
        label={"Max TTL"}
        placeholder={"Enter number"}
        helperText={"Maximum attempts to understand user"}
        onChange={e => onChangeData("max_ttl", e.target.value)}
        onBlur={() => onUpdate(node)}
      />
      <LLMSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />

      {
        node?.type === NodeType?.TEACHING &&
        <TeachingSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      {
        node?.type === NodeType?.START_FLOW &&
        <FlowSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      {
        node?.type === NodeType?.QA &&
        <QASettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      {
        node?.type === NodeType?.INPUT &&
        <InputSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      {
        node?.type === NodeType?.RECOMMENDATION &&
        <RecommendationSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      {
        (node?.type === NodeType.QUERY || node?.type === NodeType.TEXT || node?.type === NodeType.TEACHING) &&
        <>
          <ConfirmationSettings node={node} setNode={setNode} project={project}
                                onChangeData={onChangeData}
                                nodes={nodes}
                                onUpdate={onUpdate}/>
          {/*<KnowledgeBaseSettings node={node} setNode={setNode} project={project} onChangeData={onChangeData}*/}
          {/*                   onUpdate={onUpdate}/>*/}
        </>
      }
      {
        node?.type === NodeType?.HOOK &&
        <HookSettings node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project} nodes={nodes} />
      }
      <CustomParamData
        node={node} setNode={setNode} project={project}
        nodes={nodes}
        onChangeData={onChangeData}
        onUpdate={onUpdate}
      />
    </>
  )
}

const CustomParamData = ({node, setNode, project, onChangeData, onUpdate}) => {

  return (
    <>
      <Typography fontWeight={700} fontSize={13}>Custom Params</Typography>
      {
        node?.data?.custom_params &&
        Object.keys(node?.data?.custom_params)
          ?.map((custom_param_key, index) => {
            return (
              <Stack
                direction={"row"}
                gap={1}
                key={custom_param_key}
              >
                <TextField
                  size={"small"}
                  min={0}
                  defaultValue={custom_param_key}
                  InputLabelProps={{shrink: true}}
                  sx={{flex: 1}}
                  onBlur={(e) => {
                    setNode(prev => {
                      const state = {...prev};
                      if (!state?.data?.custom_params) {
                        state.data.custom_params = {}
                      }
                      const value = state.data.custom_params[custom_param_key] || "";
                      delete state.data.custom_params[custom_param_key];
                      state.data.custom_params[e.target.value] = value;
                      onUpdate(state)
                      return state
                    })
                  }}
                />

                <TextField
                  size={"small"}
                  min={0}
                  value={node.data.custom_params[custom_param_key] || ""}
                  InputLabelProps={{shrink: true}}
                  sx={{flex: 1}}
                  onChange={(e) => {
                    setNode(prev => {
                      const state = {...prev};
                      if (!state?.data?.custom_params) {
                        state.data.custom_params = {}
                      }
                      state.data.custom_params[custom_param_key] = e.target.value;
                      return state
                    })
                  }}
                  onBlur={() => onUpdate(node)}
                />
                <IconButton
                  onClick={() => {
                    setNode(prev => {
                      const state = {...prev};
                      delete state.data.custom_params[custom_param_key];
                      return state
                    })
                  }}
                >
                  <IconX size={15}/>
                </IconButton>
              </Stack>
            )
          })
      }
      <Button
        variant={"outlined"}
        color={"secondary"}
        onClick={() => {
          setNode(prev => {
            const state = {...prev};
            if (!state?.data?.custom_params) {
              state.data.custom_params = {}
            }
            const id = uuidv4();
            state.data.custom_params[id] = "";
            return state
          })
        }}
      >
        Add Custom Param
      </Button>
    </>
  )
}