import React, {useState} from "react";
import useConfirm from "../../../../../hooks/useConfirm";
import {KnowledgeRepository} from "../../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {Box, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {IconPencil, IconTrash} from "@tabler/icons-react";
import {UpdateChunk} from "./UpdateChunk";
import {Keywords} from "./Keywords";
import {QaDatasetRepository} from "../../../../../repositories/QaDatasetRepository";


export const Chunk = ({knowledge, chunk, updateChunk, fetchData, query}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection) {
      setContextMenu(
        contextMenu === null
          ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
      );
      // const newChunk = {...chunk};
      // newChunk.metadata.keywords.push(selection);
      // updateChunk(newChunk);
    }
  };

  const onAddKeyword = () => {
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection) {
      const newChunk = {...chunk};
      newChunk.metadata.keywords.push(selection);
      updateChunk(newChunk);
    }
    handleClose();
  }
  const onAddAnswer = () => {
    const selection = (window.getSelection()?.toString() || document?.selection?.createRange()?.htmlText)?.trim() || "";
    if (selection && query) {
      const range = (window?.getSelection()?.getRangeAt(0) || document?.selection?.getRangeAt(0));
      const start_pos = range?.startOffset;
      const end_pos = range?.endOffset;
      const answerData = {
        question: query,
        content: chunk?.content,
        answer: chunk?.content?.substring(start_pos, end_pos),
        start_pos: start_pos || null,
        end_pos: end_pos || null,
        topic_id: chunk?.metadata?.topic_id || null,
        slot_id: chunk?.metadata?.slot_id || null,
        doc_id: chunk?.metadata?.doc_id || null,
        org_id: chunk?.metadata?.org_id || null,
        chunk_id: chunk?.metadata?._additional?.id || null,
      }
      QaDatasetRepository.create(answerData)
        .then(() => {
          toast.success("Answer added successfully");
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.message || "Failed to add answer");
        })
    }
    handleClose();
  }

  const handleClose = () => {
    setContextMenu(null);
  };

  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this knowledge-details?',
  );

  const deleteResource = async () => {
    const response = await confirmDelete();
    const uuid = chunk?.metadata?._additional?.id || null;
    if (knowledge && response && uuid) {
      KnowledgeRepository.deleteByUUID(knowledge?.id, uuid).then(response => {
        toast.success("Resource deleted successfully")
        fetchData();
      }).catch(e => {
        alert(e.message)
        toast.error(e.message)
      })
    }
  }

  return (
    <Stack direction={"column"} gap={1}
           sx={{borderBottom: 1, borderColor: "divider", py: 1}}>
      <Dialog/>
      <UpdateChunk
        open={openEdit} handleClose={() => setOpenEdit(false)}
        selectedContent={chunk?.content || ""}
        onSuccess={(newContent) => {
          const newChunk = {...chunk};
          newChunk.content = newContent || "";
          updateChunk(newChunk);
          setOpenEdit(false);
        }}
      />
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography sx={{fontSize: 12, fontWeight: "bold"}}>
          {chunk?.metadata?._additional?.id}
        </Typography>
        <IconButton
          onClick={() => setOpenEdit(true)}
          size={"small"}
          sx={{fontSize: 12}}
          disabled={!chunk?.metadata?._additional?.id}
        >
          <IconPencil size={14}/>
        </IconButton>
        <IconButton
          onClick={deleteResource}
          color={'error'} size={"small"}
          sx={{fontSize: 12}}
          disabled={!chunk?.metadata?._additional?.id}
        >
          <IconTrash size={14}/>
        </IconButton>
      </Stack>
      <Box onContextMenu={handleContextMenu} style={{cursor: 'context-menu'}}>
        <Typography sx={{fontSize: 11}} onMouseUp={handleContextMenu}>
          {chunk?.content}
        </Typography>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
              : undefined
          }
          slotProps={{
            paper: {sx: {minWidth: 150} }
          }}
        >
          <MenuItem onClick={onAddKeyword}>Keyword</MenuItem>
          <MenuItem disabled={!query} onClick={onAddAnswer}>Answer</MenuItem>
        </Menu>
      </Box>

      <Keywords
        keywords={chunk?.metadata?.keywords || []}
        updateKeywords={(newKeywords) => {
          const newChunk = {...chunk};
          newChunk.metadata.keywords = newKeywords || [];
          updateChunk(newChunk);
        }}
      />
    </Stack>
  )
}
