import axios from "../config/axios-kb"


export const SlotRepository = {
  getAll: () => {
    return axios.get(`/api/slot`);
  },
  delete: (id) => {
    return axios.delete(`/api/slot/${id}`)
  },
  create: (data) => {
    return axios.post('/api/slot', data)
  },
  edit: (id, data) => {
    return axios.put(`/api/slot/${id}`, data)
  },
  getAllByTopicId: (topicId) => {
    return axios.get(`/api/slot/topic/${topicId}`);
  },
  getById: (slotId) => {
    return axios.get(`/api/slot/${slotId}`)
  },
  getAllByOrganizationId: (orgId) => {
    return axios.get(`/api/slot/organization/${orgId}`)
  }
}