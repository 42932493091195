export const IconHook = ({size = 20, color = "#252525"}) => (
  <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5771 4.11453H4.42291C3.0793 4.11453 2 5.19383 2 6.53744V8.38766V17.4626C2 18.8062 3.0793 19.8855 4.42291 19.8855H19.5771C20.9207 19.8855 22 18.8062 22 17.4626V8.38766V6.53744C22 5.21585 20.9207 4.11453 19.5771 4.11453ZM21.1189 17.4626C21.1189 18.3216 20.4361 19.0044 19.5771 19.0044H4.42291C3.56388 19.0044 2.88106 18.3216 2.88106 17.4626V8.38766H21.1189V17.4626ZM21.1189 7.5066H2.88106V6.53744C2.88106 5.67841 3.56388 4.99559 4.42291 4.99559H19.5771C20.4361 4.99559 21.1189 5.67841 21.1189 6.53744V7.5066Z"
      fill={color}/>
    <path
      d="M4.22461 7.02203C4.63821 7.02203 4.97351 6.68673 4.97351 6.27313C4.97351 5.85952 4.63821 5.52423 4.22461 5.52423C3.811 5.52423 3.47571 5.85952 3.47571 6.27313C3.47571 6.68673 3.811 7.02203 4.22461 7.02203Z"
      fill={color}/>
    <path
      d="M6.49341 7.02203C6.90701 7.02203 7.2423 6.68673 7.2423 6.27313C7.2423 5.85952 6.90701 5.52423 6.49341 5.52423C6.0798 5.52423 5.74451 5.85952 5.74451 6.27313C5.74451 6.68673 6.0798 7.02203 6.49341 7.02203Z"
      fill={color}/>
    <path
      d="M8.78418 7.02203C9.19778 7.02203 9.53308 6.68673 9.53308 6.27313C9.53308 5.85952 9.19778 5.52423 8.78418 5.52423C8.37057 5.52423 8.03528 5.85952 8.03528 6.27313C8.03528 6.68673 8.37057 7.02203 8.78418 7.02203Z"
      fill={color}/>
    <path
      d="M14.9074 16.7577C14.9955 16.8458 15.1057 16.8898 15.2158 16.8898C15.3259 16.8898 15.4361 16.8458 15.5242 16.7577L18.3436 13.9383C18.5198 13.7621 18.5198 13.4978 18.3436 13.3216L15.5242 10.4802C15.348 10.3039 15.0836 10.3039 14.9074 10.4802C14.7312 10.6564 14.7312 10.9207 14.9074 11.0969L17.4184 13.6079L14.9074 16.1189C14.7312 16.2951 14.7312 16.5815 14.9074 16.7577Z"
      fill={color}/>
    <path
      d="M8.47583 16.7577C8.56394 16.8458 8.67407 16.8898 8.7842 16.8898C8.89434 16.8898 9.00447 16.8458 9.09257 16.7577C9.26878 16.5815 9.26878 16.3172 9.09257 16.141L6.58156 13.6079L9.09257 11.1189C9.26878 10.9427 9.26878 10.6784 9.09257 10.5022C8.91636 10.326 8.65204 10.326 8.47583 10.5022L5.65645 13.2995C5.48024 13.4758 5.48024 13.7401 5.65645 13.9163L8.47583 16.7577Z"
      fill={color}/>
    <path
      d="M10.282 16.8238C10.3481 16.8678 10.4142 16.8678 10.4802 16.8678C10.6344 16.8678 10.7886 16.7797 10.8767 16.6255L13.9164 10.9868C14.0265 10.7665 13.9604 10.5022 13.7402 10.392C13.5199 10.2819 13.2556 10.348 13.1454 10.5683L10.1058 16.229C9.97364 16.4493 10.0617 16.7136 10.282 16.8238Z"
      fill={color}/>
  </svg>
)