import {
  Box,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import {IconChevronLeft, IconChevronRight, IconDatabaseImport, IconFileUpload, IconUser} from "@tabler/icons-react";
import React, {useState} from "react";
import {ProjectForm} from "./ProjectForm";
import {ProjectCreationType} from "../ProjectCreationPage";
import {SelectTemplate} from "./SelectTemplate";
import {FileUploader} from "react-drag-drop-files";
import {toast} from "react-toastify";
import {deepPurple} from "@mui/material/colors";
import Typography from "@mui/material/Typography";

export const ProjectCreateForm = ({project, setProject, type, setType, languages, organizations, asriProjects, fetchData, loading}) => {
  const [step, setStep] = useState(0);

  return (
    <>
      <Stack direction={"row"} gap={0} sx={{px: 2, py: 1, borderBottom: 1, borderColor: "divider"}}>
        <Button color={type === ProjectCreationType.NEW_ASSISTANT ? "primary" : "secondary"}
                onClick={() => setType(ProjectCreationType.NEW_ASSISTANT)}
                startIcon={<IconUser/>}
        >
          New Assistant
        </Button>
        <Button color={type === ProjectCreationType.IMPORT_ASSISTANT ? "primary" : "secondary"}
                onClick={() => setType(ProjectCreationType.IMPORT_ASSISTANT)}
                startIcon={<IconDatabaseImport/>}
        >
          Import Assistant
        </Button>
      </Stack>
      {
        {
          0: (
            <>
              <ProjectForm setProject={setProject} project={project} languages={languages} organizations={organizations} asriProjects={asriProjects} fetchData={fetchData}/>
              {
                type === ProjectCreationType.NEW_ASSISTANT ?
                  <Box sx={{px: 3, pb: 2, textAlign: "right"}}>
                    <Button type={"submit"} variant={"contained"}
                            disabled={!project?.name || !project?.language || !project?.organization || !project?.speechToText || loading}
                            sx={{borderRadius: "8px !important"}}
                            endIcon={loading ? <CircularProgress size={18} color={"secondary"} /> : <IconChevronRight size={18}/>}>Submit</Button>
                  </Box>
                  :
                  <Box sx={{px: 3, pb: 2, textAlign: "right"}}>
                    <Button type={"button"} onClick={() => setStep(step + 1)} variant={"contained"}
                            sx={{borderRadius: "8px !important"}}
                            disabled={!project?.name || !project?.language || !project?.organization || !project?.speechToText}
                            endIcon={<IconChevronRight size={18}/>}>Continue</Button>
                  </Box>
              }
            </>
          ),
          1: (
            <>
              {
                type === ProjectCreationType.NEW_ASSISTANT ?
                  <SelectTemplate
                    project={project} setProject={setProject}
                  /> :
                  <Box sx={{p: 3}}>
                    <FileUploader
                      multiple={false}
                      handleChange={(file) => {
                        console.log(file)
                        setProject(prev => ({...prev, file: file}));
                        toast.info("Make sure you have attached the correct file before saving.")
                      }}
                      name="file"
                      types={["json"]}
                    >
                      <Box
                        sx={{
                          border: 2,
                          borderStyle: "dashed",
                          p: 4,
                          borderColor: deepPurple[300],
                          bgcolor: deepPurple[50],
                          borderRadius: 4,
                          textAlign: "center",
                          cursor: "pointer"
                        }}
                      >
                        <IconFileUpload size={40} color={deepPurple[300]}/>
                        <Typography fontWeight={"bold"} fontSize={18}
                                    color={deepPurple[500]}>{project?.file ? "You have attached this file " : "Drag and drop JSON file here"}</Typography>
                        <Typography color={deepPurple[500]}
                                    fontSize={15}>{project?.file ? `${project?.file?.name}` : "or click to upload (50 MB max)"}</Typography>
                      </Box>
                    </FileUploader>
                  </Box>
              }

              <Stack direction={"row"} gap={2} justifyContent={"space-between"} sx={{px: 3, pb: 2, textAlign: "right"}}>
                <Button type={"button"} color={"secondary"} onClick={() => setStep(step - 1)} variant={"text"}
                        sx={{borderRadius: "8px !important"}} startIcon={<IconChevronLeft size={18}/>}>Back</Button>
                <Button variant={"contained"} type={"submit"} sx={{borderRadius: "8px !important"}}
                        disabled={!project?.file || loading}
                        endIcon={loading ? <CircularProgress size={18} color={"secondary"} /> : <IconChevronRight size={18}/>}>Submit</Button>
              </Stack>
            </>
          )
        }[step]
      }

    </>
  )
}