import {
  Box,
  Button,
  CircularProgress, Collapse,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tooltip, Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import useConfirm from "../../../../hooks/useConfirm";
import {KnowledgeRepository} from "../../../../repositories/KnowledgeRepository";
import {toast} from "react-toastify";
import {CreateResource} from "../components/CreateResource";
import {ResourceTypeIcon} from "../KnowledgeBase";
import {
  IconAppWindow, IconChevronDown, IconChevronLeft, IconCircleFilled,
  IconCopy, IconPencil, IconTrash
} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {copyToClipboard} from "../../../../utils/functions";
import {Chunks} from "../knowledge-details/components/Chunks";
import {MetadataTable} from "../knowledge-details/components/MetadataTable";
import moment from "moment/moment";

export const KnowledgeDocumentTable = ({
                                         knowledgeData,
                                         topics,
                                         slots,
                                         organization,
                                         project,
                                         updateKnowledge,
                                         fetchData,
                                         query,
                                         showEdit = true
                                       }) => {

  return (
    <Table sx={{borderCollapse: "collapse", overflowY: "none"}}>
      <TableHead>
        <TableRow>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Type</TableCell>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Id</TableCell>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Content</TableCell>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Topic / Slot</TableCell>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Publish</TableCell>
          <TableCell sx={{fontSize: 12, fontWeight: 700}}>Preview</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {knowledgeData?.map(knowledge => <KnowledgeItem key={knowledge?.id} query={query} showEdit={showEdit}
                                                        knowledge={knowledge} fetchData={fetchData}
                                                        topics={topics} slots={slots} organization={organization}
                                                        project={project} updateKnowledge={updateKnowledge}/>)}
      </TableBody>
    </Table>
  )
}

const KnowledgeItem = ({
                         knowledge,
                         project,
                         fetchData,
                         updateKnowledge,
                         topics,
                         slots,
                         organization,
                         query,
                         showEdit
                       }) => {
  const rowRef = useRef();
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [edit, setEdit] = useState(false);
  const [docs, setDocs] = useState([]);
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete?',
  );

  useEffect(() => {
    if (collapse) {
      if (docs?.length === 0) {
        fetchDocs();
      }
      if (rowRef?.current) {
        setTimeout(() => {
          rowRef?.current?.scrollIntoView({block: "start", inline: "end", behavior: "smooth"});
        }, 300)
      }
    }
  }, [collapse])

  const fetchDocs = () => {
    KnowledgeRepository.getById(knowledge?.id)
      .then(response => {
        setDocs(response.data.docs || []);
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const handleDelete = async () => {
    const response = await confirmDelete();
    if (knowledge && knowledge?.id && response) {
      KnowledgeRepository.delete(knowledge?.id)
        .then(() => {
          toast.success("Deleted successfully");
          fetchData()
        })
        .catch(error => {
          console.log(error);
          toast.error(error?.response?.data?.message || error?.message || "Failed to delete");
        })
    }
  }

  const handlePublish = (knowledge) => {
    if (knowledge) {
      setLoading(true);
      if (knowledge.published) {
        KnowledgeRepository.unpublish(knowledge.id)
          .then((response) => {
            const data = response.data;
            updateKnowledge(data)
          })
          .catch(err => toast.error(err.message || "Failed to unpublish"))
          .finally(() => setLoading(false))
      } else {
        KnowledgeRepository.publish(knowledge.id)
          .then((response) => {
            const data = response.data;
            updateKnowledge(data)
          })
          .catch(err => toast.error(err.message || "Failed to unpublish"))
          .finally(() => setLoading(false))
      }
    }
  }

  const updateChunks = (newChunk) => {
    const uid = newChunk?.id;
    setDocs(prev => {
      return prev?.map(doc => {
        const docUid = doc?.metadata?._additional?.id;
        return docUid === uid ? {
          ...doc,
          content: newChunk?.content,
          metadata: {
            ...doc.metadata,
            keywords: newChunk?.keywords
          }
        } : doc;
      })
    })
  }

  const content = {
    "url": <a href={knowledge.url} target={"_blank"} style={{color: "#252525"}}>{knowledge.url}</a>,
    "pdf": knowledge?.document?.substr(0, 300),
    "vector": knowledge?.document?.substr(0, 300),
    "onlinepdf": <a href={knowledge.url} target={"_blank"} style={{color: "#252525"}}>{knowledge.url}</a>,
    "text": knowledge?.data?.substr(0, 300),
    "live": <span>[{knowledge.method}] {knowledge.url}</span>,
  }[knowledge.resource_type]

  const url = `/project/${project.id}/knowledge-base/${knowledge.id}`;
  let liveIcon = null;
  console.log("resource_type", knowledge?.resource_type)

  if (knowledge?.resource_type === "live") {
    liveIcon = <IconCircleFilled size={12} className={knowledge?.published ? "live-green" : "live-red"} />
  }

  return (
    <>
      <Dialog/>
      <CreateResource
        open={edit}
        handleClose={() => setEdit(false)}
        onSuccess={(newKnowledge) => {
          setEdit(false);
          updateKnowledge(newKnowledge);
        }}
        selectedResource={knowledge}
        topics={topics} slots={slots}
        organization={organization}
      />
      <TableRow hover ref={rowRef}>
        <TableCell
          sx={{
            fontSize: 12,
            border: 0
          }}>{ResourceTypeIcon[knowledge?.resource_type] || knowledge?.resource_type}</TableCell>
        <TableCell sx={{fontSize: 12, border: 0}}>{knowledge?.id}</TableCell>
        <TableCell sx={{
          border: 0,
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: '200px',
          textOverflow: "ellipsis",
          fontSize: 12,
        }}>
          <Tooltip title={content} sx={{color: "#fefefe !important", maxHeight: "30dvh", overflowY: "auto"}}>
            {content}
          </Tooltip>
          {knowledge?.live_last_updated &&
            <Stack direction={"row"} gap={0.5} alignItems={"center"} sx={{fontSize: 10}}>
              {liveIcon}
              <Typography fontSize={10}>{moment(knowledge?.live_last_updated).format("DD MMM YYYY HH:mm") || "N/A"}</Typography>
            </Stack>
          }
        </TableCell>
        <TableCell sx={{fontSize: 12, border: 0}}>{knowledge?.topic_name}/{knowledge?.slot_name}</TableCell>
        <TableCell sx={{fontSize: 12, border: 0}}>
          <Stack direction={"row"} gap={0.5} alignItems={"center"}>
            <Switch size={"small"} disabled={loading} checked={!!knowledge?.published}
                    onChange={() => handlePublish(knowledge)}/>
            {loading && <CircularProgress size={14}/>}
          </Stack>
        </TableCell>
        <TableCell sx={{fontSize: 12, border: 0}}>
          <IconButton size={"small"} onClick={() => setCollapse(!collapse)}>
            {collapse ? <IconChevronDown size={18}/> : <IconChevronLeft size={18}/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow padding={"none"}>
        <TableCell colSpan={7} padding={"none"}>
          <Collapse in={collapse} timeout="auto" unmountOnExit sx={{p: 1}}>
            <Stack direction={"row"} gap={1}>
              <Button component={Link} disabled={loading} target={"_blank"}
                      to={url}
                      startIcon={<IconAppWindow size={18}/>} size={"small"}
                      variant={"text"} sx={{color: "primary.dark"}}>
                Open
              </Button>
              <Button disabled={loading}
                      onClick={async () => {
                        try {
                          await copyToClipboard(new URL(window?.location?.href).origin + url);
                          toast.success("URL copied to clipboard")
                        } catch (e) {
                          toast.error(e?.message || "Failed to copy url")
                        }
                      }}
                      startIcon={<IconCopy size={18}/>} size={"small"}
                      variant={"text"} sx={{color: "primary.dark"}}>
                Share
              </Button>
              {
                showEdit && <>
                  <Button disabled={loading} onClick={() => setEdit(true)}
                          startIcon={<IconPencil size={18}/>} size={"small"}
                          variant={"text"} sx={{color: "primary.dark"}}>
                    Edit
                  </Button>
                  <Button disabled={loading} onClick={handleDelete}
                          startIcon={<IconTrash size={18}/>} size={"small"}
                          variant={"text"} sx={{color: "primary.dark"}}>
                    Delete
                  </Button>
                </>
              }
            </Stack>
            <Typography fontSize={11} sx={{py: 1}}>{content}</Typography>
            <Typography fontSize={14} fontWeight={700}>Resources</Typography>
            <Stack direction={"row"} gap={3} sx={{py: 1}}>
              <Chunks knowledge={knowledge} chunks={docs} fetchData={fetchDocs} updateChunks={updateChunks}
                      query={query}/>
              <Box>
                <MetadataTable
                  knowledge={knowledge}
                />
              </Box>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
