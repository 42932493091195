
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {toast} from "react-toastify";
import React from "react";
import {LLMFineTuningModelRepository} from "../../../../repositories/LLMFineTuningModelRepository";

export const CreateLLMFineTunedModelModal = ({open, handleClose, organization, selectedModel, onDelete, onSuccess}) => {

  const handleDelete = () => {
    if (selectedModel) {
      LLMFineTuningModelRepository.delete(selectedModel.id)
        .then(() => {
          onDelete(selectedModel.id);
          handleClose();
        })
        .catch(err => toast.error(err.message));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData).entries());
          formJson.organization_id = organization;
          if (!formJson.name) {
            toast.error("Name is required field")
            return;
          }
          if (selectedModel && selectedModel?.id) {
            LLMFineTuningModelRepository.edit(selectedModel?.id, formJson)
              .then(response => {
                onSuccess(response.data.model);
              })
              .catch(err => {
                toast.error("Failed to edit model");
              })
              .finally(() => {
                handleClose();
              });
          } else {
            LLMFineTuningModelRepository.create(formJson)
              .then(response => {
                onSuccess(response.data.model);
              })
              .catch(err => {
                toast.error("Failed to create model");
              })
              .finally(() => {
                handleClose();
              });
          }
        },
      }}
    >
      <DialogTitle>{selectedModel ? "Edit" : "Create"} Model</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12}>
          Fine-tuning a model is the process of adapting a pre-trained machine learning model to a new task or dataset, leveraging its existing knowledge to improve performance on specific objectives.
        </DialogContentText>
        <TextField
          autoFocus
          required
          defaultValue={selectedModel?.name || ""}
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        {selectedModel && <Button color={"error"} onClick={handleDelete} sx={{mr: "auto"}}>Delete</Button>}
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">{selectedModel ? "Edit" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  )
}