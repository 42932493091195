import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {SignInContextProvider} from "./context/SignInContext";
import {Provider as AppProvider} from "./context/AppContext";
import "./assets/css/overview.css"
import {WebSettingsContextProvider} from "./context/WebSettingsContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <SignInContextProvider>
              <WebSettingsContextProvider>
                <AppProvider >
                  <App/>
                </AppProvider>
              </WebSettingsContextProvider>
            </SignInContextProvider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
