import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import {Box, Stack, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {IconFlow} from "../assets/icons/IconFlow";


function IntentNode({ id, data }) {
    const {name} = data;

    return (
        <Box className={"intent-node"} sx={{width: "auto", bgcolor: blue[600], border: 1, p: 1, pr: 2, borderRadius: 0, borderColor: "white", boxShadow: 1}}>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{color: blue[50]}}>
                <IconFlow size={13} />
                <Typography noWrap sx={{fontSize: 9, fontWeight: "bold" }}>{name}</Typography>
            </Stack>
            <Handle type="source" position={Position.Right} id={id} />
        </Box>
    );
}

export default memo(IntentNode);
