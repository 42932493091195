import React, {useEffect, useState} from "react";
import {NodeEntity, NodeType, NodeTypeIcon, ScopeType, TargetType} from "../../../../model/ModelData";
import {
  Box, Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, MenuItem, MenuList, Popover,
  Stack,
  Typography
} from "@mui/material";
import {EditNodeMenu} from "./EditNodeMenu";
import useConfirm from "../../../../hooks/useConfirm";
import {
  IconAlertTriangle, IconBrain, IconGripVertical,
  IconMenu, IconTerminal,
  IconTrash, IconVariable,
  IconWriting
} from "@tabler/icons-react";
import {v4 as uuidv4} from "uuid";
import {IconFlow} from "../../../../assets/icons/IconFlow";
import {IconText} from "../../../../assets/icons/IconText";
import {IconHook} from "../../../../assets/icons/IconHook";
import {grey} from "@mui/material/colors";
import {useSearchParams} from "react-router-dom";


export const sortFormNodes = (node, formNodes, newNodes = []) => {
  newNodes.push(node);
  const nextNodeId = node?.routes?.static?.next?.target;
  let next = formNodes?.find(i => i?.id === nextNodeId);
  if (next) {
    sortFormNodes(next, formNodes, newNodes);
  }
  return newNodes;
}

export const EditNodeMenuWrapper = ({
                                      open,
                                      selectedNode,
                                      nodes,
                                      onClose,
                                      onUpdate,
                                      onUpdateNodes,
                                      onRemove,
                                      project,
                                      flow,
                                      onRemoveForm
                                    }) => {
  const [node, setNode] = useState(null);
  const [items, setItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const routeNode = searchParams.get("node"); // is the string "Jonathan"
  const selectedRoute = searchParams.get("route"); // is the string "Jonathan"

  useEffect(() => {
    if (selectedNode) {
      let newItems = [];
      if (selectedNode?.data?.form_id) {
        newItems = nodes?.filter(i => i?.data?.form_id === selectedNode?.data?.form_id);
        if (selectedNode?.data?.form_id !== node?.data?.form_id) {
          setNode(null);
        }
      } else {
        setNode(selectedNode);
      }
      setItems(newItems);
    }
  }, [selectedNode, nodes])

  useEffect(() => {
    if (routeNode && nodes?.length) {
      const newNode = nodes?.find(i => i?.id === routeNode);
      setNode(newNode || null);
    }
  }, [nodes, routeNode])

  const createNode = (type) => {
    const newNodes = [...nodes];
    const startNode = items.find(i => i.data?.form_id === selectedNode?.data?.form_id && i.type === NodeType.START_FORM);
    if (!startNode) {
      return;
    }
    const formNodes = sortFormNodes(startNode, [...items]);

    const prevNode = formNodes[formNodes.length - 2];
    const endNode = formNodes[formNodes.length - 1];

    const newNode = {
      ...NodeEntity,
      id: uuidv4(),
      name: type?.toLowerCase()?.replaceAll("_", " "),
      type: type, // NodeType
      coords: {x: 0, y: 0},
      parameters: [],
      data: {
        greet: [],
        form_id: selectedNode?.data?.form_id
      },
      routes: {
        static: {
          next: {
            id: uuidv4(),
            target: endNode.id,
            name: endNode.name,
            type: "next",
            backstack: false,
            data: {
              greet: ""
            }
          },
          no_match: null,
          fail: null,
        },
        dynamic: []
      }
    };
    if (type === NodeType.HOOK) {
      newNode.data.api_url = "";
      newNode.data.api_method = "GET";
      newNode.data.target = TargetType.FLOW;
      newNode.data.params = [{id: uuidv4(), source: null, scope: ScopeType.FLOW}];
    } else if (type === NodeType.QA || type === NodeType.RECOMMENDATION) {
      newNode.data.qa_method = project?.settings?.qa_method || "";
      newNode.data.qa_api = project?.settings?.qa_api || "";
      newNode.data.please_wait_messages = [];
      newNode.data.persona = "";
      newNode.data.slots = [];
      newNode.data.paraphrazer = true;
      newNode.data.lemmatizer = true;
      newNode.data.conversation_buffer_window = 4;
      if (type === NodeType.RECOMMENDATION){
        newNode.data.parser = `
        function parser(result) {
          // Write your code here
        }
        `;
      }
    }
    prevNode.routes.static.next.target = newNode.id;

    newNodes.push(newNode);
    return newNodes.map(n => {
      if (n.id === prevNode.id) {
        return prevNode
      } else if (n.id === endNode.id) {
        return endNode
      }
      return n;
    })
  }

  const addNode = async (type) => {
    let newNodes = [];
    newNodes = createNode(type);
    await onUpdateNodes([...newNodes]);
    let newFormNodes = [];
    if (nodes) {
      newFormNodes = newNodes?.filter(n => n?.data?.form_id === selectedNode?.data?.form_id);
    }
    setItems(newFormNodes);
  }

  const onUpdateMultipleNodes = async (updateNodes) => {
    let newNodes = [...nodes];
    newNodes = newNodes.map(n => {
      const finded = updateNodes.find(i => i?.id === n.id);
      return finded || n;
    });
    await onUpdateNodes([...newNodes]);
  }

  return (
    open && selectedNode &&
    <>
      {
        selectedNode?.data?.form_id && items.length > 0 &&
        <Stack
          direction={"column"}
          sx={{minWidth: "280px", maxWidth: "330px", height: "100%", maxHeight: "100%"}}
        >
          <MenuItemsWrapper
            items={items}
            node={selectedNode}
            value={node}
            setValue={setNode}
            onRemove={onRemoveForm}
            onClose={() => {
              onClose();
              setNode(null);
            }}
            addNode={addNode}
            onUpdateNodes={onUpdateMultipleNodes}
          />
        </Stack>
      }
      {
        node &&
        <EditNodeMenu
          open={!!node}
          selectedNode={node}
          flow={flow}
          project={project}
          nodes={nodes}
          selectedRoute={selectedRoute}
          showHideMenuButton={node?.id === selectedNode?.id && items.length === 0}
          onClose={() => {
            onClose();
            setNode(null);
          }}
          onUpdate={async (newNode) => {
            console.log(node);
            if (node !== newNode) {
              console.log(newNode)
              await onUpdate(newNode);
              setNode(newNode);
            }
          }}
          onRemove={onRemove}
        />
      }
    </>

  )
}


const MenuItemsWrapper = ({node, value, setValue, items, onRemove, onClose, addNode, onUpdateNodes}) => {
  const [sortedItems, setSortedItems] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverItem, setDragOverItem] = useState(null);

  useEffect(() => {
    if (items && items.length) {
      const startNode = items.find(i => i.data?.form_id === node.data.form_id && i.type === NodeType.START_FORM);
      if (startNode) {
        const sorted = sortFormNodes(startNode, items);
        setSortedItems(sorted);
      }
    }
    setDraggedItem(null);
  }, [items]);

  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this node?',
    node?.name || ""
  );

  const onDelete = async () => {
    const response = await confirmDelete();
    if (response && node?.data?.form_id) {
      onRemove(node?.data?.form_id);
      onClose();
    }
  }

  const onDragEnd = async () => {
    if (draggedItem?.id === dragOverItem?.id) {
      setDraggedItem(null);
      setDragOverItem(null);
      return;
    }
    let newItems = [...items];
    const newDraggedItem = newItems.find(n => n.id === draggedItem.id);
    const prevItem = newItems.find(n => n?.routes?.static?.next?.target === draggedItem.id);
    const nextItem = newItems.find(n => n?.id === draggedItem?.routes?.static?.next?.target);
    if (prevItem && newDraggedItem) {
      prevItem.routes.static.next.target = nextItem.id || null;
    }
    if (dragOverItem === null) {
      newDraggedItem.routes.static.next.target = null;

      await onUpdateNodes(newItems);
      setDraggedItem(null);
      setDragOverItem(null);
      return;
    }
    const newDragOverItem = newItems.find(n => n.id === dragOverItem.id);
    const dragOverNextItem = newItems.find(n => n?.id === newDragOverItem?.routes?.static?.next?.target);

    if (newDragOverItem && dragOverNextItem) {
      newDragOverItem.routes.static.next.target = newDraggedItem.id || null;
      newDraggedItem.routes.static.next.target = dragOverNextItem.id || null;
    }
    await onUpdateNodes(newItems);

    setDraggedItem(null);
    setDragOverItem(null);
  }

  return (
    <Stack direction={"column"} gap={2}
           sx={{p: 1, borderRight: 1, flex: 1, borderColor: value ? "divider" : "transparent"}}>
      <Dialog/>
      <Stack direction={"row"} gap={1} alignItems={"center"} sx={{width: "100%"}}>
        <IconButton size={"small"} onClick={onClose}>
          <IconMenu size={17}/>
        </IconButton>
        <Typography textTransform={"capitalize"} fontWeight={700} sx={{flex: 1}}>Form</Typography>
        <IconButton size={"small"} color={"error"} onClick={onDelete}>
          <IconTrash size={17}/>
        </IconButton>
      </Stack>
      <List sx={{my: 0, py: 0}}>
        {
          sortedItems?.map((nodeItem, index) => {
            const isSelected = value?.id === nodeItem?.id;
            const Icon = NodeTypeIcon[nodeItem?.type] || IconAlertTriangle;
            const isDraggedOver = dragOverItem?.id === nodeItem?.id;
            const isNotDroppable = index !== 0 && index !== sortedItems?.length - 1;
            return (
              <ListItemButton
                key={nodeItem?.id}
                className={isDraggedOver ? "shake" : ""}
                onClick={() => setValue(isSelected ? null : nodeItem)}
                onDragOver={() => setDragOverItem(nodeItem)}
                sx={{
                  p: 0.5,
                  bgcolor: isSelected ? "rgba(220,228,234, 0.5)" : "transparent",
                  "&:hover": {bgcolor: "rgba(220,228,234, 0.5)"}
                }}
              >
                <ListItemIcon>
                  <Icon/>
                </ListItemIcon>
                <ListItemText primary={nodeItem?.name} secondary={nodeItem?.type?.replaceAll("_", " ")}
                              primaryTypographyProps={{fontWeight: 700, fontSize: 13, noWrap: true}}
                              secondaryTypographyProps={{fontSize: 11, textTransform: "capitalize"}}/>
                {
                  isNotDroppable &&
                  <Box
                    draggable
                    onDragStart={() => setDraggedItem(nodeItem)}
                    onDragEnd={onDragEnd}
                  >
                    <IconGripVertical size={13} color={grey[600]}/>
                  </Box>
                }
              </ListItemButton>
            )
          })
        }
      </List>
      {
        sortedItems?.length !== items?.length &&
        <Box sx={{m: 0.5, px: 1, pt: 2, borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"}}>
          <Typography fontSize={12} fontWeight={700}>Nodes that exist in the form but are not connected</Typography>
          <List sx={{borderRadius: "inherit"}}>
            {
              items?.filter(nodeItem => sortedItems?.findIndex(i => i.id === nodeItem?.id) === -1)
                ?.map(nodeItem => {
                  const isSelected = value?.id === nodeItem?.id;
                  const Icon = NodeTypeIcon[nodeItem?.type] || IconAlertTriangle;
                  return (
                    <ListItemButton
                      key={nodeItem?.id}
                      onClick={() => setValue(isSelected ? null : nodeItem)}
                      sx={{
                        p: 0.5,
                        bgcolor: isSelected ? "rgba(220,228,234, 0.5)" : "transparent",
                        "&:hover": {bgcolor: "rgba(220,228,234, 0.5)"}
                      }}
                    >
                      <ListItemIcon>
                        <Icon/>
                      </ListItemIcon>
                      <ListItemText primary={nodeItem?.name} secondary={nodeItem?.type?.replaceAll("_", " ")}
                                    primaryTypographyProps={{fontWeight: 700, fontSize: 13}}
                                    secondaryTypographyProps={{fontSize: 11, textTransform: "capitalize"}}/>
                      <Box
                        draggable
                        onDragStart={() => setDraggedItem(nodeItem)}
                        onDragEnd={onDragEnd}
                      >
                        <IconGripVertical size={13} color={grey[600]}/>
                      </Box>
                    </ListItemButton>
                  )
                })
            }
          </List>
        </Box>
      }
      <CreateNode onSelect={addNode}/>
    </Stack>
  )
}

const CreateNode = ({onSelect}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{minWidth: "250px", p: 1}}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{textTransform: "none", width: '100%'}}
        fullWidth
        variant={"outlined"}
        color={"secondary"}
      >
        Create new form item
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ml: 1, width: '300px'}}
      >
        <MenuList
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          sx={{width: "300px", maxWidth: '100%'}}>
          <MenuItem onClick={() => {
            onSelect(NodeType.TEXT)
            handleClose();
          }}>
            <ListItemIcon>
              <IconText fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Text</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.INPUT)
            handleClose();
          }}>
            <ListItemIcon>
              <IconWriting fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Input</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.QA)
            handleClose();
          }}>
            <ListItemIcon>
              <IconBrain fontSize="small"/>
            </ListItemIcon>
            <ListItemText>QA</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.RECOMMENDATION)
            handleClose();
          }}>
            <ListItemIcon>
              <IconTerminal fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Recommendation</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.HOOK)
            handleClose();
          }}>
            <ListItemIcon>
              <IconHook fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Hook</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.START_FLOW)
            handleClose();
          }}>
            <ListItemIcon>
              <IconFlow fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Flow</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onSelect(NodeType.SET_VAR)
            handleClose();
          }}>
            <ListItemIcon>
              <IconVariable fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Set</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  )
}

