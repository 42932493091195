import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import {db} from "../config/firebase";
import {doc, getDoc, setDoc, updateDoc} from "firebase/firestore";

export const TOKEN = "token"

export const WebSettingsContext = React.createContext({
  settings: null,
  searchParams: {
    organization: null,
    term: "",
  },
  onUpdateParams: () => {},
  onUnderMaintenance: () => {},
})

const id = 'blockflow';

export const WebSettingsContextProvider = props => {
  const [settings, setSettings] = useState(null);
  const [searchParams, setSearchParams] = useState({
    organization: null, term: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "web_settings", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSettings(docSnap.data());
      } else {
        const data = {
          underMaintenance: false,
          models: []
        }
        await setDoc(doc(db, "web_settings", id), data);
        setSettings(data);
      }
    }

    fetchData()
  }, [])

  const onUnderMaintenance = async () => {
    try {
      const data = {
        underMaintenance: !settings.underMaintenance
      }
      await updateDoc(doc(db, "web_settings", id), data);
      setSettings(prev => ({...prev, ...data}));
      toast.success("Successfully updated settings")
    } catch (e) {
      console.error(e);
      toast.error(e.errorMessage)
    }
  }

  const onUpdateParams = (data) => {
    setSearchParams(prev => ({...prev, ...data}))
  }

  return (
    <WebSettingsContext.Provider
      value={{
        settings: settings,
        searchParams: searchParams, onUpdateParams: onUpdateParams,
        onUnderMaintenance: onUnderMaintenance
      }}
    >
      {settings === null ? <LoadingScreen/> : props.children}
    </WebSettingsContext.Provider>
  )
}