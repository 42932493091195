import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import React from "react";

export const SIPData = ({newProject, setNewProject}) => {

  return (
    <Stack direction={"column"} gap={2}>
      <TextField
        label={"Host"}
        size={"small"}
        value={newProject?.settings?.host || ""}
        placeholder={"ex: myhost.com"}
        onChange={e => {
          setNewProject(prev => {
            const state = {...prev};
            if (!state.settings) {
              state.settings = {}
            }
            state.settings.host = e.target.value;
            return state;
          })
        }}
      />
      <TextField
        label={"Port"}
        size={"small"}
        value={newProject?.settings?.port || ""}
        placeholder={"ex: 5050"}
        onChange={e => {
          setNewProject(prev => {
            const state = {...prev};
            if (!state.settings) {
              state.settings = {}
            }
            state.settings.port = e.target.value;
            return state;
          })
        }}
        helperText={"Must be between 1 and 65535"}
      />
      <FormControl size={"small"}>
        <InputLabel id={`sip-label`}
                    sx={{textTransform: "capitalize"}}>Protocol</InputLabel>
        <Select
          labelId={`sip-label`}
          id={`sip-select`}
          label={"Protocol"}
          value={newProject?.settings?.protocol || ""}
          onChange={e => {
            setNewProject(prev => {
              const state = {...prev};
              state.settings.protocol = e.target.value;
              return state;
            })
          }}
        >
          <MenuItem value={""}><em>None</em></MenuItem>
          <MenuItem value={"TCP"}>TCP</MenuItem>
          <MenuItem value={"UDP"}>UDP</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}