import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';

import {Box, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import {
  IconChevronRight, IconCornerRightDownDouble,
  IconSquare,
} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";


function CustomNode({id, data}) {
  const {name, type, icon, greet, node} = data;
  var steps = data.steps || [];
  const totalSteps = steps?.length;

  const nextRoute = steps?.find(item => item?.type === "next");
  const noMatchRoute = steps?.find(item => item?.type === "no_match");
  const failRoute = steps?.find(item => item?.type === "fail");
  steps = steps?.filter(item => !["next", "no_match", "fail", "data"].includes(item?.type))

  return (
    <Tooltip title={totalSteps === 0 ? "Please connect node with other node" : name}>
      <Box
        className={`custom-node ${node?.data?.use_llm ? "magic-node" : ""}`}
        sx={{
          minWidth: "200px",
          maxWidth: "200px",
          width: "100%",
          bgcolor: totalSteps === 0 ? 'rgb(252,252,217)' : "#d3e5f4",
          border: 1,
          borderRadius: 0,
          borderColor: totalSteps === 0 ? "rgb(239,239,170)" : "#d3e5f4",
          boxShadow: 1
        }}>
        <Handle type="target" position={Position.Left} id={id} style={{top: 15}}/>
        <div className="custom-node__header" style={{borderRadius: 0, border: 0}}>
          <Stack direction={"row"} gap={1} sx={{width: "100%"}}>
            {icon ? icon : <IconSquare size={13}/>}
            <Typography noWrap sx={{fontSize: 9, fontWeight: "bold", flex: 1}}>{name}</Typography>
          </Stack>
        </div>
        <Box
          sx={{
            position: "relative",
            borderBottom: totalSteps > 0 ? 1 : 0,
            borderColor: "divider",
            display: node?.type === "qa" ? "none" : "block",
            px: 1,
            py: 1.5,
            mb: steps?.length > 0 ? 0.2 : 0,
            bgcolor: "#fff",
            borderTopRightRadius: "inherit",
            borderTopLeftRadius: "inherit",
          }}>
          <Typography noWrap fontSize={9}>{greet || <em>No greet message</em>}</Typography>
          {/*<Tooltip title={"Next route"} placement={"right"}>*/}
          <Handle type="source" position={Position.Right} className={"static next"}
                  style={{top: 3, transform: "translateY(0)"}} id={nextRoute?.id}/>
          {/*</Tooltip>*/}
          {/*<Tooltip title={"No match route"} placement={"right"}>*/}
          <Handle type="source" position={Position.Right} className={"static no-match"} style={{top: "50%"}}
                  id={noMatchRoute?.id}/>
          {/*</Tooltip>*/}
          {/*<Tooltip title={"Fail route"} placement={"right"}>*/}
          <Handle type="source" position={Position.Right} className={"static fail"}
                  style={{top: "calc(100% - 3px)", transform: "translateY(-100%)"}} id={failRoute?.id}/>
          {/*</Tooltip>*/}
        </Box>

        <div className="custom-node__body" style={{borderRadius: 0, background: "#FFF"}}>
          <List sx={{p: 0, m: 0,}}>
            {steps.map((step, index) => {
              return <ListItem
                key={step?.id}
                sx={{
                  px: 0.5,
                  py: 0.1,
                  m: 0,
                  textAlign: "center",
                }}
                className="custom-node__select"
              >
                <ListItemIcon sx={{minWidth: 20}}>
                  {step?.icon || <IconCornerRightDownDouble color={"#252525"} size={12}/>}
                </ListItemIcon>
                <ListItemText sx={{p: 0}}>
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography noWrap sx={{flex: 1, fontWeight: 600, fontSize: 8, color: grey[600]}} align={"left"}>
                      {step?.secondary}
                    </Typography>
                  </Stack>

                  <Handle type="source" className={"dynamic"} position={Position.Right} id={step?.id}/>
                </ListItemText>
              </ListItem>
            })}
          </List>
        </div>
      </Box>
    </Tooltip>
  )
}

export default memo(CustomNode);