import {collection} from "firebase/firestore";
import {db} from "../config/firebase";

export const USER_COLLECTION = "users";
export const FLOWS_COLLECTION = "flows";
export const PROJECTS_COLLECTION = "projects";
export const PROJECT_VERSION_COLLECTION = "project_versions";

export const usersCollection = collection(db, USER_COLLECTION);
export const flowsCollection = collection(db, FLOWS_COLLECTION);
export const projectsCollection = collection(db, PROJECTS_COLLECTION);
