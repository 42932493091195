import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Stack
} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import React, {useContext, useEffect, useState} from "react";
import {GeneralData} from "../project/settings/GeneralData";
import {OrganizationData} from "../project/settings/OrganizationData";
import {Members} from "../project/settings/Members";
import {SIPData} from "../project/settings/SIPData";
import {IconBuilding, IconInfoSquareRounded, IconUsers, IconWebhook} from "@tabler/icons-react";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../config/firebase";
import {PROJECT_VERSION_COLLECTION, PROJECTS_COLLECTION} from "../../db/collections";
import {toast} from "react-toastify";
import {Context as AppContext} from "../../context/AppContext";
import useConfirm from "../../hooks/useConfirm";


export const EditProjectModal = ({open, handleClose, selectedProject}) => {
  const {updateProject, getUserProjects} = useContext(AppContext)
  const [newProject, setNewProject] = useState({});
  const [value, setValue] = useState(0);
  const [DeleteDialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this project?',
  );

  useEffect(() => {
    setNewProject({...selectedProject});
    setValue(0);
  }, [selectedProject])

  const onSave = async () => {
    try{
      await updateProject(newProject);
      await getUserProjects();
      handleClose();
    } catch (e) {
      console.log(e);
      toast.error(e?.message || "Failed to save")
    }
  }

  const onDelete = async () => {
    const response = await confirmDelete();
    if (response) {
      try {
        const batch = writeBatch(db);
        // Delete versions
        if (newProject?.versions?.length) {
          for (const version of newProject?.versions) {
            // Delete version
            const versionRef = doc(db, PROJECT_VERSION_COLLECTION, version?.id);
            batch.delete(versionRef);
          }
        }

        // Delete project
        const projectRef = doc(db, PROJECTS_COLLECTION, newProject?.id);
        batch.delete(projectRef);

        await batch.commit();
        await getUserProjects();

      } catch (err) {
        toast.error(err);
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DeleteDialog/>
      <DialogTitle id="alert-dialog-title">Update Project</DialogTitle>
      <DialogContent sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        minHeight: "25dvh",
        minWidth: "300px",
        maxWidth: "500px",
        width: "400px"
      }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"}>
          <Button
            size={"small"}
            sx={{
              borderRadius: 0, flexDirection: "column", justifyContent: "center",
              fontWeight: value === 0 ? 700 : 500, fontSize: 11, p: 0.5,
              color: value === 0 ? "primary" : "#252525", borderBottom: value === 0 ? "2px solid !important" : 0
            }}
            onClick={() => setValue(0)}
          >
            <IconInfoSquareRounded size={20} stroke={1.5}/>
            <span>General</span>
          </Button>
          <Button
            size={"small"}
            sx={{
              borderRadius: 0, flexDirection: "column", justifyContent: "center",
              fontWeight: value === 1 ? 700 : 500, fontSize: 11, p: 0.5,
              color: value === 1 ? "primary" : "#252525", borderBottom: value === 1 ? "2px solid !important" : 0
            }}
            onClick={() => setValue(1)}
          >
            <IconBuilding size={20} stroke={1.5}/>
            <span>Organization</span>
          </Button>
          <Button
            size={"small"}
            sx={{
              borderRadius: 0, flexDirection: "column", justifyContent: "center",
              fontWeight: value === 2 ? 700 : 500, fontSize: 11, p: 0.5,
              color: value === 2 ? "primary" : "#252525", borderBottom: value === 2 ? "2px solid !important" : 0
            }}
            onClick={() => setValue(2)}
          >
            <IconUsers size={20} stroke={1.5}/>
            <span>Members</span>
          </Button>
          <Button
            size={"small"}
            sx={{
              borderRadius: 0, flexDirection: "column", justifyContent: "center",
              fontWeight: value === 3 ? 700 : 500, fontSize: 11, p: 0.5,
              color: value === 3 ? "primary" : "#252525", borderBottom: value === 3 ? "2px solid !important" : 0
            }}
            onClick={() => setValue(3)}
          >
            <IconWebhook size={20} stroke={1.5}/>
            <span>SIP</span>
          </Button>
        </Stack>
        {
          {
            0: <GeneralData newProject={newProject} setNewProject={setNewProject}/>,
            1: <OrganizationData newProject={newProject} setNewProject={setNewProject}/>,
            2: <Members newProject={newProject} setNewProject={setNewProject}/>,
            3: <SIPData newProject={newProject} setNewProject={setNewProject}/>,
          }[value]
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} color={"error"} sx={{mr: "auto"}}>Delete</Button>
        <Button onClick={handleClose} sx={{color: lightBlue[700]}}>Close</Button>
        <Button onClick={onSave} disabled={newProject === selectedProject}
                sx={{color: lightBlue[700]}}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}