import {
  Box,
  IconButton, InputAdornment,
  List, ListItem,
  ListItemButton,
  ListItemText,
  Stack, TextField,
  Fade,
  Typography, Button
} from "@mui/material";
import {grey} from "@mui/material/colors";
import React, {useState} from "react";
import {
  IconLoader,
  IconPlus,
  IconSearch,
  IconX
} from "@tabler/icons-react";
import {CreateTopicModal} from "./CreateTopicModal";
import {CreateSlotModal} from "./CreateSlotModal";


export const SideMenuKB = ({project, organization, topics, slots, setTopics, setSlots}) => {

  return (
    <Fade in={!!project} timeout={500}>
      <Stack direction={"column"}sx={{
        maxHeight: '100%',
        minWidth: "220px",
        maxWidth: "220px",
        width: "100%",
        borderRight: 1,
        pb: 0.5,
        bgcolor: "transparent",
        borderColor: "divider",
        overflowY: "auto",
      }}>

        <TopicsBlock project={project} organization={organization} topics={topics} setTopics={setTopics}/>
        <SlotsBlock project={project} organization={organization} topics={topics} slots={slots} setSlots={setSlots}/>
      </Stack>
    </Fade>
  )
}

const TopicsBlock = ({project, organization, topics, setTopics}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [search, setSearch] = useState("");

  return (
    <>
      <CreateTopicModal
        open={openModal}
        handleClose={() => {
          setSelectedTopic(null);
          setOpenModal(false);
        }}
        organization={organization}
        selectedTopic={selectedTopic}
        onSuccess={(newTopic) => {
          setTopics(prev => {
            const newValue = [...prev];
            const index = newValue.findIndex(item => item.id === newTopic.id);
            if (index >= 0) {
              newValue[index] = newTopic;
            } else {
              newValue.push(newTopic);
            }
            return newValue;
          })
        }}
        onDelete={(id) => {
          setTopics(prev => {
            const newValue = [...prev].filter(topic => topic?.id !== id);
            return newValue;
          })
        }}
      />
      <ListBlock
        title={"Topics"}
        items={topics?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        onCreate={() => setOpenModal(true)}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        mapItem={(topic) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            mb: 0.5,
            p: 0.5,
            // "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"},
          };

          return (
            <ListItemButton
              key={topic?.id || topic?.key}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedTopic(topic);
                setOpenModal(true);
              }}>
              <ListItemText
                primary={topic?.name}
                primaryTypographyProps={{fontSize: 13}}
              />
            </ListItemButton>
          )
        }}
      />
    </>
  )
}
const SlotsBlock = ({project, setSlots, organization, topics, slots}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [search, setSearch] = useState("");


  return (
    <>
      <CreateSlotModal
        open={openModal}
        handleClose={() => {
          setSelectedSlot(null);
          setOpenModal(false);
        }}
        organization={organization}
        selectedSlot={selectedSlot}
        topics={topics}
        onSuccess={(newSlot) => {
          setSlots(prev => {
            const newValue = [...prev];
            const index = newValue.findIndex(item => item.id === newSlot.id);
            if (index >= 0) {
              newValue[index] = newSlot;
            } else {
              newValue.push(newSlot);
            }
            return newValue;
          })
        }}
        onDelete={(id) => {
          setSlots(prev => {
            const newValue = [...prev].filter(slot => slot?.id !== id);
            return newValue;
          })
        }}
      />
      <ListBlock
        title={"Slots"}
        items={slots?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        onCreate={() => setOpenModal(true)}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        mapItem={(slot) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            my: 0.5,
            p: 1,
            py: 0.5,
          };
          const topic = topics?.find(topic => topic?.id == slot?.topic_id)

          return (
            <ListItemButton
              key={slot?.id || slot?.key}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedSlot(slot);
                setOpenModal(true);
              }}>
              <ListItemText
                primary={slot?.name}
                secondary={topic?.name}
                primaryTypographyProps={{fontSize: 13, color: "dark.main"}}
                secondaryTypographyProps={{fontSize: 11, color: "dark.main"}}
              />
            </ListItemButton>
          )
        }}
      />
    </>
  )
}

export const ListBlock = ({items, title, mapItem, search, onChangeSearch, onCreate, isOpen}) => {
  const [open, setOpen] = useState(!!isOpen);
  const [pagination, setPagination] = useState(5);

  return (
    <Box
      className={"side-item"}
      sx={{
        // border: open ? 1 : 0,
        borderBottom: 1,
        borderColor: "rgba(220,228,234, 0.5)",
        // bgcolor: open ? "rgba(220,228,234, 0.3)" : "#FFF",
        p: 0.5,
        m: 0.5,
        mb: 0,
        borderRadius: open ? 0 : 0
      }}
    >
      <List sx={{py: 0, m: 0}}>
        <ListItem
          sx={{
            mb: open ? 0.5 : 0,
            p: 0, zIndex: 10,
            // "&:hover": {bgcolor: open ? "rgba(220,228,234, 0.5)" : "rgba(220,228,234, 0.3)"},
            "&:hover > .create-btn": {opacity: 1, bgcolor: "transparent"}
          }}
        >
          <ListItemButton
            sx={{p: 1, "&:hover": {bgcolor: "transparent"}}}
            onClick={() => setOpen(!open)}>
            <Typography sx={{color: "dark.main", fontWeight: "bold", fontSize: 12}}>{title}</Typography>
          </ListItemButton>
          <IconButton className={"create-btn"} size={"small"} onClick={onCreate}
                      sx={{zIndex: 100, opacity: open ? 1 : 0, color: "dark.main"}}>
            <IconPlus size={13}/>
          </IconButton>
        </ListItem>
        {
          open &&
          <Box sx={{px: 1}}>
            <TextField
              size={"small"}
              variant={"standard"}
              value={search}
              fullWidth
              placeholder={"Search"}
              onChange={(e) => onChangeSearch(e.target.value)}
              InputProps={{
                sx: {borderRadius: 0, mb: 1},
                startAdornment: <InputAdornment position="start">
                  <IconSearch size={14}/>
                </InputAdornment>,
                endAdornment: search ? <InputAdornment position="end">
                  <IconButton size={"small"} onClick={() => onChangeSearch("")}>
                    <IconX size={14}/>
                  </IconButton>
                </InputAdornment> : null
              }}
            />
          </Box>
        }
        {
          open && items?.slice(0, pagination)?.map(item => mapItem(item))
        }
      </List>
      {
        open && items?.length > 5 ?
          <>
            {
              items?.length > pagination ?
                <Button fullWidth onClick={() => setPagination(pagination + 5)}
                        sx={{fontSize: 12, textTransform: "none", color: grey[700]}}><IconLoader size={14}
                                                                                                 style={{marginRight: 5}}/> Show
                  More</Button>
                : <Button fullWidth onClick={() => setPagination(5)}
                          sx={{fontSize: 12, textTransform: "none", color: grey[700]}}><IconLoader size={14}
                                                                                                   style={{marginRight: 5}}/> Show
                  Less</Button>

            }
          </> : null
      }
    </Box>
  )
}