import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import React, {useEffect, useState} from "react";

export const CreateKeyword = ({open, handleClose, onSuccess, selectedKeyword}) => {
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword(selectedKeyword);
  }, [selectedKeyword])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {selectedKeyword ? "Edit keyword" : "Create keyword"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            sx={{mt: 1, minWidth: "300px"}}
            label={"Keyword"}
            autoFocus
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{color: lightBlue[700]}}>CLOSE</Button>
        <Button onClick={() => onSuccess(keyword)} disabled={!keyword} sx={{color: lightBlue[700]}}>
          AGREE
        </Button>
      </DialogActions>
    </Dialog>
  )
}