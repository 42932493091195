import React, {useContext, useMemo, useState} from "react";
import {
  Avatar,
  Button,
  Stack,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Tooltip, Menu, Box, MenuItem, useTheme, ListItemIcon, ListItemText, ListItemButton, List
} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import {
  IconBuilding,
  IconBulldozer,
  IconHome,
  IconLogout,
  IconMenu, IconSettings,
  IconUser
} from "@tabler/icons-react";
import {SignInContext} from "../../../context/SignInContext";
import {grey} from "@mui/material/colors";
import {HeaderDrawer} from "./HeaderDrawer";
import {WebSettingsContext} from "../../../context/WebSettingsContext";
import {CreateProjectModal} from "../../../pages/projects/CreateProjectModal";
import {ImportProjectModal} from "../../../pages/projects/ImportProjectModal";
import {Context as AppContext} from "../../../context/AppContext";


export const Header = () => {
  const {isAuth, currentUser, onLogout, openModal} = useContext(SignInContext);
  const {settings, onUnderMaintenance} = useContext(WebSettingsContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createProject, setCreateProject] = useState(false);
  const [importProject, setImportProject] = useState(false);
  const {getUserProjects} = useContext(AppContext);

  const theme = useTheme();

  const isAdmin = currentUser?.roles?.includes("admin")


  return (
    <>
      <HeaderDrawer
        open={openDrawer}
        handleClose={() => setOpenDrawer(false)}
        openCart={() => {
          setOpenDrawer(false);
        }}
      />
      <CreateProjectModal
        open={createProject}
        handleClose={() => setCreateProject(false)}
      />
      <ImportProjectModal
        open={importProject}
        handleClose={() => setImportProject(false)}
      />
      <AppBar position="static"
              sx={{
                bgcolor: "transparent",
                boxShadow: 0,
                zIndex: 100,
                borderBottom: 0,
                minHeight: "48px !important",
                borderColor: "divider"
              }}
      >
        <Toolbar
          sx={{
            bgcolor: "transparent",
            // display: "none",
            zIndex: 100,
            py: 1, px: 2,
            minHeight: "48px !important",
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                 alignItems={"center"}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Button
                component={Link}
                to={"/"}
                variant={"text"}
                sx={{flexDirection: "row", color: "dark.main"}}
                startIcon={<img src={"/logo.svg"} alt={"logo"} width={30}/>}
              >
                <Typography fontWeight={700}>BlockFlow</Typography>
              </Button>
            </Stack>

            <Stack direction={"row"} gap={1} alignItems={"center"}>
              {isAdmin && <>
                <Button
                  component={NavLink}
                  to={"/"}
                  sx={{
                    borderRadius: 0,
                    textTransform: "capitalize",
                    color: "dark.main",
                    "&.active > *": {
                      fontWeight: "bold !important",
                    }
                  }}
                  startIcon={<IconHome size={18}/>}
                >
                  <Typography sx={{display: {sm: 'none', md: 'inherit'}}}>Projects</Typography>
                </Button>
                <Button
                  component={NavLink}
                  to={"/organizations"}
                  sx={{
                    borderRadius: 0,
                    textTransform: "capitalize",
                    color: "dark.main",
                    "&.active > *": {
                      fontWeight: "bold !important",
                    }
                  }}
                  startIcon={<IconBuilding size={18}/>}
                >
                  <Typography sx={{display: {sm: 'none', md: 'inherit'}}}>Organizations</Typography>
                </Button>
              </>}

              {
                isAuth ?
                  <UserAvatar currentUser={currentUser} onLogout={onLogout} theme={theme}/>
                  :
                  <Button
                    onClick={() => openModal()}
                    sx={{
                      bgcolor: "transparent",
                      borderRadius: 0,
                      textTransform: "capitalize",
                      fontSize: 14,
                      px: 2,
                      fontWeight: "bold",
                      color: '#4a4a4a',
                      borderColor: "#4a4a4a",
                      border: 1,
                      "&:hover": {
                        color: "#FFF",
                        bgcolor: "#4a4a4a"
                      }
                    }}
                  >
                    Sign in
                  </Button>
              }

            </Stack>
          </Stack>
        </Toolbar>

        <Toolbar
          sx={{
            bgcolor: "transparent",
            my: 1,
            display: {xs: 'none', md: "none"}
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                 alignItems={"center"}>
            <Button
              component={Link}
              to={"/"}
              variant={"text"}
              sx={{color: "#252525", flexDirection: "column"}}
            >
              <img src={"/logo.svg"} alt={"logo"} width={50}/>
              {/*<Typography fontSize={13} fontColor={"#252525"} fontWeight={"bold"}>BlockFlow</Typography>*/}
            </Button>

            <IconButton onClick={() => setOpenDrawer(true)}>
              <IconMenu/>
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  )
}

export const UserAvatar = ({currentUser, onLogout, theme}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const isAdmin = currentUser?.roles?.includes("admin")
  const {settings, onUnderMaintenance} = useContext(WebSettingsContext);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandle = async () => {
    try {
      await onLogout();
      handleCloseUserMenu();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box sx={{flexGrow: 0}}>
      <List sx={{minWidth: 0}}>
        <ListItemButton
          onClick={handleOpenUserMenu}
          sx={{
            borderRadius: "8px !important",
            minWidth: "0 !important",
          }}
        >
          <ListItemIcon sx={{minWidth: 0}}>
            <IconSettings />
          </ListItemIcon>
          <ListItemText primary={"Settings"} sx={{ pl: 1 }} />
        </ListItemButton>
      </List>
      <Menu
        sx={{p: 0, borderRadius: 0, minWidth: "280px"}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{sx: {borderRadius: 4, minWidth: "200px"}}}
        PaperProps={{sx: {borderRadius: 4, minWidth: "200px"}}}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}
               sx={{px: 2, py: 1, borderBottom: 1, borderColor: "divider"}}>
          <Avatar sx={{width: "35px", height: '35px', bgcolor: grey[100]}}><IconUser color={grey[600]} size={20}/></Avatar>
          <Typography fontWeight={"bold"}>{currentUser?.name} {currentUser?.lastname}</Typography>
        </Stack>
        {
          isAdmin &&
          <MenuItem onClick={onUnderMaintenance}
                    color={settings?.underMaintenance ? "error" : "primary"}
                    sx={{borderBottom: 1, borderColor: "divider", px: 3, py: 1, fontSize: 16}}>
            <ListItemIcon>
              <IconBulldozer/>
            </ListItemIcon>
            <ListItemText primary={"Under Maintenance"}/>
          </MenuItem>
        }
        <MenuItem onClick={logoutHandle}
                  sx={{px: 3, py: 1, fontSize: 16}}>
          <ListItemIcon>
            <IconLogout/>
          </ListItemIcon>
          <ListItemText primary={"Logout"}/>
        </MenuItem>
      </Menu>
    </Box>
  )
}
