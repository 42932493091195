import React, {useContext, useState} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField, Toolbar,
  Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {ArrowForwardIos} from "@mui/icons-material";
import {toast} from "react-toastify";
import {SignInContext} from "../../../context/SignInContext";

export const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
  })
  const {onRegister} = useContext(SignInContext);

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    updateForm(key, value);
  }

  const updateForm = (key, value) => {
    setFormData(prev => ({...prev, [key]: value}));
  }

  const registerHandle = async (e) => {
    e.preventDefault();
    if (!formData?.name || !formData?.lastname || !formData?.username || !formData?.username || !formData?.email || !formData?.password) {
      toast.error("All fields are required")
      return;
    }
    try {
      await onRegister({...formData});
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Grid container sx={{width: "100%", height: "100%", bgcolor: "rgba(0,0,0, 0.03)"}}>
      <Grid item gap={2} xs={12} md={6} lg={5} justifyContent={"space-between"}
            sx={{display: "flex", flexDirection: "column", height: "100%"}}>
        <Toolbar>
          <img src={"/logo.svg"} alt={"logo"} width={30}/>
        </Toolbar>
        <Stack
          maxWidth={"sm"} direction={"column"} gap={3}
          sx={{maxWidth: "400px", width: "100%", p: 2, flex: 1, mx: "auto"}}
          justifyContent={'center'} alignItems={"center"}
        >
          {/*HEADER*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
            {/*TITLE*/}
            <Typography color={"dark.main"} fontSize={50} variant={'h2'} textAlign={"center"}
                        fontWeight={"bold"}>Sign up</Typography>
            {/*SIGN UP*/}

            <Box sx={{textAlign: "center"}}>
              <Typography sx={{color: grey[700]}} component={"span"}>Already have account?</Typography>
              <Link to={"/register"} style={{
                paddingLeft: 5,
                color: "#252525",
                fontWeight: 'bold',
                textDecoration: "none",
                cursor: "pointer"
              }}>
                Sign in
              </Link>
            </Box>
          </Stack>
          {/*LOGIN WITH MAIL*/}
          <Stack component={"form"} onSubmit={registerHandle} direction={"column"} gap={1} sx={{width: '100%'}}>
            <Stack direction={"row"} gap={1}>
              <TextField
                variant={"standard"}
                required
                fullWidth
                label={"Name"}
                value={formData?.name}
                name={"name"}
                onChange={onChange}
                placeholder={"Enter your first name"}
              />
              <TextField
                variant={"standard"}
                required
                fullWidth
                label={"Last name"}
                value={formData?.lastname}
                name={"lastname"}
                onChange={onChange}
                placeholder={"Enter your last name"}
              />
            </Stack>
            <TextField
              variant={"standard"}
              required
              fullWidth
              label={"Username"}
              value={formData?.username}
              name={"username"}
              onChange={onChange}
              placeholder={"Enter your username"}
            />
            <TextField
              variant={"standard"}
              required
              fullWidth
              label={"Email"}
              value={formData?.email}
              name={"email"}
              onChange={onChange}
              placeholder={"Enter your email address"}
            />

            <TextField
              autoFocus
              type={"password"}
              variant={"standard"}
              required
              fullWidth
              name={"password"}
              label={"Password"}
              value={formData?.password}
              placeholder={"Enter your password"}
              onChange={onChange}
            />

            <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}
                   useFlexGap flexWrap={"wrap"}>
              <FormControlLabel sx={{color: grey[700]}} control={<Checkbox defaultChecked
                                                                           sx={{"&.Mui-checked": {color: "#8491F7"}}}/>}
                                label="Remember me"/>
              <Typography sx={{textDecoration: "none", color: grey[700]}} component={Link}
                          to={"/forgot-password"}>Forgot password?</Typography>
            </Stack>
            <Button
              fullWidth
              type={"submit"}
              variant={"contained"}
              disabled={!formData?.name || !formData?.lastname || !formData?.username || !formData?.username || !formData?.email || !formData?.password}
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              endIcon={<ArrowForwardIos style={{fontSize: 13}}/>}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
        <Toolbar sx={{justifyContent: "space-between"}}>
          <img src={"/logo-black.svg"} alt={"logo"} width={60}/>
          <Link to={"www.openbrain.io"}
                style={{fontSize: 12, textDecoration: "none", color: "#252525"}}>openbrain.io</Link>
        </Toolbar>
      </Grid>
      <Grid item gap={2} xs={12} md={6} lg={7} alignItems={"center"} justifyContent={"center"}
            sx={{display: {xs: "none", md: "flex"}, bgcolor: "primary.main"}}>
        <Stack sx={{maxWidth: "340px", textAlign: "center"}} direction={"column"} alignItems={"center"}
               justifyContent={"center"}>
          <img src={"/logo_white.svg"} alt={"logo"} width={300} style={{margin: "auto"}}/>
          <Typography fontSize={30} fontWeight={700} color={"#EAEAEA"}>Openbrain</Typography>
          <Typography fontSize={16} fontWeight={500} color={"#EAEAEA"}>Create your voice bot assistant effortlessly with
            BlockFlow Chat.
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}