import {Button, Stack, Tooltip} from "@mui/material";
import {
  IconLayersLinked,
  IconDatabase,
  IconSettings,
  IconAdjustmentsCog,
  IconMessage2,
  IconPlugConnected
} from '@tabler/icons-react'
import React, {useContext, useState} from "react";
import {Context as AppContext} from "../../../../context/AppContext";
import {SettingsModal} from "../../../../components/settings/SettingsModal";
import {toast} from "react-toastify";
import {SIPModal} from "../../../../components/SIP/SIPModal";
import {ExportProjectButton} from "../../../../layouts/flow-layout/header/Header";
import {NavLink} from "react-router-dom";

export const LeftMainMenu = ({project}) => {
  const {updateProject} = useContext(AppContext)
  const [openSettings, setOpenSettings] = useState(false);
  const [openSIP, setOpenSIP] = useState(false);

  const onUpdateProject = async (newProject) => {
    try {
      await updateProject(newProject);
      toast.success("Project updated successfully");
    } catch (err) {
      console.log(err);
      toast.error("Failed to update project")
    }
  }


  return (

    <>
      <SettingsModal
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
        project={project}
        onUpdate={onUpdateProject}
      />

      <SIPModal
        open={openSIP}
        disabled={!project}
        handleClose={() => setOpenSIP(false)}
        project={project}
        onUpdate={onUpdateProject}
      />

      <Stack direction={"column"} gap={2} sx={{
        maxHeight: '100%',
        minWidth: "48px",
        maxWidth: "48px",
        width: "100%",
        borderRight: 1,
        pb: 0.5,
        pt: 1,
        px: 0.5,
        borderColor: "divider",
        // backgroundColor: "#f1f4f7",
        overflowY: "auto",
      }}>
        <Tooltip title={"Designer"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} end to={`/project/${project?.id}`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconLayersLinked stroke={1.5}/>
          </Button>
        </Tooltip>
        <Tooltip title={"Knowledge Base"} placement={"right"}>
          <Button component={NavLink} disabled={!project?.settings?.organization} className={"side-link"} to={`/project/${project?.id}/knowledge-base`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconDatabase stroke={1.5}/>
          </Button>
        </Tooltip>
        <Tooltip title={"Message recorder"} placement={"right"}>
          <Button component={NavLink} disabled={!project?.settings?.speechToText} className={"side-link"} to={`/project/${project?.id}/asri`}
                  sx={{minWidth: 40}}>
            <IconMessage2 stroke={1.5}/>
          </Button>
        </Tooltip>
        <Tooltip title={"Fine Tuning"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} to={`/project/${project?.id}/fine-tuning`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconAdjustmentsCog stroke={1.5}/>
          </Button>
        </Tooltip>
        <ExportProjectButton
          project={project} showIconText={false}
        />
        <Tooltip title={"SIP"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} to={`/project/${project?.id}/sip`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconPlugConnected stroke={1.5}/>
          </Button>
        </Tooltip>

        <Tooltip title={"Settings"} placement={"right"}>
          <Button component={NavLink} className={"side-link"} to={`/project/${project?.id}/settings`}
                  sx={{minWidth: 40, color: "primary.dark"}}>
            <IconSettings stroke={1.5}/>
          </Button>
        </Tooltip>


      </Stack>
    </>
  )
}
